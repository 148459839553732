import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    theme: [],
  },
  reducers: {
    setAppMode: (state, action) => {
      return { ...state, mode: action.payload };
    },
    setAppColor: (state, action) => {
      return { ...state, color: action.payload };
    },
    getAppTheme: (state, action) => {
      return state;
    },
  },
});

export const { setAppMode, setAppColor, getAppTheme } = themeSlice.actions;

export default themeSlice.reducer;
