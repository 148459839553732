import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getUserList,registerUser, putUser, resetPassword, selfRegisterUser, confirmUserRegistration, resendCode } from "../api/userAPI";

export const getUsers = createAsyncThunk("users/getUsers", async (payload, { rejectWithValue }) => {
  try {
    const response = await getUserList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const resetMyPassword = createAsyncThunk("user/resetMyPassword", async (payload, { rejectWithValue }) => {
  try {
    const response = await resetPassword(payload);
    //console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addUser = createAsyncThunk("users/addUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await registerUser(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const updateUser = createAsyncThunk("users/updateUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await putUser(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const registerself = createAsyncThunk("users/registerself", async (payload, { rejectWithValue }) => {
  try {
    const response = await selfRegisterUser(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    // console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const confirmUser = createAsyncThunk("users/confirmUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await confirmUserRegistration(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const resendUserCode = createAsyncThunk("users/resendUserCode", async (payload, { rejectWithValue }) => {
  try {
    const response = await resendCode(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const userSlice = createSlice({
  name: "users",
  initialState: {
    payload: {
      status: null,
      error: null,
      data: null,
    },
    post: {
      status: null,
      error: null,
      data: null,
    },
    password:{
      status:null,
      error:null
    },
    otp:{
      status:null,
      error:null
    },
    put: {
      status: null,
      error: null,
      data: null,
    },
    get: {
      status: null,
      error: null,
      list: [],
    },
  },

  extraReducers: {
    [getUsers.pending]: (state) => {
      state.get.status = "loading";
    },
    [getUsers.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get users!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.get.status = 'idle';
    },
    [getUsers.rejected]: (state, action) => {
      state.get.status = "idle";
      state.get.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get users!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [addUser.pending]: (state) => {
      state.post.status = "loading";
    },
    [addUser.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("User saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.post.status = "idle";
        state.post.error = null;
      }else{
        toast.warn("Couldn't add iuser!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.post.error = "Failed";
      }

      state.post.data = data;
    },
    [addUser.rejected]: (state, action) => {
      //state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add user!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [registerself.pending]: (state) => {
      state.post.status = "loading";
    },
    [registerself.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Request submitted successfully.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.post.status = "posted";
        state.post.error = null;
      }else{
        toast.warn("Couldn't register user!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.post.error = "Failed";
        state.post.status = "idle";
      }

      state.post.data = data;
    },
    [registerself.rejected]: (state, action) => {
      //state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      state.post.status = "idle";
      // console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't register!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [confirmUser.pending]: (state) => {
      state.put.status = "loading";
    },
    [confirmUser.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Successful", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.put.status = "idle";
        state.put.error = null;
      }else{
        toast.warn("Couldn't confirm user!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.put.error = "Failed";
      }

      state.put.data = data;
    },
    [confirmUser.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      //console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't confirm user!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [resendUserCode.pending]: (state) => {
      state.otp.status = "loading";
    },
    [resendUserCode.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Code has been sent to you", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.otp.status = "idle";
        state.otp.error = null;
      }else{
        toast.warn("Couldn't resend code!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.otp.error = "Failed";
      }
    },
    [resendUserCode.rejected]: (state, action) => {
      state.otp.status = "idle";
      state.otp.error = { message: "An error occurred" };
      //console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't resend code!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateUser.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateUser.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("User saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.put.status = "idle";
      }else{
        state.put.error = "Failed";
        toast.warn("Couldn't update iuser!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.data = data;
    },
    [updateUser.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add user!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [resetMyPassword.pending]: (state) => {
      state.password.status = "loading";
    },
    [resetMyPassword.fulfilled]: (state, {payload}) => {
      //alert(JSON.stringify(payload))
      if (payload.status === 202) {
        state.password.status = "idle";
        state.password.error = null;
        toast.success("Successful", {
          autoClose: 2600,
          hideProgressBar: true,
        });
      }else{
        state.password.status = "idle";
        state.password.error = { message: "An error occurred" };
        toast.error("Failed to reset password", {
          autoClose: 2600,
          hideProgressBar: true,
        });
      }
    },
    [resetMyPassword.rejected]: (state, action) => {
      state.password.status = "idle";
      state.password.error = { message: "An error occurred" };
      //console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't reset user password!", {
        autoClose: 2600,
        hideProgressBar: true,
      });
    },
  },
});

export default userSlice.reducer;
