import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import itemsList from "../../assets/JsonData/items-list.json";
import stockHistory from "../../assets/JsonData/stock-history.json";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { addItems, updateItems, getItems, uploadItems } from "../../app/itemsSlice";
import { getCategory, getUnitMeasures } from "../../app/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { addStock, getStockHistory, updateStock } from "../../app/stockSlice";
import { addSideEffects, getSideEffects, updateSideEffects } from "../../app/sideEffectsSlice";
import { addDispodedItems } from "../../app/disposedItemsSlice";
import AppTabs from "../../components/appTabs/appTabs";
import { disposeSchema, hasAccess, instCat, itemSchema, serviceSchema, stockSchema } from "../../common/validators";
import validate from "validate.js";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const tabs = [
  {
    title: "Stock History",
  },
  {
    title: "Side Effects",
  },
  {
    title: "Dosage",
  },
];

const itemsTableHead = instCat() === 4 ? 
[
  { label: "Service Code", name: "item_code" },
  { label: "Description", name: "item_description" },
  { label: "Float Balance", name: "float_balance" },
  { label: "Cash Balance", name: "cash_balance" },
  { label: "Category", name: "category_name" },
  "action",
] : instCat() === 5 ? 
[
  { label: "Service Code", name: "item_code" },
  { label: "Description", name: "item_description" },
  { label: "Maximum Selling Price", name: "selling_price" },
  { label: "Minimum Selling Price", name: "w_selling_price" },
  // { label: "Currency", name: "ccy" },
  { label: "Category", name: "category_name" },
  "action",
]: [
  { label: "Item Code", name: "item_code" },
  { label: "Description", name: "item_description" },
  { label: "Available Quantity", name: "qty_bal" },
  { label: "Selling Price", name: "selling_price" },
  { label: "Whole Selling Price", name: "w_selling_price" },
  { label: "Price Per", name: "unit_name" },
  // { label: "Currency", name: "ccy" },
  { label: "Category", name: "category_name" },
  "action",
];

const stockTableHead = [
  { label: "Buy Date", name: "buy_date" },
  { label: "Expiry", name: "expiry_date" },
  { label: "Price Per", name: "unit_name" },
  { label: "Buying Rate", name: "buying_price" },
  { label: "Quantity", name: "qty" },
  { label: "Other Costs", name: "associated_cost" },
  // { label: "Currency", name: "ccy" },
];

const uploadItemColumns = [
  { label: "Item Name", name: "itemName" },
  { label: "Unit Measure", name: "unitMeasure" },
  { label: "Stock Quantity", name: "stockQuantity" },
  { label: "Stock Value", name: "stockValue" },
];

const disposeReasonList = [
  { label: "Destroyed", value: "Destroyed" },
  { label: "Expired", value: "Expired" },
  { label: "Lost", value: "Lost" },
]

const sideEffects =  [
  {remarks:"Feeling headache"},
  {remarks:"Feeling Sleepy"},
  {remarks:"Feeling Stomachache"},
]

const isViewAccess = () => {
  return hasAccess('items','view');
}

const isDetailsAccess = () => {
  return instCat() === 5 ? false : hasAccess('items','details');
}

const isEditAccess = () => {
  return hasAccess('items','edit');
}

const isAddAccess = () => {
  //alert(hasAccess('items','add'))
  return hasAccess('items','add');
}

const isAddUserAccess = () => {
  return hasAccess('users','add');
}

const isAddStockAccess = () => {
  return instCat() === 5 ? false : hasAccess('stock','add');
}

const isEditStockAccess = () => {
  return instCat() === 5 ? false : hasAccess('stock','edit');
}

const isDisposeAccess = () => {
  return instCat() === 5 ? false : hasAccess('items','delete');
}

const isRemoveDisposeAccess = () => {
  return instCat() === 4 || instCat() === 5 ? false : true;
}

const Items = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openStockForm, setOpenStockForm] = useState(false);
  let [openDisposeForm, setOpenDisposeForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [toEditStock, setToEditStock] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  let [showStock, setShowStock] = useState(false);
  let [showSideEffect, setShowSideEffect] = useState(false);
  let [showDosage, setShowDosage] = useState(false);
  let [toEditSideEffect, setToEditSideEffect] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedStock, setSelectedStock] = useState({});
  const [itemDataList, setItemDataList] = useState([]);
  const [stockDataList, setStockDataList] = useState([]);
  const [categoryDataList, setCategoryDataList] = useState([]);
  const [measuresDataList, setMeasuresDataList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [excelItemsData, setExcelItemsData] = useState([]);
  const [excelItemsDataNew, setExcelItemsDataNew] = useState([]);  
  const [previewExcel, setPreviewExcel] = useState(false);
  const [widthDialog, setWidthDialog] = useState("md");
  const [isRemoving, setIsRemoving] = useState(false);


  const excelItemsSample = [
    {
      itemCode:'',
      itemName:'',
      unitMeasure:'',
      retailSellPrice:'',
      wholeSellPrice:'',
      stockQuantity:'',
      stockValue:''
    }
  ]
  
  


  const dispatch = useDispatch();

  const { error, status, list } = useSelector(
    ({ items }) => items.get
  );

  const { error: postError, status: postStatus } = useSelector(
    ({ items }) => items.post
  );

  const { error: putError, status: putStatus } = useSelector(
    ({ items }) => items.put
  );

  const { error: postStockError, status: postStockStatus } = useSelector(
    ({ stock }) => stock.post
  );

  const { error: putStockError, status: putStockStatus } = useSelector(
    ({ stock }) => stock.put
  );

  const { error: errorStock, status: statusStock, list: listStock } = useSelector(
    ({ stock }) => stock.get
  );

  const { error: errorCategories, status: statusCategories, list: listCategories } = useSelector(
    ({ common }) => common.categories.get
  );

  const { error: errorMeasures, status: statusMeasures, list: listMeasures } = useSelector(
    ({ common }) => common.unitMeasures.get
  );

  const { error: postDisposeError, status: postDisposeStatus } = useSelector(
    ({ disposedItems }) => disposedItems.post
  );

  const { error: postSideEffectsError, status: postSideEffectsStatus } = useSelector(
    ({ sideEffects }) => sideEffects.post
  );

  const { error: putSideEffectsError, status: putSideEffectsStatus } = useSelector(
    ({ sideEffects }) => sideEffects.put
  );

  const { error: getSideEffectsError, status: getSideEffectsStatus, list: sideEffectsList } = useSelector(
    ({ sideEffects }) => sideEffects.get
  );

  const handleRequestClose = () => {
    setErrors([])
    setFormValues({});
    setOpenForm(false);
    setOpenDetail(false);
    setOpenStockForm(false);
    setOpenDisposeForm(false);
    setShowSideEffect(false);
    setShowDosage(false);
    setShowDosage(false);
    setEdit(false);
    setToEditSideEffect(false);
    setPreviewExcel(false);
    setActiveTab(0);
    setWidthDialog("md")
  };

  const viewItem = (item) => {
    if (item) {

      setViewDetailsSelected(true)

      setEdit(false);
      setOpenDisposeForm(false);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const editItem = (item) => {
    if (item) {
      setEdit(true);
      setOpenDisposeForm(false);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };

  const editStock = (stock) => {
    if (stock) {
      //alert('ss')
      setOpenDisposeForm(false);
      setOpenDetail(false);
      setToEditStock(true);
      setFormValues(stock);
      setSelectedStock(stock);
      setTimeout(() => {
        setOpenStockForm(true);
      }, 300);
    }
  };

  const formOpenItemSearch = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-4">
            <Inputs
              name="business_date"
              size="small"
              label="Business Date"
              value={formValues.business_date}
              inputType="date"
              onChange={(e) => {handleChange({ target: { name: "business_date", value: e } })}}
              error={errors["business_date"] ? true : false}
              helperText={errors["business_date"] ? errors.business_date[0] : null}
            />
          </div>
        </div>
      </>
    )
  }

  const formOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="item_description"
              size="small"
              label= {instCat() === 5 ? "Service Name" : "Item Name"}
              value={formValues.item_description}
              inputType="text"
              //disabled={edit ? true : false}
              onChange={handleChange}
              error={errors["item_description"] ? true : false}
              helperText={errors["item_description"] ? errors.item_description[0] : null}
            />
          </div>
          {instCat() != 4 &&
          (<div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="selling_price"
              size="small"
              label={instCat() === 5 ? "Maximum Selling Price" :"Retail Selling Price"}
              value={formValues.selling_price}
              inputType="number"
              onChange={handleChange}
              error={errors["selling_price"] ? true : false}
              helperText={errors["selling_price"] ? errors.selling_price[0] : null}
            />
          </div>
          )}
          {instCat() != 4 &&
          (<div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="w_selling_price"
              size="small"
              label={instCat() === 5 ? "Minimum Selling Price":"Whole Selling Price"}
              value={formValues.w_selling_price}
              inputType="number"
              onChange={handleChange}
              error={errors["w_selling_price"] ? true : false}
              helperText={errors["w_selling_price"] ? errors.w_selling_price[0] : null}
            />
          </div>
          )}

          {instCat() != 4 && instCat() != 5 && measuresDataList.length ? (
            <div className="col-sm-12 col-md-6 col-6">
              <Inputs
                name="unit_of_measure"
                size="small"
                inputType="select"
                value={formValues.unit_of_measure}
                defaultValue={measuresDataList[0]}
                label="Selling Price Per"
                listLabel="Selling Price Per"
                listValue="unit_of_measure"
                list={measuresDataList}
                onChange={handleChange}
                error={errors["unit_of_measure"] ? true : false}
                helperText={errors["unit_of_measure"] ? errors.unit_of_measure[0] : null}
              />
            </div>
          ) : (
            ""
          )}



          {instCat() != 4 && instCat() != 5 && categoryDataList.length ? (
            <div className="col-sm-12 col-md-6 col-6">
              <Inputs
                name="item_category"
                size="small"
                inputType="select"
                value={formValues.item_category}
                defaultValue={categoryDataList[0]}
                label="Item Category"
                listLabel="Item Category"
                listValue="item_category"
                list={categoryDataList}
                onChange={handleChange}
                error={errors["item_category"] ? true : false}
                helperText={errors["item_category"] ? errors.item_category[0] : null}
              />
            </div>
          ) : (
            ""
          )}
          
          {instCat() != 4 && instCat() != 5 &&
          (
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="items_per_box"
              size="small"
              label="How many in Pack?"
              value={formValues.items_per_box}
              inputType="number"
              onChange={handleChange}
              error={errors["items_per_box"] ? true : false}
              helperText={errors["items_per_box"] ? errors.items_per_box[0] : null}
            />
          </div>
          )}

          {
            instCat() != 5 && !edit && !openStockForm && (
              <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="stock_qty"
              size="small"
              label= {instCat() === 4 ? "Items Current Draw Balance" : "Items Current Stock Quantity"}
              value={formValues.stock_qty}
              inputType="number"
              onChange={handleChange}
              error={errors["stock_qty"] ? true : false}
              helperText={errors["stock_qty"] ? errors.stock_qty[0] : null}
            />
          </div>
            )
          }

{
            instCat() != 5 && !edit && !openStockForm && (
              <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="stock_amt"
              size="small"
              label= {instCat() === 4 ? "Items Current Device Balance" : "Items Current Stock Value"}
              value={formValues.stock_amt}
              inputType="number"
              onChange={handleChange}
              error={errors["stock_amt"] ? true : false}
              helperText={errors["stock_amt"] ? errors.stock_amt[0] : null}
            />
          </div>
            )
          }

        </div>
      </>
    );
  };

  const formStock = (item) => {
    //alert(JSON.stringify(item))
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-12">
            <h3>Item Details</h3>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="item_code"
              size="small"
              label="Item Code"
              value={item && item.item_code ? item.item_code : ""}
              inputType="text"
              disabled={item && item.item_code ? true : false}
              onChange={handleChange}
              error={errors["item_code"] ? true : false}
              helperText={errors["item_code"] ? errors.item_code[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="item_description"
              size="small"
              label="Item Description"
              value={item && item.item_description ? item.item_description : ""}
              inputType="text"
              disabled={item && item.item_description ? true : false}
              onChange={handleChange}
              error={errors["item_description"] ? true : false}
              helperText={errors["item_description"] ? errors.item_description[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="buying_price"
              size="small"
              label={instCat() === 4 ? "Float Open Balance" : "Buying Price"}
              value={formValues.buying_price}
              inputType="number"
              onChange={handleChange}
              error={errors["buying_price"] ? true : false}
              helperText={errors["buying_price"] ? errors.buying_price[0] : null}
            />
          </div>

          {instCat() == 4 &&
          (<div className="col-sm-12 col-md-6 col-6">
               <Inputs
                name="droo_balance"
                size="small"
                label={"Cash Open Balance"}
                value={formValues.droo_balance}
                inputType="number"
                onChange={handleChange}
                error={errors["droo_balance"] ? true : false}
                helperText={errors["droo_balance"] ? errors.droo_balance[0] : null}
              />
            </div>)}

          {instCat() != 4 &&
          (<div className="col-sm-12 col-md-6 col-6">
              <Inputs
                name="unit_of_measure"
                size="small"
                inputType="select"
                value={formValues.unit_of_measure}
                defaultValue={measuresDataList[0]}
                label="Buy Price Per"
                listLabel="Buy Price Per"
                listValue="unit_of_measure"
                list={measuresDataList}
                onChange={handleChange}
                error={errors["unit_of_measure"] ? true : false}
                helperText={errors["unit_of_measure"] ? errors.unit_of_measure[0] : null}
              />
            </div>)}
            {instCat() != 4 &&
          (<div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="qty"
              size="small"
              label="Quantity"
              value={formValues.qty}
              inputType="number"
              onChange={handleChange}
              error={errors["qty"] ? true : false}
              helperText={errors["qty"] ? errors.qty[0] : null}
            />
          </div>)}

          {instCat() != 4 &&
          (<div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="associated_cost"
              size="small"
              label="Other Associated Cost"
              value={formValues.associated_cost}
              inputType="number"
              onChange={handleChange}
              error={errors["associated_cost"] ? true : false}
              helperText={errors["associated_cost"] ? errors.associated_cost[0] : null}
            />
          </div>)}

          {instCat() != 4 &&
          (<div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="batch_number"
              size="small"
              label="Batch Number"
              value={formValues.batch_number}
              inputType="text"
              onChange={handleChange}
              error={errors["batch_number"] ? true : false}
              helperText={errors["batch_number"] ? errors.batch_number[0] : null}
            />
          </div>)}

          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="buy_date"
              size="small"
              label="Purchase Date"
              value={formValues.buy_date}
              inputType="date"
              onChange={(e) =>
              handleChange({ target: { name: "buy_date", value: e } })}
              error={errors["buy_date"] ? true : false}
              helperText={errors["buy_date"] ? errors.buy_date[0] : null}
            />
          </div>

          {instCat() != 4 &&
          (<div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="expiry_date"
              size="small"
              label="Expiry Date"
              value={formValues.expiry_date}
              inputType="date"
              onChange={(e) =>
                handleChange({ target: { name: "expiry_date", value: e } })}
                error={errors["expiry_date"] ? true : false}
                helperText={errors["expiry_date"] ? errors.expiry_date[0] : null}
            />
          </div>)}



        </div>
      </>
    );
  };

  const formDispose = (item) => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="item_code"
              size="small"
              label="Item Code"
              value={item && item.item_code ? item.item_code : ""}
              inputType="text"
              disabled={item && item.item_code ? true : false}
              onChange={handleChange}
              error={errors["item_code"] ? true : false}
                helperText={errors["item_code"] ? errors.item_code[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="item_description"
              size="small"
              label="Item Description"
              value={item && item.item_description ? item.item_description : ""}
              inputType="text"
              disabled={true}
              onChange={handleChange}
              error={errors["item_description"] ? true : false}
                helperText={errors["item_description"] ? errors.item_description[0] : null}
            />
          </div>

          {instCat() != 4 && disposeReasonList.length ? (
            <div className="col-sm-12 col-md-6 col-6">
              <Inputs
                name="dispose_reason"
                size="small"
                inputType="select"
                value={formValues.dispose_reason}
                defaultValue={disposeReasonList[0]}
                label="Dispose Reason"
                listLabel="Dispose Reason"
                listValue="dispose_reason"
                list={disposeReasonList}
                onChange={handleChange}
                error={errors["dispose_reason"] ? true : false}
                helperText={errors["dispose_reason"] ? errors.dispose_reason[0] : null}
              />
            </div>
          ) : (
            ""
          )}

          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="qty"
              size="small"
              label={instCat() === 4 ? "Amount" : "Quantity"}
              value={formValues.qty}
              inputType="number"
              onChange={handleChange}
              error={errors["qty"] ? true : false}
                helperText={errors["qty"] ? errors.qty[0] : null}
            />
          </div>

          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="destroy_date"
              size="small"
              label={instCat() === 4 ? "Date" : "Dispose Date"}
              value={formValues.destroy_date}
              inputType="date"
              onChange={(e) =>
                handleChange({ target: { name: "destroy_date", value: e } })}
                error={errors["destroy_date"] ? true : false}
                helperText={errors["destroy_date"] ? errors.destroy_date[0] : null}
            />
          </div>
        </div>
      </>
    );
  };

  const dialogOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <span>Item Code</span>&nbsp;&nbsp;
            <span>{selectedItem?.item_code}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Item Description</span>&nbsp;&nbsp;
            <span>{selectedItem?.item_description}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Retail Selling Price</span>&nbsp;&nbsp;
            <span>{selectedItem?.selling_price}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Whole Selling Price</span>&nbsp;&nbsp;
            <span>{selectedItem?.w_selling_price}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Available Quantity</span>&nbsp;&nbsp;
            <span>{selectedItem?.qty_bal}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Currency</span>&nbsp;&nbsp;
            <span>{selectedItem?.ccy}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Category</span>&nbsp;&nbsp;
            <span>{selectedItem?.category_name}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Unit Price Per</span>&nbsp;&nbsp;
            <span>{selectedItem?.unit_name}</span>
          </div>
          {/* <div className="col-sm-12 col-md-6 col-6">
            <span>Expiry Date</span>&nbsp;&nbsp;
            <span>{moment(selectedItem?.expiry_date).format("YYYY-MM-DD")}</span>            
          </div> */}
          <br /> <br />
          <div className="col-sm-12 col-md-12 col-12">
          <AppTabs
            tabs={tabs}
            activeTab={activeTab}
            handleTabChange={handleChangeTabs}
          />
          </div>
          <br /> <br />
          {/* <div className="col-sm-12 col-md-12 col-12">
            <br/>
            <h4>Stock Taking History</h4>  
            <br/>          
          </div> */}

          <div className="col-12">
            {showStock && stockDataList.length > 0 ? (
              <Table
                columns={stockTableHead}
                data={stockDataList}
                actions={[
                  {
                    action: "Edit",
                    renderCheck:isEditStockAccess,
                    onClick: (stock) => {
                      if(instCat() != 4) editStock(stock);
                    },
                  }]}
                downloadFileName={selectedItem?.item_description + " Stock History"}
              />
            ) : showSideEffect ? (
              <div className="col-sm-12 col-md-12 col-12">
               <Inputs
                  name="side_effect"
                  size="small"
                  label="New side effect"
                  value={formValues.side_effect}
                  inputType="text"
                  disabled={false}
                  onChange={handleChange}
                />
            {sideEffectsList && (sideEffectsList ?? []).length >= 0 && (
              sideEffectsList
                .filter((i) => i.remarks != null)
                .map((item, index) => (
                  <div className="wf-comments-item">
                    <div className="bubble" onClick={() => {handleEditSideEffect(item)}}>
                      <div>
                        {item.remarks}
                      </div>
                    </div>
                  </div>
                ))
          )}


              </div>
            )
            :(
              <MySpinner size={35} color="#367588" />
            )}
          </div>

        </div>
      </>
    );
  };

 
  const dialogExcelData = () => {
    return (
      <>
      <div className="col-12">
        {
          isRemoving === true && (
            <MySpinner size={35} color="#367588" />
          )
        }
        {isRemoving === false && excelItemsData.length > 0 && excelItemsData[0]['itemName'] && (
            <Table
            
              columns={uploadItemColumns}
              data={excelItemsData}
              downloadFileName={'Uploaded File'}
              actions={[
                {
                  action: "Remove",
                  renderCheck: isAddAccess,
                  onClick: async (item) => {
                    //handleRequestClose();   
                    setIsRemoving(true)
                   await removeUploadedItem(item);
                   setIsRemoving(false)   
                  },
                },
              ]}
            />
          )
        }
      </div>
      </>
    );
  }

  const handleEditSideEffect = (item) => {
    setToEditSideEffect(true)
    let newEffect = {
      id:item?.effect_id,
      item_code:item.item_code,
      item_id:item.id,
      side_effect:item.remarks,
      status:'A'
    }
    //alert(JSON.stringify(newEffect))
    
    setFormValues(newEffect);
  }

  const handleChange = (event) => {
   
    //alert(JSON.stringify(event.target.value))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });

    if(event.target.name === 'business_date' && event.target.value){
      loadItemsData()
    }
  };

  const handleUpload = (e) => {
    //setPreviewExcel()
    e.preventDefault();
    //alert(JSON.stringify(excelItemsData))
    dispatch(uploadItems(excelItemsData));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    let currentDate = new Date();
    let barcodeVal = moment(currentDate).format("YYMMDDHHmmSS");

    
    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

    

    if (edit && !openStockForm) {
      let validationErrors = validate(
        formValues,
        instCat() === 5 ? serviceSchema : itemSchema
      );
      if(instCat() != 4 && validationErrors){
        setErrors(validationErrors);      
      }else if(instCat() === 4 && !formValues.item_description && validationErrors){
        setErrors(validationErrors);      
      }else{
      let newItem = {
        "item_code": formValues?.item_code,
        "item_description": formValues?.item_description,
        "item_category": instCat() === 4 || instCat() === 5 ? 3 : formValues?.item_category,
        "unit_of_measure": instCat() === 4 || instCat() === 5 ? 5 : formValues?.unit_of_measure,
        "selling_price": instCat() === 4 ? 0 : formValues?.selling_price,
        "w_selling_price": instCat() === 4 ? 0 : formValues?.w_selling_price,
        "ccy": formValues?.ccy,
        "package_category": instCat() === 4 || instCat() === 5 ? 3 : formValues?.package_category,
        "sub_category": instCat() === 4 || instCat() === 5 ? 3 : formValues?.sub_category,
        "entry_date": formValues?.entry_date,
        "items_per_box": instCat() === 4 || instCat() === 5 ? 1 : formValues?.items_per_box,
      }
      dispatch(updateItems(newItem));
    }
    } else if (openStockForm && !toEditStock) {
      let validationErrors = validate(
        formValues,
        stockSchema
      );
      
      if(instCat() != 4 && validationErrors){
        setErrors(validationErrors);      
      }else if(instCat() === 4 && !formValues.buying_price && validationErrors){
        setErrors(validationErrors);      
      }else{
      //toast.success(JSON.stringify(formValues));
      let newStock = {
        "id": formValues?.id,
        "item_code": formValues?.item_code,
        "buying_price": formValues?.buying_price,
        "qty": instCat() === 4 ? 1 : formValues?.qty,
        "ccy": "TZS",
        "unit_of_measure":instCat() === 4 ? 5 : formValues?.unit_of_measure,
        "batch_number": String(userData.data.inst_id).substring(0,4)+""+barcodeVal,
        "expiry_date": formValues?.expiry_date,
        "manufacture_date": new Date(),
        "entry_date": new Date(),
        "buy_date": formValues?.buy_date,
        "associated_cost": instCat() === 4 ? 0 : formValues?.associated_cost,
        "entry_by": 1,
        "droo_balance": instCat() === 4 ? formValues?.droo_balance : null
      };
      dispatch(addStock(newStock))
    }
    } else if (toEditStock) {
      let validationErrors = validate(
        formValues,
        stockSchema
      );
      
      if(instCat() != 4 && validationErrors){
        setErrors(validationErrors);      
      }else if(instCat() === 4 && !formValues.buying_price && validationErrors){
        setErrors(validationErrors);      
      }else{
      let newStock = {
        "id": formValues?.id,
        "item_code": formValues?.item_code,
        "buying_price": formValues?.buying_price,
        "qty": instCat() === 4 ? 1 : formValues?.qty,
        "ccy": "TZS",
        "unit_of_measure": instCat() === 4 ? 5 : formValues?.unit_of_measure,
        "batch_number": formValues.batch_number ? formValues.batch_number : String(userData.data.inst_id).substring(0,4)+""+barcodeVal,
        "expiry_date": formValues?.expiry_date,
        "manufacture_date": formValues?.manufacture_date,
        "entry_date": formValues?.entry_date,
        "buy_date": formValues?.buy_date,
        "associated_cost": formValues?.associated_cost,
      };
      dispatch(updateStock(newStock))
    }
    } else if (openDisposeForm) {
      let validationErrors = validate(
        formValues,
        disposeSchema
      );
      
      if(instCat() != 4 && validationErrors){
        setErrors(validationErrors);      
      }else if(instCat() === 4 && !formValues.qty && validationErrors){
        setErrors(validationErrors);      
      }else{
      setFormValues({
        ...formValues,
        item_id: selectedItem?.id
      })
      //alert(JSON.stringify(formValues))
      dispatch(addDispodedItems(formValues))
    }
    }else if(showSideEffect && !toEditSideEffect){
      let newEffect = {
        item_code:formValues.item_code,
        item_id:formValues.id,
        remarks:formValues.side_effect,
        status:'A'
      }
      // alert(JSON.stringify(newEffect))
      dispatch(addSideEffects(newEffect))
    } else if(showSideEffect && toEditSideEffect){
      let newEffect = {
        id:formValues.id,
        item_code:formValues.item_code,
        item_id:formValues.item_id,
        remarks:formValues.side_effect,
        status:String(formValues.side_effect).length < 5 ? 'X' : 'A'
      }
      // alert(JSON.stringify(newEffect))
      dispatch(updateSideEffects(newEffect))
    }
     else {
      let validationErrors = validate(
        formValues,
        instCat() === 5 ? serviceSchema : itemSchema
      );
      
      if(instCat() != 4 && validationErrors){
        setErrors(validationErrors);      
      }else if(instCat() === 4 && !formValues.item_description && validationErrors){
        setErrors(validationErrors);      
      }else{
      let newItem = {
        "item_code": formValues?.item_code,
        "item_description": formValues?.item_description,
        "item_category":instCat() === 4 || instCat() === 5 ? 3 : formValues?.item_category,
        "unit_of_measure":instCat() === 4 || instCat() === 5 ? 5 : formValues?.unit_of_measure,
        "selling_price": instCat() === 4 ? 0 : formValues?.selling_price,
        "w_selling_price":instCat() === 4 ? 0 : formValues?.w_selling_price,
        "ccy": "TZS",
        "package_category": instCat() === 4 || instCat() === 5 ? 3 : formValues?.package_category,
        "sub_category": instCat() === 4 || instCat() === 5 ? 3 : formValues?.sub_category,
        "entry_date": "",
        "items_per_box":instCat() === 4 || instCat() === 5 ? 1 : formValues?.items_per_box,
        "entry_by": 1,
        "stock_qty": formValues.stock_qty ? formValues?.stock_qty : 0,
        "stock_amt": formValues.stock_amt ? formValues?.stock_amt : 0
      }
      dispatch(addItems(newItem));
    }
    }

  };




  const newStockClicked = (item = null) => {
    // e.preventDefault();
    //toast.success("New Stock");
    setFormValues({ item_code: item?.item_code, item_description: item?.item_description, buy_date: new Date() })
    setSelectedItem(item);
    setOpenStockForm(true);
    setOpenDisposeForm(false);

  };

  const disposeClicked = (item = null) => {
    setFormValues({ item_code: item?.item_code, item_description: item?.item_description, destroy_date: new Date() })
    setSelectedItem(item);
    setOpenDisposeForm(true);
    setEdit(false);
    setOpenDetail(false);

  };

  const handleChangeTabs = (index) => {
    setActiveTab(index);
  };

  const removeUploadedItem = async (item) => {
    let newExcelItemsData = excelItemsData;
    let index = newExcelItemsData.indexOf(item);
    
    if (index > -1) { // only splice array when item is found
      newExcelItemsData.splice(index, 1); // 2nd parameter means remove one item only
      setExcelItemsDataNew(newExcelItemsData)
      if (newExcelItemsData.length < 1) {
        setPreviewExcel(false)
      }         
    }
  }

  const reloadUploaded = async () => {
    if(previewExcel === false && excelItemsData.length > 0){
      setPreviewExcel(true);
    }
    
  }


  useEffect(() => {   
    loadItemsData()    
    dispatch(getCategory());
    dispatch(getUnitMeasures());
  }, [dispatch]);

  const loadItemsData = () => {
    let currentDate = new Date();
    currentDate = moment(currentDate).format("YYYY-MM-DD")
    currentDate = formValues && formValues.business_date ? moment(formValues.business_date).format("YYYY-MM-DD") : currentDate;
    dispatch(getItems({businessDate:currentDate}));
  }

  useEffect(() => {
    setToEditSideEffect(false);
    if (activeTab === 0) {
      setShowStock(true);
      setShowSideEffect(false);
      setShowDosage(false);
      if (viewDetailsSelected === true && selectedItem) {
        dispatch(getStockHistory({ item_code: selectedItem?.item_code }))
      }
      // dispatch(getExpenses())
    } else if (activeTab === 1) {
      setShowStock(false);
      setShowSideEffect(true);
      setShowDosage(false);
      if (selectedItem) {
        dispatch(getSideEffects({ item_code: selectedItem?.item_code }))
      }
    } else {
      setShowStock(false);
      setShowSideEffect(false);
      setShowDosage(true);
    }

  }, [activeTab]);

  useEffect(() => {
    if (viewDetailsSelected === true && selectedItem) {
      dispatch(getStockHistory({ item_code: selectedItem?.item_code }))
    }
  }, [viewDetailsSelected, selectedItem]);



  useEffect(() => {
    if (postStatus === "loading" && !edit) {
      setHideSaveButton(true);
    } else if (postStatus === "idle") {
      // dispatch(getItems());
      loadItemsData()
      setHideSaveButton(false);
    }
  }, [postStatus]);

  useEffect(() => {
    if (postSideEffectsStatus === "loading" && showSideEffect) {
      setHideSaveButton(true);
    } else if (postSideEffectsStatus === "idle") {
      dispatch(getSideEffects({item_code:selectedItem?.item_code}));
      setHideSaveButton(false);
    }
  }, [postSideEffectsStatus]);


  useEffect(() => {
    if (putSideEffectsStatus === "loading" && showSideEffect) {
      setHideSaveButton(true);
    } else if (putSideEffectsStatus === "idle") {
      //setToEditSideEffect(false);
      dispatch(getSideEffects({item_code:selectedItem?.item_code}));
      setHideSaveButton(false);
    }
  }, [putSideEffectsStatus]);

  

  useEffect(() => {
    if (postDisposeStatus === "loading") {
      setHideSaveButton(true);
    } else if (postDisposeStatus === "idle") {
      // dispatch(getItems());
      loadItemsData()
      setHideSaveButton(false);
    }
  }, [postDisposeStatus]);

  useEffect(() => {
    if (putStatus === "loading" && edit) {
      setHideSaveButton(true);
    } else if (putStatus === "idle") {
      //dispatch(getItems());
      loadItemsData()
      setHideSaveButton(false);
    }

  }, [putStatus]);

  useEffect(() => {
    if (postStockStatus === "loading") {
      setHideSaveButton(true);
    } else if (postStockStatus === "idle") {
      //dispatch(getItems());
      loadItemsData()
      setHideSaveButton(false);
    }
  }, [postStockStatus]);

  useEffect(() => {
    if (status === "loading") {

    } else if (status === "idle") {
      setItemDataList(list);
    }

  }, [status, list]);

  useEffect(() => {
    if (formValues.business_date) {
      loadItemsData()
    }
  }, [formValues]);

  useEffect(() => {
    if (statusStock === "loading") {

    } else if (statusStock === "idle" && listStock.length > 0) {
      let newList = [];
      for (let index = 0; index < listStock.length; index++) {
        let obj = {
          ...listStock[index],
          buy_date : moment(listStock[index].buy_date).format("YYYY-MM-DD")
        }
        newList.push(obj)     
      }
      setStockDataList(newList);
      setViewDetailsSelected(false);
    }

  }, [statusStock, listStock]);

  useEffect(() => {
    if (postStockError) {
      setOpenStockForm(true);
    } else if (postStockStatus === "idle") {
      setOpenStockForm(false)
      dispatch(getStockHistory({ item_code: selectedItem?.item_code }))
      //setOpenDetail(true);
    }


  }, [postStockStatus, postStockError]);

  useEffect(() => {
    if (putStockStatus === "loading") {

    } else if (putStockStatus === "idle") {
      setToEditStock(false)/////////////////////
      // dispatch(getItems());
      loadItemsData()
      dispatch(getStockHistory({ item_code: selectedItem?.item_code }))
      //setOpenDetail(true);

    }

  }, [putStockStatus]);



  useEffect(() => {
    if (statusCategories === "loading") {

    } else if (statusCategories === "idle") {
      let categoryArray = [];
      for (let index = 0; index < listCategories.length; index++) {
        categoryArray.push({ value: listCategories[index]?.id, label: listCategories[index]?.name })
      }
      setCategoryDataList(categoryArray);
    }
  }, [statusCategories, listCategories]);

  useEffect(() => {
    if (statusMeasures === "loading") {

    } else if (statusMeasures === "idle") {
      let measureArray = [];
      for (let index = 0; index < listMeasures.length; index++) {
        measureArray.push({ value: listMeasures[index]?.id, label: listMeasures[index]?.name })
      }
      setMeasuresDataList(measureArray);
    }
  }, [statusMeasures, listMeasures]);

  useEffect(() => {
    if(excelItemsData.length > 0) {
      setPreviewExcel(true)
      //alert(77)
    }
    if(excelItemsData.length > 0 && excelItemsData[0]['itemCode']){
      uploadItemColumns.unshift({label:'Item Code', name:'itemCode'})
    }
    if(excelItemsData.length > 0 && excelItemsData[0]['retailSellPrice']){
      uploadItemColumns.push({label:'Retail Selling Price', name:'retailSellPrice'})
      setWidthDialog("lg")
    }
    if(excelItemsData.length > 0 && excelItemsData[0]['wholeSellPrice']){
      uploadItemColumns.push({label:'Whole Selling Price', name:'wholeSellPrice'})
      setWidthDialog("lg")
    }
  },[excelItemsData])

  useEffect(() => {
    
    if(excelItemsDataNew.length > 0) {
      setExcelItemsData(excelItemsDataNew)
    }
  },[excelItemsDataNew])

  

  
  const readExcel = async (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      //console.log('excelFileData',d)
      setExcelItemsData(d);
    });
  };

  const downloadSample = (a, fileName) => {
    // Convert any array data to a string before exporting
    const newData = a.map((item) => {
      const newItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key])) {
          newItem[key] = item[key].join(", ");
        }
      });
      return newItem;
    });

    const fileType = "application/vnd.ms-excel;charset=utf-8";
    const fileExtension = ".xlsx";
  
    const ws = XLSX.utils.json_to_sheet(newData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };


  return (
    <div>
      <div className="ez-page-action">
        <div className="row col-7">
        <h2 className="page-header">{instCat() === 4 || instCat() === 5 ? "Available Services" : "Available Items"}</h2>
        &nbsp;
        <div style={{ alignContent: "right" }}>
          {isAddAccess() == true &&
          <Inputs
            color="success"
            className={`bx-burst-hover`}
            size="small"
            inputType="button"
            type="submit"
            text={instCat() === 4 || instCat() === 5 ? "New Service" : "New Item"}
            onClick={() => setOpenForm(true)}
            propBackgroundColor={"#367588"}
          />
          }
        </div>
        </div>
        
        <div className="col-5" style={{float:'right', alignContent: "right" }}>
          <div className="row">
          <div className="col-8">
        {isAddAccess() == true &&
        <Inputs
                type="file"
                color="success"
                className={`bx-burst-hover`}
                size="small"
                inputType="file"
                label={"Choose File"}
                onClick={reloadUploaded}
                onChange={async (e) => {
                  const file = e.target.files[0];
                  await readExcel(file);
                }}
              />
        }
        </div>&nbsp;
        <div style={{marginTop:'10px'}}>
        {isAddAccess() == true &&
          <Inputs
            color="success"
            className={`bx-burst-hover`}
            size="small"
            inputType="button"
            type="submit"
            text={"Download Sample"}
            onClick={() => downloadSample(excelItemsSample,'Items_Uploading_File')}
            propBackgroundColor={"#367588"}
          />
          }
          </div>
          </div>
        </div>



        
      </div>

      {
        formOpenItemSearch()
      }

      <div className="row">
        <div className="col-12">
          <div className="card" style={{ backgroundColor: "#e0e0e0" }}>
            <div className="card-body">
              {itemDataList.length > 0 ? (
                <Table
                  columns={itemsTableHead}
                  data={itemDataList}
                  actions={[
                    {
                      action: "Details",
                      renderCheck: isDetailsAccess,
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Update",
                      renderCheck: isEditAccess,
                      onClick: (item) => {
                        editItem(item);
                      },
                    },
                    {
                      action: instCat() === 4 ? "New Float" : "New Stock",
                      renderCheck: isAddStockAccess,
                      onClick: (item) => {
                        newStockClicked(item);
                      },
                    },
                    {
                      action: instCat() === 4 ? "Decrease Float" :"Dispose",
                      renderCheck: isDisposeAccess && isRemoveDisposeAccess,
                      onClick: (item) => {
                        disposeClicked(item);
                      },
                    },
                  ]}
                  downloadFileName={instCat() === 4 || instCat() === 5 ? "Available Services" : "Available Items"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={"Preview Uploaded"}
        width={widthDialog}
        onClose={handleRequestClose}
        open={previewExcel === true}
        text={previewExcel === true ? "Save Items" : "Can not Save"}
        content={() => dialogExcelData()}
        submit={(e) => handleUpload(e)}
        hideSaveButton={(postStatus != 'loading' && excelItemsData.length > 0) ? false: true}
        propBackgroundColor="#367588"
      />

      <MyDialog
        title={"Item Details"}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={showSideEffect ? "Save Side Effect" : "Update"}
        content={() => dialogOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={showSideEffect ? false: true}
        propBackgroundColor="#367588"
      />

      <MyDialog
        title={"Update Item Details"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={!edit ? "Save" : "Update"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor="#367588"
      />

      <MyDialog
        title={instCat() === 4 ? "Decrease Float" : "Dispose Item"}
        width="md"
        onClose={handleRequestClose}
        open={openDisposeForm}
        text={!openDisposeForm ? "Save" : "Update"}
        content={() => formDispose(selectedItem)}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor={"#367588"}
      />

      <MyDialog
        title={instCat() === 4 ? "Float Taking" : "Stock Taking"}
        width="md"
        onClose={handleRequestClose}
        open={openStockForm}
        text={"Save"}
        content={() =>
          formStock(selectedItem && selectedItem.item_code ? selectedItem : null)
        }
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor={"#367588"}
      />
    </div>
  );
};

export default Items;
