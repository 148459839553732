import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
// import userList from "../../assets/JsonData/users-list.json";

import { toast } from "react-toastify";
import MyDialog from "../../components/dialog/Dialog";
import { getUsers,addUser, updateUser, } from "../../app/userSlice";
import MySpinner from "../../components/Spinner/spinner";
import { getRoles } from "../../app/rolesSlice";
import { hasAccess, usersSchema } from "../../common/validators";
import validate from "validate.js";

const userTableHead = [
  { label: "First Name", name: "f_name" },
  { label: "Middle Name", name: "m_name" },
  { label: "Last Name", name: "l_name" },
  { label: "Email", name: "email" },
  { label: "Mobile", name: "mobile" },
  { label: "Status", name: "status" },
];

const statusLov = [
  {label:"Active", value:"A"},
  {label:"Deleted", value:"D"},
  {label:"Handover", value:"H"},
  {label:"Inactive", value:"I"},
  {label:"No Status", value:null},
  {label:"Select One", value:""},
  
]

const userData = localStorage.getItem("userData")
? JSON.parse(localStorage.getItem("userData"))
: null;

const Users = () => {
  const dispatch = useDispatch();
  let [openForm, setOpenForm] = useState(false);
  let [edit, setEdit] = useState(false);
  let [data, setData] = useState([]);
  let [rolesData, setRolesData] = useState([]);  
  const [formValues, setFormValues] = useState({});
  const {status:statusRoles,error:errorRoles,list:rolesList} = useSelector(({roles}) => roles.get);
  const {status,error,list} = useSelector(({users}) => users.get);
  const {status:postStatus,error:postError} = useSelector(({users}) => users.post);
  const {status:putStatus,error:putError} = useSelector(({users}) => users.put);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    dispatch(getUsers());
    
  }, [dispatch]);

  useEffect(() => {
    if(postStatus == "idle" && !postError){
      dispatch(getUsers());
      setFormValues({});
      setOpenForm(false);
      setEdit(false);
    }    
  }, [postStatus,postError]);

  useEffect(() => {
    if(putStatus == "idle" && !putError){
      //dispatch(getUsers());
      setFormValues({});
      setOpenForm(false);
      setEdit(false);
    }    
  }, [putStatus,putError]);

  useEffect(() => {
    if (list && status === "idle") {
      let _data = [];
      list.forEach((item, index) => {
        _data.push({
          id: item.id,
          f_name: item.f_name,
          m_name: item.m_name,
          l_name: item.l_name,
          email: item.email,
          mobile: item.mobile,
          status:
            item.status.toUpperCase() === "A"
              ? "Active"
              : item.status.toUpperCase() === "I"
              ? "Inactive"
              : item.status.toUpperCase() === "H"
              ? "Handover"
              : "Deleted"
        });
      });
      setData(_data);
    }
  }, [list]);

  useEffect(() => {
    let lovRoles = [];
    if(rolesList && rolesList.length > 0){
      for (let index = 0; index < rolesList.length; index++) {
        lovRoles.push({value:rolesList[index]["id"],label:rolesList[index]["name"]})        
      }
      setRolesData(lovRoles);
    }

  },[rolesList])

  const handleRequestClose = () => {
    setErrors([])
    setFormValues({});
    setOpenForm(false);
    setEdit(false);
  };

  const isAccess = (component,action) => {
    return hasAccess(component,action);
  }

  const isEditAccess = () => {
    return hasAccess('staff','edit');
  }


  const editItem = (userItem) => {
    //alert(JSON.stringify(userItem))
    if (userItem) {
      setEdit(true);
      setOpenForm(true);
      dispatch(getRoles())
      setTimeout(() => {
        setFormValues(userItem);
      }, 300);
    }
  };

  const registerUser = (e) => {
    e.preventDefault();
    dispatch(getRoles())
    setOpenForm(true);
    setFormValues({});
  };

  const formOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="f_name"
              size="small"
              label="First Name"
              value={formValues.f_name}
              inputType="text"
              onChange={handleChange}
              disabled={edit ? true : false}
              error={errors["f_name"] ? true : false}
              helperText={errors["f_name"] ? errors.selling_price[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="m_name"
              size="small"
              label="Middle Name"
              value={formValues.m_name}
              inputType="text"
              onChange={handleChange}
              error={errors["m_name"] ? true : false}
              helperText={errors["m_name"] ? errors.m_name[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="l_name"
              size="small"
              label="Last Name"
              value={formValues.l_name}
              inputType="text"
              onChange={handleChange}
              error={errors["l_name"] ? true : false}
              helperText={errors["l_name"] ? errors.l_name[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="email"
              size="small"
              label="Email"
              value={formValues.email}
              inputType="text"
              onChange={handleChange}
              error={errors["email"] ? true : false}
              helperText={errors["email"] ? errors.email[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="mobile"
              size="small"
              label="Mobile"
              value={formValues.mobile}
              inputType="text"
              onChange={handleChange}
              error={errors["mobile"] ? true : false}
              helperText={errors["mobile"] ? errors.mobile[0] : null}
            />
          </div>
          {statusLov.length ? (
        <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="status"
            size="small"
            inputType="select"
            value={formValues.status}
            defaultValue={statusLov[0]["value"]}
            label="Status"
            listLabel="Status"
            listValue="status"
            list={statusLov}
            onChange={handleChange}
            
          />
        </div>
      ) : (
        ""
      )}
       {rolesData.length ? (
        <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="role_id"
            size="small"
            inputType="select"
            value={formValues.role_id}
            defaultValue={rolesData[0]["value"]}
            label="Role"
            listLabel="Role"
            listValue="role_id"
            list={rolesData}
            onChange={handleChange}
            error={errors["role_id"] ? true : false}
              helperText={errors["role_id"] ? errors.role_id[0] : null}
          />
        </div>
      ) : (
        ""
      )}
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="password"
              size="small"
              label="Password"
              value={formValues.password}
              inputType="password"
              onChange={handleChange}
              error={errors["password"] ? true : false}
              helperText={errors["password"] ? errors.password[0] : null}
            />
          </div>
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    //event.preventDefault();
    //alert(event)
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmit = async () => {
    if(isAccess('staff','add')){
      if(edit===true){
        let validationErrors = validate(
          formValues,
          usersSchema
        );
        if(validationErrors){
          setErrors(validationErrors);      
        }else{
        //formValues.id = selectedUser?.id;
        dispatch(updateUser(formValues))
        }
      }else{
        let validationErrors = validate(
          formValues,
          usersSchema
        );
        if(validationErrors){
          setErrors(validationErrors);      
        }else{
        dispatch(addUser(formValues))
        }
      }
    }else{
      toast.error("You are not allowed to access this facility");
    }
    
    //alert(JSON.stringify(formValues))
    //toast.success(JSON.stringify(formValues));
  };

  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Users</h2>
        &nbsp;
         <div style={{alignContent:"right"}}>
           {isAccess('staff','add') === true &&
        <Inputs
          color="success"
          className={`bx-burst-hover`}
          size="small"
          inputType="button"
          type="submit"
          text={"New User"}
          onClick={(e) => registerUser(e)}
          propBackgroundColor={"#367588"}
        />
        }
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {list.length ? (
                <Table
                  columns={userTableHead}
                  data={data}
                  actions={[
                    {
                      action: "Edit",
                      renderCheck: isEditAccess,
                      onClick: (item) => {
                        editItem(item);
                      },
                    } 
                  ]}
                  downloadFileName={"Staff List"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={edit ? "User Update" : "User Registration"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        submit={handleSubmit}
        text={edit ? "Update" : "Register"}
        content={() => formOpen()}
        hideSaveButton={!openForm}
        propBackgroundColor={"#367588"}
      />
    </div>
  );
};

export default Users;
