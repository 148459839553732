import axios from "axios";
import { ADMIN_SERVICE_URL } from "../app/configs";
import { signOut } from "../app/authSlice";

let store;

export const injectStore = (_store) => {
  store = _store;
};

//*ADMIN
export const adminServiceApi = axios.create({
  baseURL: `${ADMIN_SERVICE_URL}/`,
  headers: {
    "Content-Type": "application/json",
  },
});

//MFUKOPESA
export const mfukopesaServiceApi = axios.create({
  baseURL: `https://mfukopesauat.herokuapp.com/`,
  headers: {
    "Content-Type": "application/json",
  },
});

adminServiceApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response === undefined) {
      store.dispatch(signOut());
    }
    return Promise.reject(error);
  }
);
