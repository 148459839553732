import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { fetchSMSBalance, fetchSentSMS, getCustomersList, postCustomers, postPurchaseSms, postSms, putCustomers } from "../api/customersAPI";

const initialState = {
  payload: {
    status: null,
    error: null,
    data: null,
  },
  post: {
    status: null,
    error: null,
    data: null,
  },
  put: {
    status: null,
    error: null,
    data: null,
  },
  get: {
    status: null,
    error: null,
    list: [],
  },
  smsBalance: {
    status: null,
    error: null,
    list: [],
  },
  smsSent: {
    status: null,
    error: null,
    list: [],
  },
  smsSend: {
    status: null,
    error: null,
    list: [],
  },
  smsPurchase: {
    status: null,
    error: null,
    list: [],
  },
};

export const getCustomers = createAsyncThunk("customer/getCustomers", async (payload, { rejectWithValue }) => {
  try {
    const response = await getCustomersList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getSmsBalance = createAsyncThunk("business/getSmsBalance", async (payload, { rejectWithValue }) => {
  try {
    const response = await fetchSMSBalance(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getSmsSent = createAsyncThunk("business/getSmsSent", async (payload, { rejectWithValue }) => {
  try {
    const response = await fetchSentSMS(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addCustomer = createAsyncThunk(
  "customers/addCustomer",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await postCustomers(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const sendSms = createAsyncThunk(
  "business/sendSms",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postSms(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const purchaseSms = createAsyncThunk(
  "business/purchaseSms",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postPurchaseSms(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customers/updateCustomer",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await putCustomers(payload);
      return response;
    } catch (err) {
      // console.log('err',err)
      return rejectWithValue(err.message);
    }
  }
);


export const customersSlice = createSlice({
  name: "customers",
  initialState: initialState,
  reducers: {
    resetItemsState: (state) => {
      state.post.status = null;
      state.post.data = null;
      state.post.error = null;

      state.payload.data = null;
      state.payload.error = null;
      state.payload.status = null;

      state.put.error = null;
      state.put.data = null;

      state.get.status = null;
      state.get.error = null;
      state.get.list = [];

      state.smsBalance.status = null;
      state.smsBalance.error = null;
      state.smsBalance.list = [];

      state.smsSent.status = null;
      state.smsSent.error = null;
      state.smsSent.list = [];

      state.smsSend.status = null;
      state.smsSend.error = null;
      state.smsSend.list = [];

      state.smsPurchase.status = null;
      state.smsPurchase.error = null;
      state.smsPurchase.list = [];
    },
  },
  extraReducers: {
    [getCustomers.pending]: (state) => {
      state.get.status = "loading";
    },
    [getCustomers.fulfilled]: (state, {payload}) => {
      //console.log('actionaction',payload.data)
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
      }
      state.get.status = 'idle';
    },
    [addCustomer.pending]: (state) => {
      state.post.status = "loading";
    },
    [addCustomer.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
     if (status === 201) {
        toast.success("Customer saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't add customer!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.post.status = "idle";
      state.post.data = data;
    },
    [addCustomer.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add customer!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateCustomer.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateCustomer.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Customer updated.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't update customer!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.status = "idle";
      // const index = state.findIndex((user) => user.id === action.payload.id);
      // state[index].updated = action.payload.updated;
    },
    [updateCustomer.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      // console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      //alert(JSON.stringify(action.error))
      toast.warn(action.error.message, {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    ////sms
    [getSmsBalance.pending]: (state) => {
      state.smsBalance.status = "loading";
    },
    [getSmsBalance.fulfilled]: (state, {payload}) => {
      //console.log('actionaction',payload.data)
      const { status, data } = payload;
      if (status === 200) {
        state.smsBalance.list = data;
        state.smsBalance.error = null;
      }else{
        state.smsBalance.error = { message: `${status} - An error occurred` };
      }
      state.smsBalance.status = 'idle';
    },
    [getSmsSent.pending]: (state) => {
      state.smsSent.status = "loading";
    },
    [getSmsSent.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.smsSent.list = data;
        state.smsSent.error = null;
      }else{
        state.smsSent.error = { message: `${status} - An error occurred` };
      }
      state.smsSent.status = 'idle';
    },
    [sendSms.pending]: (state) => {
      state.smsSend.status = "loading";
    },
    [sendSms.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
     if (status === 201) {
        toast.success("Successful", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't send message!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.smsSend.status = "idle";
      state.smsSend.data = data;
    },
    [sendSms.rejected]: (state, action) => {
      state.smsSend.status = "idle";
      state.smsSend.error = { message: "An error occurred" };
      // console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't send message!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [purchaseSms.pending]: (state) => {
      state.smsPurchase.status = "loading";
    },
    [purchaseSms.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
     if (status === 201) {
        toast.success("Request received", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't purchase messages!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.smsPurchase.status = "idle";
      state.smsPurchase.data = data;
    },
    [purchaseSms.rejected]: (state, action) => {
      state.smsPurchase.status = "idle";
      state.smsPurchase.error = { message: "An error occurred" };
      // console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't purchase messages!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    ////sms

  },
});

// export const { resetItemsState } = resetItemsState.actions;
export default customersSlice.reducer;
