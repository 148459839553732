import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getLoansProgressList, postLoansProgress, putLoansProgress } from "../api/loansProgressAPI";

const initialState = {
  payload: {
    status: null,
    error: null,
    data: null,
  },
  post: {
    status: null,
    error: null,
    data: null,
  },
  put: {
    status: null,
    error: null,
    data: null,
  },
  get: {
    status: null,
    error: null,
    list: [],
  },
};

export const getLoansProgress = createAsyncThunk("loans/getLoansProgress", async (payload, { rejectWithValue }) => {
  try {
    const response = await getLoansProgressList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addLoansProgress = createAsyncThunk(
  "loans/addLoan",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await postLoansProgress(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateLoanProgress = createAsyncThunk(
  "loans/updateLoan",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await putLoansProgress(payload);
      return response;
    } catch (err) {
      // console.log('err',err)
      return rejectWithValue(err.message);
    }
  }
);


export const loansProgressSlice = createSlice({
  name: "expenses",
  initialState: initialState,
  reducers: {
    resetItemsState: (state) => {
      state.post.status = null;
      state.post.data = null;
      state.post.error = null;

      state.payload.data = null;
      state.payload.error = null;
      state.payload.status = null;

      state.put.error = null;
      state.put.data = null;

      state.get.status = null;
      state.get.error = null;
      state.get.list = [];
    },
  },
  extraReducers: {
    [getLoansProgress.pending]: (state) => {
      state.get.status = "loading";
    },
    [getLoansProgress.fulfilled]: (state, {payload}) => {
      // console.log('actionaction',payload)
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
      }
      state.get.status = 'idle';
    },
    [addLoansProgress.pending]: (state) => {
      state.post.status = "loading";
    },
    [addLoansProgress.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
     if (status === 201) {
      state.post.status = "posted";
        toast.success("Loan progress saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        state.post.status = "idle";
        toast.warn("Couldn't save!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.post.data = data;
    },
    [addLoansProgress.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't save!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateLoanProgress.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateLoanProgress.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Progress updated.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't update!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.status = "idle";
      // const index = state.findIndex((user) => user.id === action.payload.id);
      // state[index].updated = action.payload.updated;
    },
    [updateLoanProgress.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      //alert(JSON.stringify(action.error))
      toast.warn(action.error.message, {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

// export const { resetItemsState } = resetItemsState.actions;
export default loansProgressSlice.reducer;
