const userPasswordSchema = {
    new_password: {
      presence: {
        allowEmpty: false,
        message: `^New password is required`,
      },
    },
    current_password: {
      presence: {
        allowEmpty: false,
        message: `^Current password is required`,
      },
    },
    confirm_password: {
        presence: {
          allowEmpty: false,
          message: `^Confirmation password is required`,
        },
    },
  };

  const resetPasswordSchema = {
    username: {
      presence: {
        allowEmpty: false,
        message: `^Username is required`,
      },
    },
  };

  const loginSchema = {
    username: {
        presence: {
          allowEmpty: false,
          message: `^Username is required`,
        },
      },
    password: {
      presence: {
        allowEmpty: false,
        message: `^Password is required`,
      },
    },
  };

  const registerSchema = {
    mobile: {
        presence: {
          allowEmpty: false,
          message: `^Mobile number is required`,
        },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: `^Email is required`,
      },
    },
    fullname: {
      presence: {
        allowEmpty: false,
        message: `^Full name is required`,
      },
    },
    business_name: {
      presence: {
        allowEmpty: false,
        message: `^Business name is required`,
      },
    },
    // inst_cat: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Category is required`,
    //   },
    // },
    password: {
      presence: {
        allowEmpty: false,
        message: `^Password is required`,
      },
    },
    confirm_password: {
      presence: {
        allowEmpty: false,
        message: `^Confirmation password is required`,
      },
    },
  };

  const confirmUserSchema = {
    email: {
      presence: {
        allowEmpty: false,
        message: `^Email is required`,
      },
    },
    otp: {
      presence: {
        allowEmpty: false,
        message: `^Code is required`,
      },
    },
    
  };

  const resendCodeSchema = {
    email: {
      presence: {
        allowEmpty: false,
        message: `^Email is required`,
      },
    },
    
  };

  const usersSchema = {
    email: {
      presence: {
        allowEmpty: false,
        message: `^Email is required`,
      },
    },
    f_name: {
      presence: {
        allowEmpty: false,
        message: `^First name is required`,
      },
    },
    m_name: {
      presence: {
        allowEmpty: false,
        message: `^Middle name is required`,
      },
    },
    l_name: {
      presence: {
        allowEmpty: false,
        message: `^Last name is required`,
      },
    },
    mobile: {
      presence: {
        allowEmpty: false,
        message: `^Mobile number is required`,
      },
    },
    // password: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Password is required`,
    //   },
    // },
    role_id: {
      presence: {
        allowEmpty: false,
        message: `^Role is required`,
      },
    },
    
  };

  const businessSchema = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^Name is required`,
      },
    },
    inst_cat: {
      presence: {
        allowEmpty: false,
        message: `^Category is required`,
      },
    },
  };

  const taxSchema = {
    tax_name: {
      presence: {
        allowEmpty: false,
        message: `^Name is required`,
      },
    },
    rate: {
      presence: {
        allowEmpty: false,
        message: `^Rate is required`,
      },
    },
  };

  const messageSchema = {
    messageBody: {
      presence: {
        allowEmpty: false,
        message: `^Message is required`,
      },
    },
    via: {
      presence: {
        allowEmpty: false,
        message: `^How do you send?`,
      },
    },
    receiversGroup: {
      presence: {
        allowEmpty: false,
        message: `^Receiver group is required`,
      },
    },
  };

  const purchaseSchema = {
    counts: {
      presence: {
        allowEmpty: false,
        message: `^How many messages do you want?`,
      },
    },
  };

  const expenseSchema = {
    amount: {
      presence: {
        allowEmpty: false,
        message: `^Amount is required`,
      },
    }
  };

  const loanReviewSchema = {
    remarks: {
      presence: {
        allowEmpty: false,
        message: `^Remarks is required`,
      },
    },
    
  };

  const loansSchema = {
    amount: {
      presence: {
        allowEmpty: false,
        message: `^Amount is required`,
      },
    },
    tin: {
      presence: {
        allowEmpty: false,
        message: `^TIN is required`,
      },
    },
    product_id: {
      presence: {
        allowEmpty: false,
        message: `^Product is required`,
      },
    },
    f_name: {
      presence: {
        allowEmpty: false,
        message: `^Name is required`,
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: `^Email is required`,
      },
    },
    mobile: {
      presence: {
        allowEmpty: false,
        message: `^Phone is required`,
      },
    },
    tenure_months: {
      presence: {
        allowEmpty: false,
        message: `^Tenure is required`,
      },
    },
    address: {
      presence: {
        allowEmpty: false,
        message: `^Address is required`,
      },
    },
    business_license_expiry: {
      presence: {
        allowEmpty: false,
        message: `^Date is required`,
      },
    },
    
  };

  const creditRepaySchema = {
    pay_date: {
      presence: {
        allowEmpty: false,
        message: `^Date is required`,
      },
    },
    amount: {
      presence: {
        allowEmpty: false,
        message: `^Amount is required`,
      },
    },
    
  };

  const barBatchSchema = {
    // batch_date: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Date is required`,
    //   },
    // },
    batch_type: {
      presence: {
        allowEmpty: false,
        message: `^Type is required`,
      },
    },
    item_code: {
      presence: {
        allowEmpty: false,
        message: `^Item is required`,
      },
    },
    barcode_value: {
      presence: {
        allowEmpty: false,
        message: `^Value is required`,
      },
    },
    // qty: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Quantity is required`,
    //   },
    // },
    
  };

  const customerSchema = {
    customer_name: {
      presence: {
        allowEmpty: false,
        message: `^Name is required`,
      },
    },
    customer_mobile: {
      presence: {
        allowEmpty: false,
        message: `^Phone is required`,
      },
    },
    
  };

  const itemSchema = {
    // item_code: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Code is required`,
    //   },
    // },
    item_description: {
      presence: {
        allowEmpty: false,
        message: `^Name is required`,
      },
    },
    selling_price: {
      presence: {
        allowEmpty: false,
        message: `^Selling price is required`,
      },
    },
    w_selling_price: {
      presence: {
        allowEmpty: false,
        message: `^Whole selling price is required`,
      },
    },
    unit_of_measure: {
      presence: {
        allowEmpty: false,
        message: `^Measure is required`,
      },
    },
    item_category: {
      presence: {
        allowEmpty: false,
        message: `^Category is required`,
      },
    },
    items_per_box: {
      presence: {
        allowEmpty: false,
        message: `^How many in a box?`,
      },
    },
    
  };

  const serviceSchema = {
    item_description: {
      presence: {
        allowEmpty: false,
        message: `^Name is required`,
      },
    },
    selling_price: {
      presence: {
        allowEmpty: false,
        message: `^Selling price is required`,
      },
    },
    w_selling_price: {
      presence: {
        allowEmpty: false,
        message: `^Whole selling price is required`,
      },
    }
    
  };

  
  const stockSchema = {
    item_code: {
      presence: {
        allowEmpty: false,
        message: `^Code is required`,
      },
    },
    // item_description: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Name is required`,
    //   },
    // },
    buying_price: {
      presence: {
        allowEmpty: false,
        message: `^Buying price is required`,
      },
    },
    qty: {
      presence: {
        allowEmpty: false,
        message: `^Quantity is required`,
      },
    },
    unit_of_measure: {
      presence: {
        allowEmpty: false,
        message: `^Measure is required`,
      },
    },
    // batch_number: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Batch number is required`,
    //   },
    // },
    buy_date: {
      presence: {
        allowEmpty: false,
        message: `^Purchase date is required`,
      },
    },
    // expiry_date: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Expiry date is required`,
    //   },
    // },
    
  };

  const saleSchema = {
    //item_code
    sale_category: {
      presence: {
        allowEmpty: false,
        message: `^Category is required`,
      },
    },
    sell_type: {
      presence: {
        allowEmpty: false,
        message: `^Pay type is required`,
      },
    },
  }

  const orderSchema = {
    // order_date: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Order date is required`,
    //   },
    // },
    // deliver_date: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Deliver date is required`,
    //   },
    // },
    // unit_of_measure: {
    //   presence: {
    //     allowEmpty: false,
    //     message: `^Items are required`,
    //   },
    // },
    counts: {
      presence: {
        allowEmpty: false,
        message: `^Items are required`,
      },
    },
    
  };

  const disposeSchema = {
    item_code: {
      presence: {
        allowEmpty: false,
        message: `^Code is required`,
      },
    },
    item_description: {
      presence: {
        allowEmpty: false,
        message: `^Name is required`,
      },
    },
    dispose_reason: {
      presence: {
        allowEmpty: false,
        message: `^Reason price is required`,
      },
    },
    qty: {
      presence: {
        allowEmpty: false,
        message: `^Quantity is required`,
      },
    },
    destroy_date: {
      presence: {
        allowEmpty: false,
        message: `^Dispose date is required`,
      },
    },
    
  };

  const hasAccess = (component,action) => {
    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    let isTrue = false;
    //console.log('userDatauserData',userData)
    //alert(JSON.stringify(userData.data.userResources))   
    if(userData && userData.data.userResources){
      for (let index = 0; index < userData.data.userResources.length; index++) {
        if(userData.data.userResources[index]?.component === component && userData.data.userResources[index]?.action === action ){
          isTrue = true;
        } 
        
      }
    }
    //alert(isTrue+" "+component)
    return isTrue
  }

  const instCat = () => {
    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    
    return userData?.data?.inst_cat
  }

  const isManagement = () => {
    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    let isTrue = false;
    if(userData && userData?.data?.role_id == 10){
      isTrue = true;
    }
    return isTrue
  }

  const isProduction = () => {
    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    let isTrue = false;
    if(userData && userData?.data?.role_id == 9){
      isTrue = true;
    }
    return isTrue
  }

export {
    userPasswordSchema,
    loginSchema,
    resetPasswordSchema,
    hasAccess,
    registerSchema,
    confirmUserSchema,
    resendCodeSchema,
    businessSchema,
    disposeSchema,
    stockSchema,
    itemSchema,
    serviceSchema,
    usersSchema,
    loansSchema,
    loanReviewSchema,
    taxSchema,
    customerSchema,
    orderSchema,
    creditRepaySchema,
    barBatchSchema,
    expenseSchema,
    isProduction,
    isManagement,
    instCat,
    purchaseSchema,
    messageSchema,
    saleSchema
};