import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getLoansList, loanDetails, postLoans, putLoans,getCalculator } from "../api/loansAPI";

const initialState = {
  payload: {
    status: null,
    error: null,
    data: null,
  },
  calculator: {
    status: null,
    error: null,
    data: null,
  },
  post: {
    status: null,
    error: null,
    data: null,
  },
  put: {
    status: null,
    error: null,
    data: null,
  },
  get: {
    status: null,
    error: null,
    list: [],
  },
};

export const getLoans = createAsyncThunk("loans/getLoans", async (payload, { rejectWithValue }) => {
  try {
    const response = await getLoansList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getLoanDetails = createAsyncThunk("loans/getLoanDetails", async (payload, { rejectWithValue }) => {
  try {
    const response = await loanDetails(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getLoanCalculator = createAsyncThunk("loans/getLoanCalculator", async (payload, { rejectWithValue }) => {
  try {
    const response = await getCalculator(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addLoan = createAsyncThunk(
  "loans/addLoan",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await postLoans(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateLoan = createAsyncThunk(
  "loans/updateLoan",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await putLoans(payload);
      return response;
    } catch (err) {
      // console.log('err',err)
      return rejectWithValue(err.message);
    }
  }
);


export const loansSlice = createSlice({
  name: "expenses",
  initialState: initialState,
  reducers: {
    resetItemsState: (state) => {
      state.post.status = null;
      state.post.data = null;
      state.post.error = null;

      state.payload.data = null;
      state.payload.error = null;
      state.payload.status = null;

      state.put.error = null;
      state.put.data = null;

      state.get.status = null;
      state.get.error = null;
      state.get.list = [];
    },
  },
  extraReducers: {
    [getLoans.pending]: (state) => {
      state.get.status = "loading";
    },
    [getLoans.fulfilled]: (state, {payload}) => {
      // console.log('actionaction',payload)
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
      }
      state.get.status = 'idle';
    },
    [getLoanDetails.pending]: (state) => {
      state.payload.status = "loading";
    },
    [getLoanDetails.fulfilled]: (state, {payload}) => {
      // console.log('actionaction',payload)
      const { status, data } = payload;
      if (status === 200) {
        state.payload.data = data;
        state.payload.error = null;
      }else{
        state.payload.error = { message: `${status} - An error occurred` };
      }
      state.payload.status = 'idle';
    },
    [getLoanCalculator.pending]: (state) => {
      state.calculator.status = "loading";
    },
    [getLoanCalculator.fulfilled]: (state, {payload}) => {
      // console.log('actionaction',payload)
      const { status, data } = payload;
      if (status === 200) {
        state.calculator.data = data;
        state.calculator.error = null;
      }else{
        state.calculator.error = { message: `${status} - An error occurred` };
      }
      state.calculator.status = 'idle';
    },
    [addLoan.pending]: (state) => {
      state.post.status = "loading";
    },
    [addLoan.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
     if (status === 201) {
        toast.success("Loan saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't add loan!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.post.status = "idle";
      state.post.data = data;
    },
    [addLoan.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add loan!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateLoan.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateLoan.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Loan updated.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't update loan!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.status = "idle";
      // const index = state.findIndex((user) => user.id === action.payload.id);
      // state[index].updated = action.payload.updated;
    },
    [updateLoan.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      //alert(JSON.stringify(action.error))
      toast.warn(action.error.message, {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

// export const { resetItemsState } = resetItemsState.actions;
export default loansSlice.reducer;
