import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { useDispatch, useSelector } from "react-redux";
import { getResources } from "../../app/resourcesSlice";
import { addRole, updateRole, getRoles, getRolesResources,updateRoleResource } from "../../app/rolesSlice";

const itemsTableHead = [
  { label: "Name", name: "name" },
  { label: "Status", name: "status" },
  "action",
];

const resourcesTableHead = [
  { label: "Name", name: "resource_name" },
  { label: "Status", name: "status" },
  "action",
];


const Roles = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [itemDataList, setItemDataList] = useState([]);
  const [resourcesDataList, setResourcesDataList] = useState([]);
  const [resourcesGroupList, setResourcesGroupList] = useState([]);



  const dispatch = useDispatch();

  const { error: resourcesError, status: resourcesStatus, list: resourcesList } = useSelector(
    ({ resources }) => resources.get
  );

  const { error, status, list } = useSelector(
    ({ roles }) => roles.get
  );

  const { error:rolesResourcesError, status:rolesResourcesStatus, list:rolesResourcesList } = useSelector(
    ({ roles }) => roles.rolesResources
  );

  const { error: postError, status: postStatus } = useSelector(
    ({ roles }) => roles.post
  );

  const { error: putErrorRolesResources, status: putStatusRolesResources } = useSelector(
    ({ roles }) => roles.putRolesResources
  );

  const { error: putError, status: putStatus } = useSelector(
    ({ roles }) => roles.put
  );

  const { get: getActionsData } = useSelector(
    ({ common }) => common.actions
  );

  const { get: getComponentsData } = useSelector(
    ({ common }) => common.components
  );



  const handleRequestClose = () => {
    setFormValues({});
    setOpenForm(false);
    setOpenDetail(false);
    setEdit(false);
  };

  const viewItem = (item) => {
    setSelectedItem(item);
    dispatch(getRolesResources({role_id:item?.id}));   
  };

  const removeRoleResource = (item) => {
    dispatch(updateRoleResource({id:item?.id,status:"X"}));   
  };

  const viewItemWithResources = (item) => {
    if (item) {
      let newItem = {
        ...item,
        resource_group:item.resource_group != null ? item.resource_group : null       
      } 
      
      setViewDetailsSelected(true)

      //setEdit(true);
      setFormValues(item);
      //setSelectedItem(newItem);
      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const editItem = (item) => {
    setEdit(true);
    setSelectedItem(item);
    dispatch(getRolesResources({role_id:item?.id}));    
  };

  const editItemWithResources = (item) => {
    if (item) {
      let newItem = {
        ...item,
        resource_group:item.resource_group != null ? item.resource_group : null       
      } 
     
      setFormValues(newItem);
      // setSelectedItem(newItem);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };


  const formOpen = () => {
    return (
      <>
        <div className="row">
          {/* <div className="col-sm-12 col-md-12 col-12">
            <h3>Seller Details</h3>
          </div> */}
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="name"
              size="small"
              label="Role Name"
              value={formValues.name}
              inputType="text"
              disabled={edit ? true : false}
              onChange={handleChange}
            />
          </div>

          {resourcesDataList.length ? (
            <div className="col-sm-12 col-md-6 col-6">
              <Inputs
                name="resource_group"
                size="small"
                inputType="select"
                value={formValues.resource_group}
                defaultValue={resourcesDataList[0]}
                label="Resources"
                listLabel="Resources"
                listValue="resource_group"
                list={resourcesDataList}
                onChange={handleChangeResource}
              />
            </div>
          ) : (
            ""
          )}

        </div>
      </>
    );
  };


  const dialogOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <span><b>Name</b></span>&nbsp;&nbsp;
            <span>{selectedItem?.name}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span><b>Status</b></span>&nbsp;&nbsp;
            <span>{selectedItem?.status}</span>
          </div>
          <div className="col-sm-12 col-md-12 col-12">
          <br/>
            <b>Resources</b>
            <br/> <br/>
            {/* {selectedItem.resource_group != null ? JSON.stringify(selectedItem.resource_group):"No Resources"} */}
            {selectedItem.resource_group != null && selectedItem.resource_group.length > 0 ? (
                <Table
                  columns={resourcesTableHead}
                  data={selectedItem.resource_group}
                  actions={[
                    {
                      action: "Remove",
                      onClick: (item) => {
                        //viewItem(item);
                        removeRoleResource(item)
                      },
                    },
                  ]}
                  downloadFileName={"Roles Resources"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
          </div>
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleChangeResource = (event) => {
    //alert(66)
    //alert(JSON.stringify(event.target))
    //alert(JSON.stringify(event.target.value))

    let resourceGroupArray = [];
    //resourceGroupArray = !(edit && selectedItem && selectedItem.resource_group) ? [] : selectedItem.resource_group;
   
      

    // if(edit && selectedItem && selectedItem.resource_group){
    //   const indexResource = resourceGroupArray.indexOf(event.target.value);
    //   if (indexResource > -1) { // only splice array when item is found
    //     resourceGroupArray.splice(indexResource, 1); // 2nd parameter means remove one item only
    //   }      
    // }else{
    //   const indexResource = resourcesGroupList.indexOf(event.target.value);
    //   if (indexResource > -1) { // only splice array when item is found
    //     resourcesGroupList.splice(indexResource, 1); // 2nd parameter means remove one item only
    //   }
    // }
    

    // for (let index = 0; index < resourcesGroupList.length; index++) {
    //   if(resourcesGroupList[index] === event.target.value){
    //   };      
    // }

    resourceGroupArray.push(event.target.value)
    //alert(JSON.stringify(resourceGroupArray))
    setResourcesGroupList(resourceGroupArray)

    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });

  };


  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    formValues.resource_group = JSON.stringify(resourcesGroupList);
    if (edit) {
      let newItem = {
        "id": selectedItem?.id,
        "name": formValues?.name,
        "resource_group": formValues?.resource_group,
        "status": formValues.status ? formValues.status : "A",
      }
      dispatch(updateRole(newItem));
    } else {
      let newItem = {
        "name": formValues?.name,
        "resource_group": formValues?.resource_group,
        "status": formValues.status ? formValues.status : "A",
      }
      dispatch(addRole(newItem));
    }

  };



  useEffect(() => {
    dispatch(getResources());
    dispatch(getRoles());
  }, [dispatch]);


  useEffect(() => {
    if (postStatus === "loading" && !edit) {
      setHideSaveButton(false);
    } else if (postStatus === "idle") {
      dispatch(getRoles());
      setHideSaveButton(false);
    }
  }, [postStatus]);

  useEffect(() => {
    if (putStatus === "loading" && edit) {
      setHideSaveButton(false);
    } else if (putStatus === "idle") {
      dispatch(getRoles());
      dispatch(getRolesResources({role_id:selectedItem?.id})); 
      setHideSaveButton(false);
    }

  }, [putStatus]);

  useEffect(() => {
    if (putStatusRolesResources === "loading") {
      
    } else if (!putErrorRolesResources &&  putStatusRolesResources === "idle") {
      dispatch(getRolesResources({role_id:selectedItem?.id})); 
    }

  }, [putStatusRolesResources,putErrorRolesResources]);


  useEffect(() => {
    if (status === "loading") {

    } else if (status === "idle") {
      setItemDataList(list);
    }

  }, [status, list]);

  useEffect(async () => {
    if (rolesResourcesStatus === "loading") {

    } else if (rolesResourcesStatus === "idle") {
      // alert(rolesResourcesStatus)
      //selectedItem.resource_group = rolesResourcesList;

      if(edit === true && rolesResourcesList && rolesResourcesList.length > 0){
        // alert(rolesResourcesList.length)
       let resourcesArray = [];
        for (let index = 0; index < resourcesList.length; index++) {
          //resourcesArray.push(resourcesList[index])
          resourcesArray.push({ label: resourcesList[index]["name"], value: resourcesList[index] })
        }

        for (let index = 0; index < resourcesArray.length; index++) {
          // resourcesArray.push({ label: resourcesList[index]["name"], value: resourcesList[index] })
          if(rolesResourcesList.length > 0){
            for (let kindex = 0; kindex < rolesResourcesList.length; kindex++) {
              if(rolesResourcesList[kindex]["resource_id"] == resourcesArray[index]["value"]["id"]){
                  if (index > -1) { // only splice array when item is found
                    resourcesArray.splice(index, 1); // 2nd parameter means remove one item only
                  }
              }
            }
          }          
        }
        setResourcesDataList(resourcesArray);
      }

      let newSelectedItem = {
        ...selectedItem,
        resource_group:rolesResourcesList
      }
      setSelectedItem(newSelectedItem);
      edit === true ? editItemWithResources(newSelectedItem) : viewItemWithResources(newSelectedItem);
    }

  }, [rolesResourcesStatus, rolesResourcesList]);

  useEffect(() => {
    if (status === "loading") {

    } else if (status === "idle") {
      let resourcesArray = [];
      for (let index = 0; index < resourcesList.length; index++) {
        resourcesArray.push({ label: resourcesList[index]["name"], value: resourcesList[index] })
      }
      setResourcesDataList(resourcesArray);
    }

  }, [resourcesStatus, resourcesList]);



  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Available Roles</h2>
        &nbsp;
        <div style={{ alignContent: "right" }}>
          <Inputs
            color="success"
            className={`bx-burst-hover`}
            size="small"
            inputType="button"
            type="submit"
            text={"New Role"}
            onClick={() => setOpenForm(true)}
          />
          &nbsp;
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {itemDataList.length > 0 ? (
                <Table
                  columns={itemsTableHead}
                  data={itemDataList}
                  actions={[
                    {
                      action: "Details",
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Update",
                      onClick: (item) => {
                        editItem(item);
                      },
                    }
                  ]}
                  downloadFileName={"Available Roles"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <Detaildialog
        title={"Role Details"}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={!edit ? "Save" : "Update"}
        content={() => dialogOpen()}
      />

      <MyDialog
        title={"Update Role Details"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={!edit ? "Save" : "Update"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
      />

    </div>
  );
};

export default Roles;
