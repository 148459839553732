import { adminServiceApi } from "../services";

export const getItemsCategoryList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("categories", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUnitMeasuresList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("measures", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getInstitutionCategoriesList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    //params: query,
    headers: {
      //Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("publics/institution_categories", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getActionsList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("public/actions", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getComponentsList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("public/components", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const base64toBlob = (data) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
      out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
};


