import {
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "react-bootstrap";
// import { useDispatch } from "react-redux";

import DownloadExcel from "./downloadExcel";
import DownloadPDF from "./downloadPDF";
import "./table.css";

const Table = ({
  data,
  columns,
  downloadFileName,
  dateFrom,
  dateTo,
  actions,
}) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [pageRange, setPageRange] = useState(10);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  // const [order, setOrder] = useState("ASC");
  // const [data, setData] = useState(filteredData);

  let pages = 1;

  const [searchParam, setSearchParam] = useState("");

  const [dataShow, setDataShow] = useState([]);
  const [downloadNow, setDownloadNow] = useState(false);

  const [isOpenActions, setIsOpenActions] = useState(() => {
    let _initState = [];
    data.forEach((item, index) => {
      _initState.push(false);
    });

    return _initState;
  });

  const handleChange = (event) => {
    if (event.target.name !== undefined) {
      setPageRange(event.target.value);
      selectedPage(1);
    }
  };

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  // const sorting = (col) => {
  //   if (order === "ASC") {
  //     const sorted = [...data].sort((a, b) =>
  //       a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
  //     );
  //     setData(sorted);

  //     setOrder("DESC");
  //   }
  //   if (order === "DESC") {
  //     const sorted = [...data].sort((a, b) =>
  //       a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
  //     );
  //     setData(sorted);
  //     setOrder("ASC");
  //   }
  // };

  useEffect(() => {
    let _filtered = data.filter((val) => {
      if (searchParam === "") {
        return val;
      } else if (columns !== []) {
        let matched = false;
        columns.forEach((item) => {
          if (
            val[`${item.name}`] &&
            val[`${item.name}`]
              .toString()
              .toLocaleLowerCase()
              .includes(searchParam.toLocaleLowerCase())
          ) {
            matched = true;
          }
        });
        if (matched) {
          return val;
        }
        return "";
      } else {
        return "";
      }
    });
    setFilteredData(_filtered);
    setCurrentPageNo(1);
  }, [searchParam, data, columns]);

  const selectedPage = (page) => {
    const start = pageRange * page;
    const end = start + pageRange;

    setDataShow(data.slice(start, end));
    setCurrentPageNo(page);
  };

  const BuildActions = (index, _item) => {
    if (actions != null) {
      return (
        <td key={`td-action-index`} className="td-action">
          <IconButton
            className="action-menu-icon"
            onClick={(e) => {
              e.preventDefault();
              let _initState = [];
              filteredData.forEach((item) => {
                _initState.push(false);
              });
              _initState[index] = true;
              setIsOpenActions(_initState);
            }}
          >
            {/* <IconMenu className="icon" /> */}
            <i className="bx bx-menu"></i>
          </IconButton>
          {isOpenActions[index] && (
            <div key={`actions-popper-${index}`} className="actions-popper">
              <ClickAwayListener
                key={`clickaway-listener-${index}`}
                onClickAway={(e) => {
                  let _initState = [];
                  filteredData.forEach((item) => {
                    _initState.push(false);
                  });
                  setIsOpenActions(_initState);
                }}
              >
                <MenuList id={`menu-list-${index}`}>
                  {actions.map((action, _index) => {
                    let { renderCheck } = action;
                    let render = true;
                    if (renderCheck) {
                      render = renderCheck(_item);
                    }
                    if (render) {
                      return (
                        <>
                          <MenuItem
                            key={`action-menu-item-${_index}`}
                            role="appbar-menu_item"
                            id={`action-menu-item-${_index}`}
                            onClick={(e) => {
                              e.preventDefault();
                              action.onClick(_item);
                              let _initState = [];
                              filteredData.forEach((item) => {
                                _initState.push(false);
                              });
                              setIsOpenActions(_initState);
                            }}
                          >
                            {action.action}
                          </MenuItem>
                        </>
                      );
                    } else return <></>;
                  })}
                </MenuList>
              </ClickAwayListener>
            </div>
          )}
        </td>
      );
    } else return <></>;
  };

  const printNow = () => {
    navigate("/print", {
      state: {
        data: filteredData,
        columns: columns,
        title: downloadFileName,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  };

  return (
    <div>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <div className="ez-search">
          <input
            type="tel"
            placeholder="Search here..."
            onChange={(e) => {
              setSearchParam(e.target.value);
            }}
          />
          <i className="bx bx-search"></i>
        </div>

        <div className="ez-download">
          {/* Download */}

          <Tooltip title="Download" placement="top">
            <i
              class="bx bx-cloud-download"
              onClick={() => {
                setDownloadNow(true);
              }}
            ></i>
          </Tooltip>
          {downloadNow && (
            <div className="download-popper">
              <ClickAwayListener
                onClickAway={(e) => {
                  setDownloadNow(false);
                }}
              >
                <MenuList>
                  <MenuItem
                    role="appbar-menu_item"
                    id={`menu-item-download-pdf}`}
                    key={`menu-item-download-pdf`}
                  >
                    <DownloadPDF
                      data={filteredData}
                      columns={columns}
                      fileName={downloadFileName}
                      toDate={dateTo}
                      fromDate={dateFrom}
                    />
                  </MenuItem>
                  <MenuItem
                    role="appbar-menu_item"
                    id={`menu-item-download-excel}`}
                    key={`menu-item-download-excel}`}
                    onClick={() => {
                      setDownloadNow(false);
                      // dispatch(
                      //   showSnackbar({
                      //     message: "Downloading please wait...",
                      //     type: "info",
                      //     open: true,
                      //   })
                      // );
                    }}
                  >
                    <DownloadExcel
                      data={filteredData}
                      columns={columns}
                      fileName={downloadFileName}
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </div>
          )}

          {/* Print */}
          <Tooltip title="Print" placement="top">
            <i
              style={{ fontSize: "1.4rem" }}
              class="bx bxs-printer"
              name="printer"
              type="solid"
              color="secondary"
              onClick={printNow}
            ></i>
          </Tooltip>
        </div>
      </div>

      {/* table data */}

      <div className="ez-table-wrapper">
        <table>
          {columns ? (
            <thead>
              <tr>
                {columns.map((item, index) => (
                  <th className="trow" key={index}>
                    {item.label}
                  </th>
                ))}
                {actions != null && <th className="ez-action">Action</th>}
              </tr>
            </thead>
          ) : (
            ""
          )}
          {dataShow ? (
            <tbody>
              {filteredData
                .slice(
                  (currentPageNo - 1) * pageRange,
                  (currentPageNo - 1) * pageRange + pageRange
                )
                .map((item, index) => (
                  <tr key={`tr-item-${index}`}>
                    {columns.map((col, _index) => (
                      <td
                        key={`td-row-${index}-col-${_index}`}
                        className={
                          "text-align-" +
                          (col.hasOwnProperty("options")
                            ? col.options.align ?? "left"
                            : "left")
                        }
                      >
                        {item[col.name] ?? ""}
                      </td>
                    ))}
                    {BuildActions(index, item)}
                  </tr>
                ))}
            </tbody>
          ) : null}
        </table>
        {/* table footer */}
        {pages > 0 ? (
          <div className="table-pagination">
            <div className="left">
              <div className="table-range">
                <span>
                  {filteredData.length} item(s) | Page {currentPageNo} of{" "}
                  {Math.ceil(filteredData.length / pageRange)}
                </span>
              </div>
            </div>
            <div className="right">
              <select value={pageRange} onChange={handleChange}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
              <span>rows</span>

              <Tooltip placement="top" title="First Page">
                <span>
                  <button
                    className="leftMost"
                    disabled={currentPageNo === 1}
                    onClick={(e) => {
                      e.preventDefault();
                      if (currentPageNo > 1) {
                        setCurrentPageNo(1);
                      }
                    }}
                  >{`<<`}</button>
                </span>
              </Tooltip>

              <Tooltip placement="top" title="Previous Page">
                <span>
                  <button
                    disabled={!(currentPageNo > 1)}
                    onClick={(e) => {
                      e.preventDefault();
                      if (currentPageNo > 1) {
                        setCurrentPageNo(currentPageNo - 1);
                      }
                    }}
                  >{`<`}</button>
                </span>
              </Tooltip>

              <Tooltip placement="top" title="Next Page">
                <span>
                  <button
                    disabled={
                      !(currentPageNo < filteredData.length / pageRange)
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (currentPageNo < filteredData.length / pageRange) {
                        setCurrentPageNo(currentPageNo + 1);
                      }
                    }}
                  >{`>`}</button>
                </span>
              </Tooltip>

              <Tooltip placement="top" title="Last Page">
                <span>
                  <button
                    disabled={
                      currentPageNo >=
                      Math.ceil(filteredData.length / pageRange)
                    }
                    className={"rightMost"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        currentPageNo <
                        Math.ceil(filteredData.length / pageRange)
                      ) {
                        setCurrentPageNo(
                          Math.ceil(filteredData.length / pageRange)
                        );
                      }
                    }}
                  >{`>>`}</button>
                </span>
              </Tooltip>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Table;
