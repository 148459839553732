import React, { useState, useEffect, useCallback, useRef } from "react";
import Inputs from "../../components/inputs/Inputs";
import logo from "../../assets/images/taiPOS.png";
import "./login.css";
import Footer from "../../components/footer/Footer";

import { registerself } from "../../app/userSlice";
import { useSelector, useDispatch } from "react-redux";
import Particles from 'react-particles';
import { loadFull } from "tsparticles";
import { Button, Grid } from '@material-ui/core';
import dawa from "../../assets/images/syrups.svg";
import tablet from "../../assets/images/tablet.svg";
import syringe from "../../assets/images/syringe.svg";
import bottle from "../../assets/images/bottle.svg";
import { registerSchema } from "../../common/validators";
import validate from "validate.js";
import { useNavigate } from "react-router-dom";
import { getInstitutionCategories } from "../../app/commonSlice";
import { ClipLoader } from "react-spinners";
import AppAutoComplete from "../../common/appautocomplete";



const Register = () => {
  // const auth = useSelector((state) => state.auth);
  const [errors, setErrors] = useState([]);
  const [instCategories, setInstCategories] = useState([]);

  const [showResetButton, setShowResetButton] = useState(true);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputRef = useRef(null);


  const { auth } = useSelector((state) => state.auth);
  const { error, status, data } = useSelector(
    ({ users }) => users.post
  );
  const { error: instCategoriesError, status: instCategoriesStatus, list: instCategoriesList } = useSelector(
    ({ common }) => common.institutionCategories.get
  );


  const handleChange = (event) => {
      // alert(JSON.stringify(event.target))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const loadCategories = () => {
    dispatch(getInstitutionCategories())
  }

  useEffect(async () => {
    inputRef.current.focus();
    loadCategories()
    
  }, []);

 


  // useEffect(()=>{
  //   if(status === 'posted'){
  //     navigate('/login')
  //   }
  // },[status])

  

  useEffect(() => {
    if (instCategoriesStatus === "loading") {

    } else if (instCategoriesStatus === "idle" && instCategoriesList) {

      let categoryArray = [];
      for (let index = 0; index < instCategoriesList.length; index++) {
        categoryArray.push({ value: instCategoriesList[index]["id"], label: instCategoriesList[index]["name"] })
      }
      setInstCategories(categoryArray);
    }
  }, [instCategoriesStatus, instCategoriesError, instCategoriesList]);

  useEffect(() => {
    //alert(JSON.stringify(auth));
    if (auth?.data) {
      localStorage.setItem('userData', JSON.stringify(auth?.data))
    }
  }, [auth]);

  useEffect(() => {
    if (status === 'loading') {
      setShowResetButton(false)
    } else
      if (status === 'posted' && error === null) {
        setShowResetButton(true)
        // window.location.replace("/confirm_registration")
        navigate("/confirm_registration");
      }
      else
        if (error) {
          setShowResetButton(true)
        }
  }, [status, error, data]);

  const backToLogin = (e) => {
    e.preventDefault();
    // window.location.replace("/login")
    navigate("/login");
  }

  const submit = (e) => {
    e.preventDefault();

    let validationErrors = validate(
      formValues,
      registerSchema
    );
    let newFormValues;
    if(!formValues.inst_cat){
      newFormValues = {...formValues,inst_cat: 2}
      setFormValues({...formValues,inst_cat: 2})
    }    


    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      // console.log('newFormValues reg',newFormValues)
      // console.log('formValues reg',formValues)
      dispatch(
        registerself(formValues)
      );

      // //window.location.replace("/confirm_registration")

    }

  };

  const toHome = () => {
    navigate("/intro");
  }


  const particlesInit = useCallback(async (engine) => {
    //console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    //await console.log(container);
  }, []);

  //alert(localStorage.getItem('token'))
  return (
    <div className="register-page">
      <div style={{ zIndex: "5 !íportant" }}>
      </div>

      <div className="row">
        <div className="col-12">
          <img onClick={(e) => {
                e.preventDefault();
                toHome()
              }} src={logo} alt="logo" height="80vh" />
          <div className="card" style={{ backgroundColor: "#ffffff" }}>
            <h2 onClick={(e) => {
                e.preventDefault();
                toHome()
              }} className="page-header">TaiPOS</h2>
            <div
              className="card-body"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Inputs
                    name="fullname"
                    label="Full name"
                    className="input-class"
                    size="small"
                    value={formValues.fullname}
                    inputType="text"
                    onChange={handleChange}
                    error={errors["fullname"] ? true : false}
                    helperText={errors["fullname"] ? errors.fullname[0] : null}
                    inputRef={inputRef}
                  />
                </Grid>
                <br />
                <Grid item xs={12} md={12}>
                  <Inputs
                    name="business_name"
                    label="Business name"
                    className="input-class"
                    size="small"
                    value={formValues.business_name}
                    inputType="text"
                    onChange={handleChange}
                    error={errors["business_name"] ? true : false}
                    helperText={errors["business_name"] ? errors.business_name[0] : null}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {1 == 1 && (
                    // <Inputs
                    //   name="inst_cat"
                    //   size="small"
                    //   inputType="autocomplete"
                    //   value={formValues.inst_cat}
                    //   defaultValue={instCategories.length > 0 ? instCategories[0]["value"] : 2}
                    //   label="Category"
                    //   listLabel="Category"
                    //   listValue="inst_cat"
                    //   list={instCategories}
                    //   onChange={handleChange}
                    //   error={errors["inst_cat"] ? true : false}
                    //   helperText={errors["inst_cat"] ? errors.inst_cat[0] : null}
                    // />

                    <AppAutoComplete
                            label="Search Category"
                            itemStateValue="inst_cat"
                            values={formValues}
                            setValues={setFormValues}
                            options={instCategories}
                            optionName={['label']}
                            optionId="value"
                            reload={loadCategories}
                            // defaultValue={instCategories.length > 0 ? instCategories[0]["value"] : 2}
                            handleChange={handleChange}
                            page={"register"}
                        />
                  )}
                </Grid>
                <br />
                <Grid item xs={12} md={12}>
                  <Inputs
                    name="email"
                    label="Email Address"
                    className="input-class"
                    size="small"
                    value={formValues.email}
                    inputType="text"
                    onChange={handleChange}
                    error={errors["email"] ? true : false}
                    helperText={errors["email"] ? errors.email[0] : null}
                  />
                </Grid>
                <br />
                <Grid item xs={12} md={12}>
                  <Inputs
                    name="mobile"
                    label="Mobile number"
                    className="input-class"
                    size="small"
                    value={formValues.mobile}
                    inputType="text"
                    onChange={handleChange}
                    error={errors["mobile"] ? true : false}
                    helperText={errors["mobile"] ? errors.mobile[0] : null}
                  />
                </Grid>
                <br />
                <Grid item xs={12} md={6}>
                  <Inputs
                    name="password"
                    label="Password"
                    className="input-class"
                    size="small"
                    value={formValues.password}
                    inputType="password"
                    onChange={handleChange}
                    error={errors["password"] ? true : false}
                    helperText={errors["password"] ? errors.password[0] : null}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Inputs
                    name="confirm_password"
                    label="Confirm Password"
                    className="input-class"
                    size="small"
                    value={formValues.confirm_password}
                    inputType="password"
                    onChange={handleChange}
                    error={errors["confirm_password"] ? true : false}
                    helperText={errors["confirm_password"] ? errors.confirm_password[0] : null}
                  />
                </Grid>
                <br />
                <Grid item xs={12} md={12}>
                  {showResetButton && 
                  <Button
                  id="btn-nav-resend"
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{backgroundColor:'#367588', width:'100%'}}
                    onClick={(e) => submit(e)}
                    propBackgroundColor={"#367588"}
                  >
                    Register
                    </Button>
                  }
                </Grid>
                <Grid item xs={12} md={12}>
                  {
                    (showResetButton === false) && (
                      <div style={{ textAlign: 'center' }}>
                        <ClipLoader
                          color={"#367588"}
                          loading={true}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                </Grid>
              </Grid>
            </div>

          </div>
        </div>
      </div>
      <div id="register" style={{ float: "center" }}>
        Already have an account? Click here to &nbsp;
        <b id="styleRegister" style={{ cursor: "pointer", color: "#367588" }} onClick={(e) => backToLogin(e)}>
          Login
        </b>
      </div>
      <div style={{ marginTop: "9vh" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Register;
