import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import './intro.css';



const Pricing = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);

  const toLogin = () => {
    navigate("/login");
  }

  const toHome = () => {
    navigate("/intro");
  }

  const toPolicy = () => {
    navigate("/policy");
  }

  const toPricing = () => {
    navigate("/pricing");
  }

  return (
    <Grid className="intro" container spacing={1} style={{ height: '100vh', padding: '3rem' }}>
      <Grid item xs={12} md={6}>
        <h2 onClick={(e) => {
          e.preventDefault();
          toHome()
        }} style={{ color: '#367588' }}>TaiPOS</h2>
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'row' }}>
        <Grid item xs={2} md={2}>

        </Grid>
        <Grid item xs={2} md={2}>
          <p className="top-menu" onClick={(e) => {
            e.preventDefault();
            toHome()
          }}>
            Home
          </p>
        </Grid>
        <Grid item xs={2} md={2}>
          <p className="top-menu" onClick={(e) => {
            e.preventDefault();
            toPricing()
          }}>
            Pricing
          </p>
        </Grid>
        <Grid item xs={2} md={2}>
          <p className="top-menu" onClick={(e) => {
            e.preventDefault();
            toPolicy()
          }}>
            Terms
          </p>

        </Grid>
        <Grid item xs={2} md={2}>

          <Button
            id="btn-nav-resend"
            size="small"
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#367588' }}
            onClick={(e) => {
              e.preventDefault();
              toLogin()
            }}
          >
            Login
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} style={{padding:'2rem', textAlign: 'center', color:'#367588', backgroundColor: '#fff', alignItems: 'center', borderRadius:'0.2rem' }}>
        <h4 style={{fontSize:'1.5rem'}}>ONE MONTH</h4>
        <p style={{marginTop:'5rem', fontSize:'2rem'}}>10,000 <br/> TSHs</p>
        <br/>
        <small>All services available</small>
      </Grid>
      <Grid item xs={12} md={4} style={{padding:'2rem', textAlign: 'center', color:'#fff', backgroundColor: '#367588', alignItems: 'center', borderRadius:'0.2rem' }}>
      <h4 style={{fontSize:'1.5rem'}}>SIX MONTHS</h4>
      <p style={{marginTop:'5rem', fontSize:'2rem'}}>55,000 <br/> TSHs</p>
      <br/>
      <small>All services available</small>
      </Grid>
      <Grid item xs={12} md={4} style={{padding:'2rem', textAlign: 'center', color:'#367588', backgroundColor: '#fff', alignItems: 'center', borderRadius:'0.2rem' }}>
      <h4 style={{fontSize:'1.5rem'}}>ANNUAL</h4>
      <p style={{marginTop:'5rem', fontSize:'2rem'}}>100,000 <br/> TSHs</p>
      <br/>
      <small>All services available</small>
      </Grid>

    </Grid>

  );
};

export default Pricing;
