import React, { useRef } from "react";
import "./profile.css";

const clickOutRef = (content_ref, toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle("active");
    } else {
      if (toggle_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove("active");
      }
    }
  });
};

const Profile = (props) => {
  const dropdown_content_el = useRef(null);
  const dropdown_toggle_el = useRef(null);

  clickOutRef(dropdown_content_el, dropdown_toggle_el);

  return (
    <div className="ez-dropdown">
      <button ref={dropdown_toggle_el} className="ez-dropdown-toggle">
        {props.icon ? <i className={props.icon}></i> : ""}

        {props.badge ? (
          <span className="ez-dropdown-toggle-badge">{props.badge}</span>
        ) : (
          ""
        )}
        {props.customToggle ? props.customToggle() : ""}
      </button>
      <div
        ref={dropdown_content_el}
        onClick={props.onClick}
        className="ez-dropdown-content"
      >
        {props.contentData && props.renderItems
          ? props.contentData.map((item, index) =>
              props.renderItems(item, index)
            )
          : ""}
        {props.content && props.renderItems
          ? props.content.map((item, index) => props.renderItems(item, index))
          : ""}
      </div>
    </div>
  );
};

export default Profile;
