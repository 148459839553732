import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import { useDispatch, useSelector } from "react-redux";
import { addSideEffects, getSideEffects, updateSideEffects } from "../../app/sideEffectsSlice";
import { addDispodedItems } from "../../app/disposedItemsSlice";
import { customerSchema, disposeSchema, hasAccess, orderSchema } from "../../common/validators";
import validate from "validate.js";
import { addCustomer, getCustomers, updateCustomer } from "../../app/customersSlice";
import { getItems } from "../../app/itemsSlice";
import { addOrder, getOrderItems, getOrders, updateOrder } from "../../app/ordersSlice";
import moment from "moment";
import { PDFDownloadLink, PDFViewer,  } from "@react-pdf/renderer";
import { CircularProgress } from "@material-ui/core";
import InvoiceDoc from "../../components/table/invoice";


// PDFView


const itemsTableHead = [
  { label: "Invoice date", name: "order_date" },
  { label: "Customer Name", name: "customer_name" },
  { label: "Mobile", name: "customer_mobile" },
  { label: "Email", name: "customer_email" },
  { label: "Status", name: "status" },
  { label: "Invoice number", name: "order_number" },
  { label: "Unpaid amount", name: "amount" },
  { label: "Paid amount", name: "paid_amount" },
  "action",
];



const isViewAccess = () => {
  return hasAccess('orders','view');
}

const isDetailsAccess = () => {
  return hasAccess('orders','details');
}

const isEditAccess = () => {
  return hasAccess('orders','edit');
}

const isAddAccess = () => {
  return hasAccess('orders','add');
}


const Invoices = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openCancelForm, setOpenCancelForm] = useState(false);
  let [openStockForm, setOpenStockForm] = useState(false);
  let [openDisposeForm, setOpenDisposeForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  let [showSideEffect, setShowSideEffect] = useState(false);
  let [toEditSideEffect, setToEditSideEffect] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formValues, setFormValues] = useState({"order_date": new Date(),"deliver_date": new Date(),"counts": 1});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [itemDataList, setItemDataList] = useState([]);
  const [measuresDataList, setMeasuresDataList] = useState([]);
  const [invoiceDataList, setInvoiceDataList] = useState([]);
  
  const [activeTab, setActiveTab] = useState(0);


  const dispatch = useDispatch();

  const { error, status, list } = useSelector(
    ({ orders }) => orders.get
  );

  const { error: postError, status: postStatus } = useSelector(
    ({ orders }) => orders.post
  );

  const { error: putError, status: putStatus } = useSelector(
    ({ orders }) => orders.put
  );

  const { error: errorItems, status: statusItems, list: listItems } = useSelector(
    ({ orders }) => orders.items
  );

  const { error: postOrderError, status: postOrderStatus } = useSelector(
    ({ orders }) => orders.post
  );
 
 


  const handleRequestClose = () => {
    setErrors([])
    setFormValues({});
    setOpenForm(false);
    setOpenCancelForm(false);
    setOpenDetail(false);
    setOpenStockForm(false);
    setOpenDisposeForm(false);
    setShowSideEffect(false);
    setEdit(false);
    setToEditSideEffect(false);
    setActiveTab(0);
    setSelectedOrders([]);
    setTotalPrice(0);
    setFormValues({})
  };

  const viewItem = (item) => {
    if (item) {
      dispatch(getOrderItems({orderNumber:item?.order_number}))
      setViewDetailsSelected(true)

      setEdit(false);
      setOpenDisposeForm(false);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const deliverOrder = (item,status = 'delivered') => {
    if (item) {
      dispatch(getOrderItems({orderNumber:item?.order_number}))
      setEdit(true);
      //setOpenDisposeForm(false);
      item.newStatus = status;
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };

  const cancelOrder = (item) => {
    if (item) {
      dispatch(getOrderItems({orderNumber:item?.order_number}))
      setEdit(true);
      //setOpenDisposeForm(false);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenCancelForm(true);
      }, 300);
    }
  };

 
  const handleDownloadInvoice = (fileName = 'Invoice') => {
    return (
      <PDFDownloadLink
      // style={styles.downloadLink}
      document={dialogOpen}
      fileName={`${fileName}.pdf`}
    >
      {({ blob, url, loading, error }) => (
        <>
          {!loading && <div>Download Invoice</div>}
          {loading && (
            <div
              style={{ width: "100px" }}
              className="flex-row justify-content-center align-items-center"
            >
              <CircularProgress size={11} color="primary" />
            </div>
          )}
        </>
      )}
    </PDFDownloadLink>
    )

  }


  const cols = [
    { label: "Item Code", name: "item_code" },
    { label: "Description", name: "item_description" },
    { label: "Counts", name: "counts" },
    { label: "Rate", name: "rate" },
    { label: "Amount", name: "amount" },
  ]

 
  const dialogOpen = () => {
    return (
      <>
        <div className="row">
        <div className="col-sm-12 col-md-12 col-12"><h4>PRO FORMA INVOICE</h4></div>
        <br /> <br />
        <div className="col-sm-12 col-md-6 col-6">Bill To</div>
        <div className="col-sm-12 col-md-6 col-6" style={{textAlign:'right'}}>
          {
            invoiceDataList.length > 0 && (
              <InvoiceDoc data={invoiceDataList}
                      headerData={selectedItem}
                      columns={cols}
                      fileName={"Invoice"}
                      toDate={new Date()}
                      fromDate={new Date()}/>
            )
          }
        </div>
        <br /> <br />

          <div className="col-sm-12 col-md-6 col-6">
            <span>Name</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_name}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6" style={{textAlign:'right'}}>
            <span>Invoice No</span>&nbsp;&nbsp;
            <span>{selectedItem?.order_number}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Mobile</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_mobile}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6" style={{textAlign:'right'}}>
            <span>Date</span>&nbsp;&nbsp;
            <span>{moment(selectedItem?.order_date).format("YYYY-MM-DD")}</span>
          </div>          
          <div className="col-sm-12 col-md-6 col-6">
            <span>Email</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_email}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6" style={{textAlign:'right'}}>
            <span>Total Amount</span>&nbsp;&nbsp;
            <span>{selectedItem?.amount}</span>
          </div>
          
          <br /> <br />
          <div className="col-sm-12 col-md-12 col-12">
            <table>
              <thead>
                <tr>
                  <th>Item Code</th>
                  <th>Description</th>
                  <th style={{textAlign:'right'}}>Qty</th>
                  <th style={{textAlign:'right'}}>Rate</th>
                  <th style={{textAlign:'right'}}>Price</th>
                </tr>
              </thead>
              <tbody>
                {listItems.map((item) => (
                  <tr key={item}>
                    <td>{item.item_code}</td>
                    <td>{item.item_description}</td>
                    <td style={{textAlign:'right'}}>{Number(item.counts).toLocaleString()}</td>
                    <td style={{textAlign:'right'}}>{item.amount > 0 ? Number(item.amount/item.counts).toLocaleString() : 0}</td>
                    <td style={{textAlign:'right'}}>{Number(item.amount).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
            <div className="col-sm-12 col-md-12 col-12" style={{textAlign:'right'}}>
              TOTAL <b>{selectedItem?.amount}</b>
            </div>
          <br /> <br />


        </div>
      </>
    );
  };


  const handleChange = (event) => {
   
    //alert(JSON.stringify(event.target.value))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });

    
    if(event.target.name === "unit_of_measure"){
       setFormValues({
        ...formValues,
        "rate": formValues.sale_category === 'wholesale' ? event.target.value["w_selling_price"] : event.target.value["selling_price"],
        "item_code": event.target.value["item_code"],
        "item_id": event.target.value["id"],
        "item_description": event.target.value["item_description"],
        "counts":1
      });

      
    }
  };

  const addToOrderList = () => {

        //alert(JSON.stringify(formValues))

        let _selectedOrders = selectedOrders;      
        let selectedOrder = {"customer_id":selectedItem["id"],"order_date":formValues["order_date"],"deliver_date":formValues["deliver_date"],"item_id":null,"item_code":null,"item_description":null,"counts":0,"amount":0}
    
        selectedOrder.amount = formValues.counts * formValues.rate;
        selectedOrder.counts = formValues.counts;
        selectedOrder.item_code = formValues["item_code"];
        selectedOrder.item_id = formValues["item_id"];
        selectedOrder.item_description = formValues["item_description"];

        const itemPresent = _selectedOrders.filter((item) => item.item_code !== selectedOrder.item_code);
        // const itemPresent = _selectedOrders.find((item) => item.item_code === selectedOrder.item_code);
        _selectedOrders = itemPresent;
        if (itemPresent) {         
          _selectedOrders.push(selectedOrder);
          setSelectedOrders(_selectedOrders);

          if(formValues.counts > 0 && !_selectedOrders.includes(selectedOrder)){
            _selectedOrders.push(selectedOrder);
            setSelectedOrders(_selectedOrders);
          }
        }

        calculateTotalPrice(itemPresent);
  }

  const calculateTotalPrice = (itemPresent) => {
    let totalAmount = 0;
    for (let index = 0; index < itemPresent.length; index++) {
      totalAmount = totalAmount + itemPresent[index]["amount"];      
    }

    setTotalPrice(totalAmount);
  }

  const handleCancelOrder = (e) => {
    e.preventDefault();
    dispatch(updateOrder({id: selectedItem.id, order_number:selectedItem.order_number, status:'cancelled'}))
  }

  const handleDeliverOrder = (e) => {
    e.preventDefault();
    dispatch(updateOrder({id: selectedItem.id, order_number:selectedItem.order_number, status:selectedItem.newStatus ? selectedItem.newStatus : 'delivered'}))
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    if (edit && !openStockForm) {
      let validationErrors = validate(
        formValues,
        customerSchema
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{
      let newItem = {
        "id": formValues?.id,
        "customer_name": formValues?.customer_name,
        "customer_mobile": formValues?.customer_mobile,
        "customer_email": formValues?.customer_email
      }
      dispatch(updateCustomer(newItem));
    }
    } else if (openStockForm) {
      let validationErrors = validate(
        formValues,
        orderSchema
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{
        //toast.success(JSON.stringify(formValues));
        for (let index = 0; index < selectedOrders.length; index++) {
          delete selectedOrders[index]['item_code'];
          delete selectedOrders[index]['item_description'];        
        }      
        dispatch(addOrder({toSend:JSON.stringify(selectedOrders)}))
      }
    } else if (openDisposeForm) {
      let validationErrors = validate(
        formValues,
        disposeSchema
      );
      if(validationErrors){
        //console.log('validationErrors',validationErrors)
        setErrors(validationErrors);      
      }else{
      setFormValues({
        ...formValues,
        item_id: selectedItem?.id
      })
      //alert(JSON.stringify(formValues))
      dispatch(addDispodedItems(formValues))
    }
    }else if(showSideEffect && !toEditSideEffect){
      let newEffect = {
        item_code:formValues.item_code,
        item_id:formValues.id,
        remarks:formValues.side_effect,
        status:'A'
      }
      // alert(JSON.stringify(newEffect))
      dispatch(addSideEffects(newEffect))
    } else if(showSideEffect && toEditSideEffect){
      let newEffect = {
        id:formValues.id,
        item_code:formValues.item_code,
        item_id:formValues.item_id,
        remarks:formValues.side_effect,
        status:String(formValues.side_effect).length < 5 ? 'X' : 'A'
      }
      // alert(JSON.stringify(newEffect))
      dispatch(updateSideEffects(newEffect))
    }
     else {
      let validationErrors = validate(
        formValues,
        customerSchema
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{
      let newItem = {
        "customer_name": formValues?.customer_name,
        "customer_mobile": formValues?.customer_mobile,
        "customer_email": formValues?.customer_email
      }
      dispatch(addCustomer(newItem));
    }
    }

  };


  const newStockClicked = (item = null) => {
    // e.preventDefault();
    //toast.success("New Stock");
    dispatch(getItems())
    //setFormValues({ item_code: item?.item_code, item_description: item?.item_description, buy_date: new Date() })
    setSelectedItem(item);
    setOpenStockForm(true);
    setOpenDisposeForm(false);

  };

  const disposeClicked = (item = null) => {
    //setFormValues({ item_code: item?.item_code, item_description: item?.item_description, destroy_date: new Date() })
    setSelectedItem(item);
    setOpenDisposeForm(true);
    setEdit(false);
    setOpenDetail(false);

  };

  const handleChangeTabs = (index) => {
    setActiveTab(index);
  };


  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  useEffect(() => {
    setToEditSideEffect(false);
    if (activeTab === 0) {
      setShowSideEffect(false);
      if (viewDetailsSelected === true && selectedItem) {
        //dispatch(getStockHistory({ item_code: selectedItem?.item_code }))
      }
      // dispatch(getExpenses())
    } else {
      //setShowStock(false);
      setShowSideEffect(false);
    }

  }, [activeTab]);

  useEffect(() => {
    if (viewDetailsSelected === true && selectedItem) {
     // dispatch(getStockHistory({ item_code: selectedItem?.item_code }))
    }
  }, [viewDetailsSelected, selectedItem]);



  useEffect(() => {
    if (postStatus === "loading" && !edit) {
      setHideSaveButton(true);
    } else if (postStatus === "idle") {
      dispatch(getCustomers());
      setHideSaveButton(false);
    }
  }, [postStatus]);

 


  useEffect(() => {
    if (putStatus === "loading" && edit) {
      setHideSaveButton(true);
    } else if (putStatus === "idle") {
      dispatch(getOrders());
      setHideSaveButton(false);
      setOpenForm(false);
      setOpenCancelForm(false);
    }

  }, [putStatus]);

  useEffect(() => {
    if (postOrderStatus === "loading") {
      setHideSaveButton(true);
    } else if (postOrderStatus === "idle") {
      dispatch(getCustomers());
      setHideSaveButton(false);
      setTotalPrice(0);
      setSelectedOrders([]);
      setFormValues({})
    }
  }, [postOrderStatus]);

  useEffect(() => {
    if (status === "loading") {

    } else if (status === "idle" ) {
      let newList = [];
      //newList = list;
      for (let index = 0; index < list.length; index++) {
        let indObject = list[index];
        indObject = {
          ...indObject,
          'order_date':moment(list[index]['order_date']).format("YYYY-MM-DD"),
          'deliver_date':moment(list[index]['deliver_date']).format("YYYY-MM-DD"),
          'amount':Number(list[index]['amount']).toLocaleString()
        }
        newList.push(indObject)     
      }
      setItemDataList(newList);
    }

  }, [status, list]);


  useEffect(() => {
    if (postOrderError) {
      setOpenStockForm(true);
    } else if (postOrderStatus === "idle") {
      setOpenStockForm(false)
      setTotalPrice(0);
      setSelectedOrders([]);
      setFormValues({})
    }


  }, [postOrderStatus, postOrderError]);


  useEffect(() => {
    if (statusItems === "loading") {

    } else if (statusItems === "idle") {
      let measureArray = [];
      let dataInvoiceArray = [];
      for (let index = 0; index < listItems.length; index++) {
        measureArray.push({ value: listItems[index], label: listItems[index]?.item_description })
        
        if(selectedItem && openDetail === true){
          let newInvoice = {
            ...listItems[index],
            amount:Number(listItems[index]['amount']).toLocaleString(),
            counts:Number(listItems[index]['counts']).toLocaleString(),
            rate: listItems[index]['counts'] > 0 ? Number(listItems[index]['amount'] / listItems[index]['counts']).toLocaleString() : 0
          }
          dataInvoiceArray.push(newInvoice)
        }
      }
      setMeasuresDataList(measureArray);
      setInvoiceDataList(dataInvoiceArray);
    }
  }, [statusItems, listItems]);


  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Customers Invoices</h2>
        
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card" style={{ backgroundColor: "#e0e0e0" }}>
            <div className="card-body">
              {itemDataList.length > 0 ? (
                <Table
                  columns={itemsTableHead}
                  data={itemDataList}
                  actions={[
                    {
                      action: "Details",
                      renderCheck: isViewAccess,
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Paid and Delivered",
                      renderCheck: (item) => {
                        if(isEditAccess() && (item.status === 'active' || item.status === 'paid' || item.status === 'oncredit')){
                          return true
                        }else{
                          return false
                        }                        
                      },
                      onClick: (item) => {
                        //editItem(item);
                        deliverOrder(item,'delivered')
                        
                      },
                    },
                    {
                      action: "Paid Only",
                      renderCheck: (item) => {
                        if(isEditAccess() && (item.status === 'active')){
                          return true
                        }else{
                          return false
                        }                        
                      },
                      onClick: (item) => {
                        //editItem(item);
                        if (item.status === 'active') {
                          //handleDeliverOrder(item)
                          deliverOrder(item,'paid')
                        }else{

                        }
                        
                      },
                    },
                    {
                      action: "Delivered on Credit",
                      renderCheck: (item) => {
                        if(isEditAccess() && (item.status === 'active')){
                          return true
                        }else{
                          return false
                        }                        
                      },
                      onClick: (item) => {
                        //editItem(item);
                        if (item.status === 'active') {
                          //handleDeliverOrder(item)
                          deliverOrder(item,'oncredit')
                        }else{

                        }
                        
                      },
                    },
                    {
                      action: "Cancel",
                      renderCheck: (item) => {
                        if(isEditAccess() && (item.status === 'active')){
                          return true
                        }else{
                          return false
                        }                        
                      },
                      onClick: (item) => {
                        //editItem(item);
                        if (item.status === 'active') {
                          //handleCancelOrder(item)
                          cancelOrder(item)
                        }else{
                          
                        }
                        
                      },
                    },
                  ]}
                  downloadFileName={"Customer Orders"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={""}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={showSideEffect ? "Save Side Effect" : "Update"}
        content={() => dialogOpen()}
        // submit={(e) => {handleDownloadInvoice()}}
        submit={(e) => handleSubmit(e)}
        
        // hideSaveButton={false}
        hideSaveButton={showSideEffect ? false: true}
        propBackgroundColor="#367588"
      />

      <MyDialog
        // title={String(selectedItem?.newStatus).toUpperCase()+" ORDER"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={listItems && listItems.length > 0 ? "Save "+String(selectedItem?.newStatus).toUpperCase() : ""}
        content={() => dialogOpen()}
        // content={() => formOpen()}
        submit={(e) => handleDeliverOrder(e)}
        // submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor="#367588"
      />

      <MyDialog
        // title={"Cancel Order"}
        width="md"
        onClose={handleRequestClose}
        open={openCancelForm}
        text={listItems && listItems.length > 0 ? "Save Cancellation" : ""}
        content={() => dialogOpen()}
        // content={() => formOpen()}
        submit={(e) => handleCancelOrder(e)}
        // submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor="red"
      />
    </div>
  );
};

export default Invoices;
