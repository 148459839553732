import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getBusinessList, putBusiness, registerBusiness } from "../api/businessesAPI";

export const getBusiness = createAsyncThunk("business/getBusiness", async (payload, { rejectWithValue }) => {
  try {
    const response = await getBusinessList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Errorrrrrrrrr ", error);
    return rejectWithValue(error.message);
  }
});

export const addBusiness = createAsyncThunk("business/addBusiness", async (payload, { rejectWithValue }) => {
  try {
    const response = await registerBusiness(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const updateBusiness = createAsyncThunk("business/updateBusiness", async (payload, { rejectWithValue }) => {
  try {
    const response = await putBusiness(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const businessSlice = createSlice({
  name: "resources",
  initialState: {
    payload: {
      status: null,
      error: null,
      data: null,
    },
    post: {
      status: null,
      error: null,
      data: null,
    },
    put: {
      status: null,
      error: null,
      data: null,
    },
    get: {
      status: null,
      error: null,
      list: [],
    },
  },

  extraReducers: {
    [getBusiness.pending]: (state) => {
      state.get.status = "loading";
    },
    [getBusiness.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get resources!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.get.status = 'idle';
    },
    [getBusiness.rejected]: (state, action) => {
      state.get.status = "idle";
      state.get.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get resource!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [addBusiness.pending]: (state) => {
      state.post.status = "loading";
    },
    [addBusiness.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Resource saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.post.status = "idle";
        state.post.error = null;
      }else{
        toast.warn(data.message? data.message : "Couldn't add Business!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.post.error = "Failed";
      }

      state.post.data = data;
    },
    [addBusiness.rejected]: (state, action) => {
      //state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add Business!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateBusiness.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateBusiness.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Resource saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.put.status = "idle";
      }else{
        state.put.error = "Failed";
        toast.warn("Couldn't update resource!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.data = data;
    },
    [updateBusiness.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add resource!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

export default businessSlice.reducer;
