import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "./dialog.css";

import React from "react";
import Inputs from "../inputs/Inputs";
import MySpinner from "../Spinner/spinner";

const MyDialog = ({
  open,
  onClose,
  width,
  text,
  title,
  submit,
  content,
  height,
  hideSaveButton,
  propBackgroundColor,
  reject,
  rejectText,
  drop,
  dropText,
  approve,
  approveText,
  disbursed,
  disbursedText,
  showLoading
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={width}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent style={{ height: height ?? "" }}>
        <DialogContentText>{content && content()}</DialogContentText>
      </DialogContent>
      <DialogActions>
      {hideSaveButton === false && disbursedText && 
        <Inputs
          color="info"
          size="small"
          inputType="button"
          type="submit"
          text={disbursedText ?? "Mark Disbursed"}
          onClick={disbursed}
          propBackgroundColor={propBackgroundColor}
        />
        }
      {hideSaveButton === false && dropText && 
        <Inputs
          color="info"
          size="small"
          inputType="button"
          type="submit"
          text={dropText ?? "Drop Request"}
          onClick={drop}
          propBackgroundColor={"#800000ff"}
        />
        }
        {hideSaveButton === false && text && 
        <Inputs
          color="success"
          size="small"
          inputType="button"
          type="submit"
          text={text ?? "Submit"}
          onClick={submit}
          propBackgroundColor={propBackgroundColor}
        />
        }
        {hideSaveButton === false && approveText && 
        <Inputs
          color="success"
          size="small"
          inputType="button"
          type="submit"
          text={approveText ?? "Approve"}
          onClick={approve}
          propBackgroundColor={propBackgroundColor}
        />
        }
        {hideSaveButton === false && rejectText && 
        <Inputs
          color="info"
          size="small"
          inputType="button"
          type="submit"
          text={rejectText ?? "Send Back"}
          onClick={reject}
          propBackgroundColor={"#800000ff"}
        />
        }
        <Inputs
          color="primary"
          size="small"
          inputType="button"
          text="Close"
          type="submit"
          onClick={onClose}
        />
        {
          showLoading && showLoading === true && <p><MySpinner size={35} color="#367588" /></p>
        }
        
      </DialogActions>
    </Dialog>
  );
};

export default MyDialog;
