import { instCat } from "../common/validators";
import { adminServiceApi } from "../services";

export const getStockList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    // query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("stock", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const postStock = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post(instCat() === 4 ? "stock/agent/add" : "stock/add",query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putStock = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("stock/"+query?.id,query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};