import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getStockList, postStock, putStock } from "../api/stockAPI";
import stockHistory from "../assets/JsonData/stock-history.json";

const initialState = {
  payload: {
    status: null,
    error: null,
    data: null,
  },
  post: {
    status: null,
    error: null,
    data: null,
  },
  put: {
    status: null,
    error: null,
    data: null,
  },
  get: {
    status: null,
    error: null,
    list: [],
  },
};

export const getStockHistory = createAsyncThunk("stock/getStock", async (payload, { rejectWithValue }) => {
  try {
    const response = await getStockList(payload);
    //console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addStock = createAsyncThunk(
  "stock/addStock",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postStock(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateStock = createAsyncThunk(
  "stock/updateStock",
  async (payload, { rejectWithValue }) => {
    try {      
      const response = await putStock(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const stockSlice = createSlice({
  name: "stock",
  initialState: initialState,
  reducers: {
    resetStockState: (state) => {
      state.post.status = null;
      state.post.data = null;
      state.post.error = null;

      state.payload.data = null;
      state.payload.error = null;
      state.payload.status = null;

      state.put.error = null;
      state.put.data = null;

      state.get.status = null;
      state.get.error = null;
      state.get.list = [];
    },
  },
  extraReducers: {
    [getStockHistory.pending]: (state) => {
      state.get.status = "loading";
    },
    [getStockHistory.fulfilled]: (state, {payload}) => {
      //console.log('payloadpayload',payload.data)
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
      }
      state.get.status = "idle";
    },
    [getStockHistory.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
    },

    [addStock.pending]: (state) => {
      state.post.status = "loading";
    },
    [addStock.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
      // if (status === 200 || status === 201) {
      //   state.post.data = data;
      //   state.post.error = null;
      // } else {
      //   state.post.error = { message: `${status} - An error occurred` };
      // }
      if (status === 201) {
        toast.success("Stock saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't add stock!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.post.status = "idle";
      state.post.data = data;
    },
    [addStock.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
    },
    [updateStock.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateStock.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Stock updated.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn(data.message, {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.status = "idle";
      // const index = state.findIndex((user) => user.id === action.payload.id);
      // state[index].updated = action.payload.updated;
    },
    [updateStock.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.warn(action.error.message, {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

// export const { resetStockState } = resetStockState.actions;
export default stockSlice.reducer;
