import React from "react";
import "./cards.css";
// import Inputs from "../inputs/Inputs";
import { BorderColor } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
// import { PropagateLoader } from "react-spinners";

const Cards = (props) => {
  const navigate = useNavigate();

 
    return (
      <div className="ez-card" id="vertical" style={{borderColor : (props.borderColor ? props.borderColor : "#dddddda1")}} >
        {props.icon && props.title ? (
          <>
            <div className="ez-card-icon-vertical">
              <i className={props.icon}></i>
            </div>
            <div className="ez-card-info">
              {/* <h4>{props.title}</h4> */}
              <span>{props.title}</span>
            </div>
          </>
        ) : (
          <>
            <div className="ez-card-icon-vertical">
              <h6>{props.number}</h6>
            </div>
             <div className="ez-card-info">
              <span>{props.title}</span>
            </div>
          </>
        )}
      </div>
    );
};

export default Cards;
