import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast, Zoom } from "react-toastify";
import { userAuth,resetPassword, switchBusiness } from "../api/userAPI";
import usersList from "../assets/JsonData/users-list.json";


export const userLogin = createAsyncThunk("user/userLogin", async (payload, { rejectWithValue }) => {
  try {
    const response = await userAuth(payload);
    //console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const switchUserBusiness = createAsyncThunk("user/switchUserBusiness", async (payload, { rejectWithValue }) => {
  try {
    const response = await switchBusiness(payload);
    //console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const resetMyPassword = createAsyncThunk("user/resetMyPassword", async (payload, { rejectWithValue }) => {
  try {
    const response = await resetPassword(payload);
    //console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const userLogins = createAsyncThunk("users/auth", async (payload) => {
  //alert(JSON.stringify(payload))
  let responsed = {status:404,data:{token:null,message:"Wrong credentials"}};
  for (let index = 0; index < usersList.length; index++) {
    if(usersList[index].USERNAME === payload.username){
      responsed.status = 200;
      //responsed.data.token = "$2a$10$OmQ5Bl7WdtDsdkZxuRGc0OpFBNbpH5LMLIofm9K2rkONcXxvUrX/a";
      responsed.data.message = "success";
      responsed.data.fullname = usersList[index].F_NAME+" "+usersList[index].L_NAME;
      responsed.data.userId = usersList[index].USERID;
    }    
  }

  return responsed;

  // const response = await toast.promise(
  //   fetch("http://localhost:3600/v1/users/auth", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify([
  //       { username: payload.username, password: payload.password },
  //     ]),
  //   }),
  //   {
  //     pending: "Loggin in.",
  //     success: "Welcome.",
  //     error: "Failed to login.",
  //   },
  //   //* options for toast
  //   {
  //     transition: Zoom,
  //     autoClose: 1400,
  //     hideProgressBar: true,
  //   }
  // );
  // if (response.ok) {
  //   const auth = await response.json();
  //   return auth;
  // }
});

export const signOut = () => { };

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: {},
    post:{
      status:null,
      error:null
    }
  },

  extraReducers: {
    [userLogin.pending]: (state) => {
      state.post.status = "loading";
    },
    [userLogin.fulfilled]: (state, {payload}) => {
      //alert(JSON.stringify(payload))
      if (payload.status === 200) {
        //alert(payload.data?.data.f_name)
        payload.data.fullname = payload.data.data.f_name+" "+payload.data.data.l_name;
        localStorage.setItem('token',payload.data?.data.token)
        localStorage.setItem('userData',JSON.stringify(payload.data))
        state.auth = payload;
        // window.location.replace("/pos");
        window.location.replace("/");
      }else{
        toast.error("Wrong credentials", {
          autoClose: 2600,
          hideProgressBar: true,
        });
      }
    },
    [userLogin.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't login user!", {
        autoClose: 2600,
        hideProgressBar: true,
      });
    },   
    [switchUserBusiness.pending]: (state) => {
      state.post.status = "loading";
    }, 
    [switchUserBusiness.fulfilled]: (state, {payload}) => {
      //alert(JSON.stringify(payload))
      if (payload.status === 200 || payload.status === 201) {
        //alert(payload.data?.data.f_name)
        payload.data.fullname = payload.data.data.f_name+" "+payload.data.data.l_name;
        localStorage.setItem('token',payload.data?.data.token)
        localStorage.setItem('userData',JSON.stringify(payload.data))
        state.auth = payload;
        window.location.replace("/");
      }else{
        toast.error("Wrong credentials", {
          autoClose: 2600,
          hideProgressBar: true,
        });
      }
    },
    [switchUserBusiness.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't login user!", {
        autoClose: 2600,
        hideProgressBar: true,
      });
    }, 
  },
});

export default authSlice.reducer;
