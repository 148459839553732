import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
// import userList from "../../assets/JsonData/users-list.json";

import { toast } from "react-toastify";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import { hasAccess, taxSchema, usersSchema } from "../../common/validators";
import validate from "validate.js";
import { addTax, getTaxes, updateTax } from "../../app/taxSlice";

const tableHead = [
  { label: "Name", name: "tax_name" },
  { label: "Rate", name: "rate" },
  { label: "Status", name: "status" },
];

const statusLov = [
  {label:"Active", value:"active"},
  {label:"Deleted", value:"deleted"},
  {label:"Inactive", value:"inactive"}
  
]

const userData = localStorage.getItem("userData")
? JSON.parse(localStorage.getItem("userData"))
: null;

const Tax = () => {
  const dispatch = useDispatch();
  let [openForm, setOpenForm] = useState(false);
  let [edit, setEdit] = useState(false);
  let [data, setData] = useState([]);
  let [rolesData, setRolesData] = useState([]);  
  const [formValues, setFormValues] = useState({});
  const {status,error,list} = useSelector(({tax}) => tax.get);
  const {status:postStatus,error:postError} = useSelector(({tax}) => tax.post);
  const {status:putStatus,error:putError} = useSelector(({tax}) => tax.put);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    dispatch(getTaxes());
    
  }, [dispatch]);

  useEffect(() => {
    if(postStatus == "idle" && !postError){
      dispatch(getTaxes());
      setFormValues({});
      setOpenForm(false);
      setEdit(false);
    }    
  }, [postStatus,postError]);

  useEffect(() => {
    if(putStatus == "idle" && !putError){
      dispatch(getTaxes())
      setFormValues({});
      setOpenForm(false);
      setEdit(false);

    }    
  }, [putStatus,putError]);

  useEffect(() => {
    if (list && status === "idle") {
      let _data = [];
      list.forEach((item, index) => {
        _data.push({
          id: item.id,
          tax_name: item.tax_name,
          rate: item.rate,
          status: item.status
        });
      });
      setData(_data);
    }
  }, [list]);


  const handleRequestClose = () => {
    setErrors([])
    setFormValues({});
    setOpenForm(false);
    setEdit(false);
  };

  const isAccess = (component,action) => {
    return hasAccess(component,action);
  }

  const isEditAccess = () => {
    return hasAccess('staff','edit');
  }


  const editItem = (item) => {
    //alert(JSON.stringify(userItem))
    if (item) {
      setEdit(true);
      setOpenForm(true);
      setTimeout(() => {
        setFormValues(item);
      }, 300);
    }
  };

  const registerUser = (e) => {
    e.preventDefault();
    setOpenForm(true);
    setFormValues({tax_name:"Value Added Tax(VAT)", rate:18});
  };

  const formOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="tax_name"
              size="small"
              label="Tax Name"
              value={formValues.tax_name}
              inputType="text"
              onChange={handleChange}
              disabled={edit ? true : false}
              error={errors["tax_name"] ? true : false}
              helperText={errors["tax_name"] ? errors.tax_name[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="rate"
              size="small"
              label="Rate in %"
              value={formValues.rate}
              inputType="text"
              onChange={handleChange}
              error={errors["rate"] ? true : false}
              helperText={errors["rate"] ? errors.rate[0] : null}
            />
          </div>
          {statusLov.length ? (
        <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="status"
            size="small"
            inputType="select"
            value={formValues.status}
            defaultValue={statusLov[0]["value"]}
            label="Status"
            listLabel="Status"
            listValue="status"
            list={statusLov}
            onChange={handleChange}
            
          />
        </div>
      ) : (
        ""
      )}
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    //event.preventDefault();
    //alert(event)
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmit = async () => {
    if(isAccess('staff','add')){
      if(edit===true){
        let validationErrors = validate(
          formValues,
          taxSchema
        );
        if(validationErrors){
          setErrors(validationErrors);      
        }else{
        //formValues.id = selectedUser?.id;
        dispatch(updateTax(formValues))
        }
      }else{
        let validationErrors = validate(
          formValues,
          taxSchema
        );
        if(validationErrors){
          setErrors(validationErrors);      
        }else{
        dispatch(addTax(formValues))
        }
      }
    }else{
      toast.error("You are not allowed to access this facility");
    }
    
    //alert(JSON.stringify(formValues))
    //toast.success(JSON.stringify(formValues));
  };

  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Tax Deductions Available</h2>
        &nbsp;
         <div style={{alignContent:"right"}}>
           {isAccess('staff','add') === true &&
        <Inputs
          color="success"
          className={`bx-burst-hover`}
          size="small"
          inputType="button"
          type="submit"
          text={"New Tax"}
          onClick={(e) => registerUser(e)}
          propBackgroundColor={"#367588"}
        />
        }
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {list.length ? (
                <Table
                  columns={tableHead}
                  data={data}
                  actions={[
                    {
                      action: "Edit",
                      renderCheck: isEditAccess,
                      onClick: (item) => {
                        editItem(item);
                      },
                    } 
                  ]}
                  downloadFileName={"Tax List"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={edit ? "Tax Update" : "Tax Registration"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        submit={handleSubmit}
        text={edit ? "Update" : "Register"}
        content={() => formOpen()}
        hideSaveButton={!openForm}
        propBackgroundColor={"#367588"}
      />
    </div>
  );
};

export default Tax;
