import React from "react";
import Grid from "@material-ui/core/Grid";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import { useLocation,useNavigate } from "react-router-dom";

import "./breadcrumbs.css";

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      color: theme.palette.primary.main,
    },
  },
}))(Chip);

const BreadCrumbs = () => {
  // const today = new Date();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClickCrumbs = (url) => {
    navigate(url);
  }

  const breadcrumbsMapping = {
    "/": "Home",
    "/home": "",
    "/items": "Items",
    "/pos": "Pos",
    "/sales": "Sales",
    "/accounts": "Accounts",
    "/staff": "Staff",
    "/reports": "Reports",
    "/businesses": "Businesses",
    "/tax": "Tax",
    "/invoices": "Invoices",
    "/orders": "Orders",
    "/customers": "Customers",
    "/policy":"Policy",
    "/404": "Page Not found"
  };

  const pathnames = pathname.split("/").filter((x) => x);

  return (
    <>
      <div className="breadcrumbs">
        <Grid container justifyContent="space-between" spacing={0}>
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
              key={`breadCrumb-home`}
              component="a"
              href="/"
              label="Home"
              icon={() => {
                return <i class="bx bx-home-alt"></i>;
              }}
              onClick={(e) => {
                e.preventDefault();
                handleClickCrumbs("/");
              }}
            />
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;
              return last ? (
                <StyledBreadcrumb
                  key={`breadCrumb-${index}`}
                  className="currentPage"
                  label={breadcrumbsMapping[`${to}`]}
                  onClick={(e) => {
                   
                    e.preventDefault();
                   
                  }}
                />
              ) : (
                <StyledBreadcrumb
                  key={`breadCrumb-${index}`}
                  component="a"
                  href={to}
                  label={breadcrumbsMapping[`${to}`]}
                  onClick={(e) => {
                    //alert(last)
                    e.preventDefault();
                    handleClickCrumbs(to);
                  }}
                />
              );
            })}
          </Breadcrumbs>
        </Grid>
      </div>
    </>
  );
};

export default BreadCrumbs;
