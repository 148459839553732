import { instCat } from "../common/validators";
import { adminServiceApi } from "../services";

export const getSalesReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/sales", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getExpensesReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/expenses", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getStockingReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/stocking", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDisposingReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/disposing", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getExpiringReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/expiring", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getStockValueReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get(instCat() === 4 ? "stock/agent/valueTotal" : "stock/valueTotal", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getProfitSummary = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/profitSummary", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSubscriptionBills = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("invoices/pending", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getFloatCashBalance = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/cashFloatBalances", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getMostPurchasesReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/mostPurchased", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getNearToFInishReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("stock/valueSummary", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getActiveCreditsReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/activeCredits", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getActiveOrdersReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/activeOrders", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSalesTodayTotalReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/salesTodayTotal", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSalesTodayTotalReportWithdraw = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/salesTodayTotalWithdraw", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getProfitTodayTotalReport = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("reports/profitTodayTotalReport", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};