import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFViewer,
  
} from "@react-pdf/renderer";

import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../../assets/images/logo.png";
import moment from "moment";
// import { useSelector } from "react-redux";

const InvoiceDoc = ({
  fileName = "",
  headerData = {},
  data = [],
  columns = [],
  toDate = null,
  fromDate = null,
}) => {
  // const { activeSession } = useSelector(({ auth }) => auth);

  const Table = ({ children }) => (
    <View
      style={{
        marginTop: 8,
        marginBottom: 8,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </View>
  );

  const TableRow = ({ children }) => (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "right",
        borderBottom: "1px solid #e5e5e5",
      }}
    >
      {children}
    </View>
  );

  const TableRowNoLine = ({ children }) => (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "right",
      }}
    >
      {children}
    </View>
  );

  const TableHeader = ({ children }) => (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#367588",
      }}
    >
      {children}
    </View>
  );

  const TableCell = ({ children }) => (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 5,
        paddingVertical: 10,
        fontSize: 8,
        textAlign: "left",
      }}
    >
      <Text>{children}</Text>
    </View>
  );

  const TableCellRightAlign = ({ children }) => (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 5,
        paddingVertical: 10,
        fontSize: 8,
        textAlign: "right",
      }}
    >
      <Text>{children}</Text>
    </View>
  );

  const TableCellHeader = ({ children }) => (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 5,
        paddingVertical: 10,
        fontSize: 10,
        textAlign: "left",
      }}
    >
      <Text>{children}</Text>
    </View>
  );

  const TableCellHeaderRight = ({ children }) => (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 5,
        paddingVertical: 10,
        fontSize: 10,
        textAlign: "right",
      }}
    >
      <Text>{children}</Text>
    </View>
  );

  const TableWhiteSpace = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 20,
        }}
      ></View>
    );
  };

  const userData = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : null;


  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ffffff",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },

    image: {
      height: 80,
      width: 150,
      marginVertical: 15,
      marginHorizontal: 100,
    },
    imageLogo: {
      alignSelf: "center",
      height: 75,
      // width: 75,
      marginBottom: 10,
    },
    cellBackground: {
      backgroundColor: "#dddddd",
    },
    subtitle: {
      textAlign: "center",
      marginBottom: 5,
      fontSize: 12,
    },

    padded: {
      padding: 20,
    },

    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 20,
      fontWeight: 800,
      textAlign: "center",
      textDecoration: "none",
      marginBottom: 10,
    },
    downloadLink: {
      textDecoration: "none",
      color: "unset"      
    },
    button: {      
      //backgroundColor:"#367588",
      //width:'200px',
      alignSelf:'right',
      textAlign:'right',
      color:'unset',
      fontWeight:'400'
    },
    halfWidth:{
      width: '50%',
      marginTop:'10px',
      marginBottom:'10px'
    },
    halfWidthRight:{
      width: '50%',
      textAlign:'right'
    },
    textRight:{
      textAlign:'right'
    }
  });

  const InvoiceOpen = () => {

    return (
      <Document>
      <Page orientation="portrait" style={styles.padded}>
          <Image style={styles.imageLogo} src={logo} />
          <Text style={styles.title}>{String(userData.data?.name).toLocaleUpperCase()}</Text>
          {/* <Text style={styles.title}>{fileName.toUpperCase()}</Text> */}
          <Text style={{width:'100%'}}>PRO FORMA INVOICE</Text>
          <Text style={styles.halfWidth}>Bill To</Text>
          <Table>
            <TableRowNoLine>
                <TableCell>
                Customer: {headerData?.customer_name}
                </TableCell>
                <TableCellRightAlign>
                Invoice No: {headerData?.order_number}
                </TableCellRightAlign>
              </TableRowNoLine>
              <TableRowNoLine>
                <TableCell>
                Mobile: {headerData?.customer_mobile}
                </TableCell>
                <TableCellRightAlign>
                Invoice Date: {headerData?.order_date}
                </TableCellRightAlign>
              </TableRowNoLine>
              <TableRowNoLine>
                <TableCell>
                Email: {headerData?.customer_email}
                </TableCell>
                <TableCellRightAlign>
                Total Amount: {headerData?.amount}
                </TableCellRightAlign>
              </TableRowNoLine>
              </Table>
              <TableWhiteSpace />
              <Table>
              <TableHeader>
                <TableCellHeader key={`header-sn`}>S/N</TableCellHeader>
                <TableCellHeader key={`header-desc`}>Item Description</TableCellHeader>
                <TableCellHeaderRight key={`header-qty`}>Quantity</TableCellHeaderRight>
                <TableCellHeaderRight key={`header-rate`}>Rate</TableCellHeaderRight>
                <TableCellHeaderRight key={`header-amt`}>Amount</TableCellHeaderRight>

                {/* {columns.map((column, index) => (
                  <TableCellHeader key={`header-${index}`}>
                    {column.label}
                  </TableCellHeader>
                ))} */}
              </TableHeader>

              {data.map((item, rowIndex) => (
                  <TableRow key={`row-${rowIndex}`}>
                    <TableCell>{rowIndex+1}</TableCell>
                    <TableCell>{item.item_description}</TableCell>
                    <TableCellRightAlign>{item.counts}</TableCellRightAlign>
                    <TableCellRightAlign>{(item.rate)}</TableCellRightAlign>
                    <TableCellRightAlign>{(item.amount)}</TableCellRightAlign>
                  </TableRow>
                ))}

              {/* {data.map((row, rowIndex) => (
              <TableRow key={`row-${rowIndex}`}>
                <TableCell key={`row-${rowIndex}-col-sn`}>
                  {rowIndex + 1}
                </TableCell>
                {columns.map((col, colIndex) => (
                  <TableCell key={`row-${rowIndex}-col-${colIndex}`}>
                    {row[col.name] ?? ""}
                  </TableCell>
                ))}
              </TableRow>
            ))} */}

              </Table>
              <Text style={styles.textRight}>TOTAL {(headerData?.amount)}</Text>
          
      </Page>
      </Document>
    );
  };

  return (
    <PDFDownloadLink
      style={styles.downloadLink}
      document={<InvoiceOpen/>}
      fileName={`${fileName}.pdf`}
    >
      {({ blob, url, loading, error }) => (
        <>
          {!loading && <div style={styles.button}><b style={{backgroundColor:'#367588', padding:'5px', color:'#fff', borderRadius:'5px'}}>Download Invoice</b></div>}
          {loading && (
            <div
              style={{ width: "100px" }}
              className="flex-row justify-content-center align-items-center"
            >
              <CircularProgress size={11} color="primary" />
            </div>
          )}
        </>
      )}
    </PDFDownloadLink>
  );
};

export default InvoiceDoc;
