import { configureStore } from "@reduxjs/toolkit";
import themeSlice from "./themeSlice";
import userSlice from "./userSlice";
import authSlice from "./authSlice";
import commonSlice from "./commonSlice";
import itemsSlice from "./itemsSlice";
import stockSlice from "./stockSlice";
import posSlice from "./posSlice";
import salesSlice from "./salesSlice";
import rolesSlice from "./rolesSlice";
import resourcesSlice from "./resourcesSlice";
import workflowSlice from "./workflowSlice";
import productsSlice from "./productsSlice";
import disposedItemsSlice from "./disposedItemsSlice";
import expensesSlice from "./expensesSlice";
import sideEffectsSlice from "./sideEffectsSlice";
import handoversSlice from "./handoversSlice";
import reportsSlice from "./reportsSlice";
import businessesSlice from "./businessesSlice";
import loansSlice from "./loansSlice";
import loansProgressSlice from "./loansProgressSlice";
import taxSlice from "./taxSlice";
import customersSlice from "./customersSlice";
import ordersSlice from "./ordersSlice";
import creditsSlice from "./creditsSlice";
import barcodesSlice from "./barcodesSlice";



export const store = configureStore({
  reducer: {
    theme: themeSlice,
    users: userSlice,
    auth: authSlice,
    common: commonSlice,
    items: itemsSlice,
    stock: stockSlice,
    pos: posSlice,
    sales: salesSlice,
    roles: rolesSlice,
    resources: resourcesSlice,
    workflow: workflowSlice,
    products: productsSlice,
    disposedItems: disposedItemsSlice,
    expenses: expensesSlice,
    sideEffects: sideEffectsSlice,
    handovers: handoversSlice,
    reports: reportsSlice,
    businesses: businessesSlice,
    loans: loansSlice,
    loansProgress: loansProgressSlice,
    tax: taxSlice,
    customers: customersSlice,
    orders: ordersSlice,
    credits: creditsSlice,
    barcodes: barcodesSlice,
  },
});
