import { useSelector } from "react-redux";
import { HashLoader,CircleLoader, ClipLoader } from "react-spinners";

const MySpinner = ({ size, color }) => {
  const selectedColor = useSelector((state) => state.theme.color);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ClipLoader
        color={
          selectedColor === "theme-color-cyan"
            ? "#367588"
            : selectedColor === "theme-color-red"
            ? "#fb0b12"
            : selectedColor === "theme-color-blue"
            ? "#349eff"
            : selectedColor === "theme-color-green"
            ? "#019707"
            : selectedColor === "theme-color-gold"
            ? "#D7AE57"
            : "#d68102"
        }
        size={size}
      />
    </div>
  );
};

export default MySpinner;
