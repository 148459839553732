import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { assignWorkflow, getProductsList, getProductsWorkflowList, putProducts, putProductWorkflow, registerProducts } from "../api/productAPI";

export const getProducts = createAsyncThunk("products/getProducts", async (payload, { rejectWithValue }) => {
  try {
    const response = await getProductsList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getProductsWorkflows = createAsyncThunk("products/getProductsWorkflows", async (payload, { rejectWithValue }) => {
  try {
    const response = await getProductsWorkflowList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    //console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addProduct = createAsyncThunk("products/addProduct", async (payload, { rejectWithValue }) => {
  try {
    const response = await registerProducts(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addProductWorkflow = createAsyncThunk("products/addProductWorkflow", async (payload, { rejectWithValue }) => {
  try {
    const response = await assignWorkflow(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const updateProduct = createAsyncThunk("products/updateProduct", async (payload, { rejectWithValue }) => {
  try {
    const response = await putProducts(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const updateProductWorkflow = createAsyncThunk("products/updateProductWorkflow", async (payload, { rejectWithValue }) => {
  try {
    const response = await putProductWorkflow(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const productsSlice = createSlice({
  name: "products",
  initialState: {
    payload: {
      status: null,
      error: null,
      data: null,
    },
    post: {
      status: null,
      error: null,
      data: null,
    },
    put: {
      status: null,
      error: null,
      data: null,
    },
    get: {
      status: null,
      error: null,
      list: [],
    },
    productsWorkflow: {
      status: null,
      error: null,
      list: [],
    },
    postProductsWorkflow: {
      status: null,
      error: null,
      data: null,
    },
    putProductsWorkflow: {
      status: null,
      error: null,
      data: null,
    },
  },

  extraReducers: {
    [getProducts.pending]: (state) => {
      state.get.status = "loading";
    },
    [getProducts.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get product!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.get.status = 'idle';
    },
    [getProducts.rejected]: (state, action) => {
      state.get.status = "idle";
      state.get.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get product!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [getProductsWorkflows.pending]: (state) => {
      state.productsWorkflow.status = "loading";
    },
    [getProductsWorkflows.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.productsWorkflow.list = data;
        state.productsWorkflow.error = null;
      }else{
        state.productsWorkflow.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get product!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.productsWorkflow.status = 'idle';
    },
    [getProductsWorkflows.rejected]: (state, action) => {
      state.productsWorkflow.status = "idle";
      state.productsWorkflow.error = { message: "An error occurred" };
      //console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get product!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [addProduct.pending]: (state) => {
      state.post.status = "loading";
    },
    [addProduct.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Product saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.post.status = "idle";
        state.post.error = null;
      }else{
        toast.warn("Couldn't add product!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.post.error = "Failed";
      }

      state.post.data = data;
    },
    [addProduct.rejected]: (state, action) => {
      //state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add product!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [addProductWorkflow.pending]: (state) => {
      state.postProductsWorkflow.status = "loading";
    },
    [addProductWorkflow.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Workflow saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.postProductsWorkflow.status = "idle";
        state.postProductsWorkflow.error = null;
      }else{
        toast.error("Sorry! "+data?.message, {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.postProductsWorkflow.error = "Failed";
      }

      state.postProductsWorkflow.data = data;
    },
    [addProductWorkflow.rejected]: (state, action) => {
      state.postProductsWorkflow.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add workflow!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateProduct.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateProduct.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Product saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.put.status = "idle";
      }else{
        state.put.error = "Failed";
        toast.warn("Couldn't update product!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.data = data;
    },
    [updateProduct.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add product!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateProductWorkflow.pending]: (state) => {
      state.putProductsWorkflow.status = "loading";
    },
    [updateProductWorkflow.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Product workflow updated.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.putProductsWorkflow.status = "idle";
      }else{
        state.putProductsWorkflow.error = "Failed";
        toast.warn("Couldn't update product workflow!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.putProductsWorkflow.data = data;
    },
    [updateProductWorkflow.rejected]: (state, action) => {
      state.putProductsWorkflow.status = "idle";
      state.putProductsWorkflow.error = { message: "An error occurred" };
      //console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add product workflow!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

export default productsSlice.reducer;
