import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getItemsSideEffectList, postSideEffects, putSideEffects } from "../api/itemsAPI";

const initialState = {
  payload: {
    status: null,
    error: null,
    data: null,
  },
  post: {
    status: null,
    error: null,
    data: null,
  },
  put: {
    status: null,
    error: null,
    data: null,
  },
  get: {
    status: null,
    error: null,
    list: [],
  },
};

export const getSideEffects = createAsyncThunk("items/getSideEffects", async (payload, { rejectWithValue }) => {
  try {
    const response = await getItemsSideEffectList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addSideEffects = createAsyncThunk(
  "items/addSideEffects",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await postSideEffects(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateSideEffects = createAsyncThunk(
  "items/updateSideEffects",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await putSideEffects(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const sideEffectsSlice = createSlice({
  name: "items",
  initialState: initialState,
  reducers: {
    resetItemsState: (state) => {
      state.post.status = null;
      state.post.data = null;
      state.post.error = null;

      state.payload.data = null;
      state.payload.error = null;
      state.payload.status = null;

      state.put.error = null;
      state.put.data = null;

      state.get.status = null;
      state.get.error = null;
      state.get.list = [];
    },
  },
  extraReducers: {
    [getSideEffects.pending]: (state) => {
      state.get.status = "loading";
    },
    [getSideEffects.fulfilled]: (state, {payload}) => {
      // console.log('actionaction',payload)
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
      }
      state.get.status = 'idle';
    },
    [addSideEffects.pending]: (state) => {
      state.post.status = "loading";
    },
    [addSideEffects.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
      // if (status === 200 || status === 201) {
      //   state.post.data = data;
      //   state.post.error = null;
      // } else {
      //   state.post.error = { message: `${status} - An error occurred` };
      // }
      if (status === 201) {
        toast.success("Side effect saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't Side effect!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.post.status = "idle";
      state.post.data = data;
    },
    [addSideEffects.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't Side effect!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateSideEffects.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateSideEffects.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Side effect updated.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't update Side effect!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.status = "idle";
      // const index = state.findIndex((user) => user.id === action.payload.id);
      // state[index].updated = action.payload.updated;
    },
    [updateSideEffects.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.warn(action.error.message, {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

// export const { resetItemsState } = resetItemsState.actions;
export default sideEffectsSlice.reducer;
