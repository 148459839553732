import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { useDispatch, useSelector } from "react-redux";
import { addResource, updateResource,getResources } from "../../app/resourcesSlice";
import { getActions, getComponents } from "../../app/commonSlice";
import Barcode from "react-barcode";
import { getItems } from "../../app/itemsSlice";
import { barBatchSchema, hasAccess } from "../../common/validators";
import validate from "validate.js";
import { addBarcode, getBarcodes, updateBarcode } from "../../app/barcodesSlice";
import html2pdf from 'html2pdf.js';

const itemsTableHead = [
  { label: "Batch", name: "barcode_value" },
  { label: "Item", name: "item_description" },
  // { label: "Quantity", name: "qty" },
  { label: "Type", name: "batch_type" },
  // { label: "Date", name: "batch_date" },
  { label: "Status", name: "status" },
  "action",
];


const Barcodes = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);  
  let [hideSaveButton, setHideSaveButton] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [itemDataList, setItemDataList] = useState([]);
  const [componentsDataList, setComponentsDataList] = useState([]);
  const [actionsDataList, setActionsDataList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [formValues, setFormValues] = useState({"batch_date": new Date()});
  

  const dispatch = useDispatch();
  
  const { error, status, list } = useSelector(
    ({ barcodes }) => barcodes.get
  );

  const { error: itemsError, status: itemsStatus, list: itemsList } = useSelector(
    ({ items }) => items.get
  );

  const { error:postError, status:postStatus } = useSelector(
    ({ barcodes }) => barcodes.post
  );

  const { error:putError, status:putStatus } = useSelector(
    ({ barcodes }) => barcodes.put
  );


  const handleRequestClose = () => {
    setFormValues({});
    setOpenForm(false);
    setOpenDetail(false);  
    setEdit(false);
  };

  const viewItem = (item) => {
    if (item) {

      setViewDetailsSelected(true)
      let countsArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21];
      let count = 0;
      // do {
      //   // Perform some operation
      //   //setCount(count + 1);
      //   countsArray.push(count + 1);
      // } while (count < 10);
      // console.log('countsArray',countsArray)


      //setEdit(true);
      setFormValues(item);
      //setSelectedItem(item);
      setSelectedItem({
        ...item,
        batch_array:countsArray
      });

      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const editItem = (item) => {
    if (item) {
      dispatch(getItems())
      setEdit(true);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };

  const addBatch = () => {
    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    dispatch(getItems())
    setOpenForm(true);
    let currentDate = new Date();
    let barcodeVal = moment(currentDate).format("YYMMDDHHmmSS");
    setFormValues({
        ...formValues,
        "batch_type" : "Barcode",
        "barcode_value": String(userData.data.inst_id).substring(0,2)+""+barcodeVal
    })

  };

  const downloadPdf = (e) => {
    e.preventDefault();
    const element = document.getElementById('bar-codes');
    html2pdf().from(element).save(selectedItem.barcode_value+'_'+selectedItem.item_code+'.pdf');
  };

  const isAddAccess = () => {
    return hasAccess('barcodes','add');
  }

  const isEditAccess = () => {
    return hasAccess('barcodes','edit');
  }

  const isViewAccess = () => {
    return hasAccess('barcodes','view');
  }


  const formOpen = () => {
    return (
      <>
        <div className="row">
          {/* <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="batch_date"
              size="small"
              label="Batch Date"
              value={formValues.batch_date}
              inputType="date"
              onChange={(e) =>
                handleChange({ target: { name: "batch_date", value: e } })}
              error={errors["batch_date"] ? true : false}
              helperText={errors["batch_date"] ? errors.batch_date[0] : null}
            />
          </div> */}
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="barcode_value"
              size="small"
              label="Batch Number Value"
              disabled={edit ? false: true}
              value={formValues.barcode_value}
              inputType="text"
              //disabled={edit?true:false}
              onChange={handleChange}
              error={errors["barcode_value"] ? true : false}
              helperText={errors["barcode_value"] ? errors.barcode_value[0] : null}
            />
          </div>
          {/* <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="qty"
              size="small"
              label="Quantity"
              value={formValues.qty}
              inputType="number"
              onChange={handleChange}
              error={errors["qty"] ? true : false}
              helperText={errors["qty"] ? errors.qty[0] : null}
            />
          </div> */}

        <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="batch_type"
            size="small"
            inputType="select"
            value={formValues?.batch_type}
            defaultValue={"Barcode"}
            label="Batch Type"
            listLabel="Batch Type"
            listValue="batch_type"
            list={[{label:'Barcode',value:'Barcode'},{label:'Qrcode',value:'Qrcode'}]}
            onChange={handleChange}
            error={errors["batch_type"] ? true : false}
            helperText={errors["batch_type"] ? errors.batch_type[0] : null}
          />
        </div>

        
        <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="item_code"
            size="small"
            inputType="select"
            value={formValues.item_code}
            defaultValue={componentsDataList[0]}
            label="Item"
            listLabel="Item"
            listValue="Item"
            list={componentsDataList}
            onChange={handleChange}
            error={errors["item_code"] ? true : false}
            helperText={errors["item_code"] ? errors.item_code[0] : null}
          />
        </div>

        <div className="col-12">
          <Barcode value={formValues.barcode_value} />
        </div>
          
        </div>
      </>
    );
  };


  const dialogOpen = () => {
    return (
      <>
        <div className="row" id="bar-codes">
          
          {/* <div className="col-12"> */}
          { selectedItem.batch_array && selectedItem.batch_array.map((item) => (
            <div key={item} className="col-4">
             <Barcode value={selectedItem.barcode_value} />
            </div>
          ))}
          
          {
            <div className="col-12" style={{textAlign:'center',paddingTop:'40px'}}>
            {selectedItem.item_description} | {selectedItem.item_code} | {moment(selectedItem.batch_date).format("YYYY-MM-DD")}
            </div>
          }
          
          
        {/* </div> */}
          
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    // alert(JSON.stringify(event.target.value))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    let validationErrors = validate(
      formValues,
      barBatchSchema
    );
    if(validationErrors){
      setErrors(validationErrors);      
    }else{
    if(edit){
      let newItem = {
        "id": selectedItem?.id,
        "batch_type": formValues?.batch_type,
        "barcode_value": formValues?.barcode_value,
        "batch_date": formValues.batch_date ? formValues?.batch_date : new Date(),
        "qty": formValues.qty,
        "status": formValues.status ? formValues.status: "A",
        "item_code": formValues.item_code
      }
      dispatch(updateBarcode(newItem));
    }else{
      let newItem = {
        "batch_type": formValues?.batch_type,
        "barcode_value": formValues?.barcode_value,
        "batch_date": new Date(),
        "qty": formValues.qty,
        "status": formValues.status ? formValues.status: "A",
        "item_code": formValues.item_code
      }
      dispatch(addBarcode(newItem));
    }   
  } 
    
  };

  

  useEffect(() => {
    dispatch(getBarcodes());  
  }, [dispatch]);
  

  useEffect(() => {
    if(postStatus === "loading"){
      setHideSaveButton(true);
    }else if(postStatus === "idle"){
      dispatch(getBarcodes());     
      setHideSaveButton(false);
      setOpenForm(false)
    }
  }, [postStatus]);

  useEffect(() => {
    if(putStatus === "loading"){
      setHideSaveButton(true);
    }else if(putStatus === "idle"){
      dispatch(getBarcodes());   
      setHideSaveButton(false);
      setOpenForm(false)
    }

  }, [putStatus]);


  useEffect(() => {
    if(status === "loading"){

    }else if(status === "idle"){
      setItemDataList(list);
    }

  }, [status,list]);

  useEffect(() => {
    if(itemsStatus === "loading"){

    }else if(itemsStatus === "idle"){
      let actionsArray = [];
      for (let index = 0; index < itemsList.length; index++) {
        actionsArray.push({value:itemsList[index].item_code,label:itemsList[index].item_description})        
      }
      setComponentsDataList(actionsArray);
    }
  }, [itemsStatus,itemsList]);
  
 

  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Batch List</h2>
         &nbsp;
         {
          isAddAccess === true && <div style={{alignContent:"right"}}>
          <Inputs
            color="success"
            className={`bx-burst-hover`}
            size="small"
            inputType="button"
            type="submit"
            text={"New Batch"}
            onClick={() => {
              addBatch()
              }
            }
          />
          &nbsp;
          </div>
         }
         
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {itemDataList.length>0 ? (
                <Table
                  columns={itemsTableHead}
                  data={itemDataList}
                  actions={[
                    {
                      action: "Details",
                      renderCheck: isViewAccess,
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Update",
                      renderCheck: isEditAccess,
                      onClick: (item) => {
                        editItem(item);
                      },
                    }
                  ]}
                  downloadFileName={"Available Resources"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={"Batch Code for "+selectedItem?.item_description+" ("+selectedItem?.item_code+") | Date "+moment(selectedItem?.batch_date).format("YYYY-MM-DD")}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={"Download"}
        content={() => dialogOpen()}
        submit={(e) => downloadPdf(e)}
        hideSaveButton={false}
        propBackgroundColor="#367588"
        
      />

      <MyDialog
        title={"Batch Details"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={!edit ? "Save" : "Update"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor="#367588"
      />

    </div>
  );
};

export default Barcodes;
