import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, updateProduct, getProducts, addProductWorkflow, getProductsWorkflows,updateProductWorkflow } from "../../app/productsSlice";
import { getActions, getComponents } from "../../app/commonSlice";
import { getWorkflow } from "../../app/workflowSlice";

const itemsTableHead = [
  { label: "Name", name: "name" },
  { label: "Interest Rate", name: "int_rate" },
  { label: "Workflow Stages", name: "stages_count" },
  "action",
];

const workflowTableHead = [
  { label: "Stage Number", name: "stage_no" },
  { label: "Name", name: "workflow_name" },
  { label: "Minimum Attendants", name: "minimum_attendants" },
  "action",
];


const Products = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  let [addWorkflow, setAddWorkflow] = useState(false);


  const [formValues, setFormValues] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [itemDataList, setItemDataList] = useState([]);
  const [workflowDataList, setWorkflowDataList] = useState([]);
  const [actionsDataList, setActionsDataList] = useState([]);



  const dispatch = useDispatch();

  const { error, status, list } = useSelector(
    ({ products }) => products.get
  );

  const { error: productsWorkflowError, status: productsWorkflowStatus, list: productsWorkflowList } = useSelector(
    ({ products }) => products.productsWorkflow
  );

  const { error: workflowError, status: workflowStatus, list: workflowList } = useSelector(
    ({ workflow }) => workflow.get
  );

  const { error: postError, status: postStatus } = useSelector(
    ({ products }) => products.post
  );

  const { error: putError, status: putStatus } = useSelector(
    ({ products }) => products.put
  );

  const { error: putProductsWorkflowError, status: putProductsWorkflowStatus } = useSelector(
    ({ products }) => products.putProductsWorkflow
  );

  const { get: getActionsData } = useSelector(
    ({ common }) => common.actions
  );

  const { get: getComponentsData } = useSelector(
    ({ common }) => common.components
  );



  const handleRequestClose = () => {
    setFormValues({});
    setOpenForm(false);
    setAddWorkflow(false);
    setOpenDetail(false);
    setEdit(false);
  };

  const toAddWorkflow = (item) => {
    if (item) {
      dispatch(getWorkflow())
      setSelectedItem(item);
      setOpenDetail(false);
      setTimeout(() => {
        setAddWorkflow(true)
      }, 300);
    }
  };

  const toRemoveWorkflow = (item) => {
    if (item) {
     dispatch(updateProductWorkflow({id:item?.id,status:"X"}))
    }
  };

  const viewItem = (item) => {
    if (item) {
      dispatch(getProductsWorkflows({ product_id: item?.id }))
      setViewDetailsSelected(true)

      //setEdit(true);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const editItem = (item) => {
    if (item) {
      setEdit(true);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };

  const formOpenWorkflow = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="product_name"
              size="small"
              label="Product"
              value={selectedItem?.name}
              inputType="text"
              disabled={true}
            />
          </div>
          {workflowDataList.length ? (
            <div className="col-sm-12 col-md-6 col-6">
              <Inputs
                name="workflow_id"
                size="small"
                inputType="select"
                value={formValues.workflow_id}
                defaultValue={workflowDataList[0]["value"]}
                label="Workflow"
                listLabel="Workflow"
                listValue="workflow_id"
                list={workflowDataList}
                onChange={handleChange}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="stage_no"
              size="small"
              label="Stage Number"
              value={formValues.stage_no}
              inputType="number"
              disabled={false}
              onChange={handleChange}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="minimum_attendants"
              size="small"
              label="Minimum Attendants"
              value={formValues.minimum_attendants}
              defaultValue={1}
              inputType="number"
              disabled={false}
              onChange={handleChange}
            />
          </div>
        </div>
      </>
    );
  };


  const formOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="name"
              size="small"
              label="Name"
              value={formValues.name}
              inputType="text"
              disabled={false}
              onChange={handleChange}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="int_rate"
              size="small"
              label="Interest Rate"
              value={formValues.int_rate}
              inputType="number"
              disabled={false}
              onChange={handleChange}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="stages_count"
              size="small"
              label="Workflow Stages"
              value={formValues.stages_count}
              inputType="number"
              disabled={false}
              onChange={handleChange}
            />
          </div>
        </div>
      </>
    );
  };


  const dialogOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <span><b>Name</b></span>&nbsp;&nbsp;
            <span>{selectedItem?.name}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span><b>Interest Rate</b></span>&nbsp;&nbsp;
            <span>{selectedItem?.int_rate}</span>
          </div>
          <br />
          <br />
          <div className="col-sm-12 col-md-6 col-6">
            <span><b>Workflow Stages</b></span>&nbsp;&nbsp;
            <span>{selectedItem?.stages_count}</span>
          </div>
          <br />
          <div className="col-sm-12 col-md-12 col-12">
            {productsWorkflowList && productsWorkflowList.length > 0 ? (
              <Table
                columns={workflowTableHead}
                data={productsWorkflowList}
                actions={[
                  {
                    action: "Remove",
                    onClick: (item) => {
                      toRemoveWorkflow(item);
                    },
                  },
                ]}
                downloadFileName={"Available Product Workflows"}
              />
            ) : (
              <MySpinner size={35} color="#367588" />
            )}
          </div>
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    //alert(JSON.stringify(event.target))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    if (edit) {
      let newItem = {
        "id": selectedItem?.id,
        "name": formValues?.name,
        "int_rate": formValues?.int_rate,
        "stages_count": formValues?.stages_count,
      }
      dispatch(updateProduct(newItem));
    } else if (addWorkflow) {
      // alert("Workflow")
      let newItem = {
        "product_id": selectedItem?.id,
        "workflow_id": formValues?.workflow_id,
        "stage_no": formValues?.stage_no,
        "minimum_attendants": formValues?.minimum_attendants,
      }
      dispatch(addProductWorkflow(newItem))
    } else {
      let newItem = {
        "name": formValues?.name,
        "int_rate": formValues?.int_rate,
        "stages_count": formValues?.stages_count,
      }
      dispatch(addProduct(newItem));
    }

  };



  useEffect(() => {
    dispatch(getProducts());
    // dispatch(getActions());    
    // dispatch(getComponents());    
  }, [dispatch]);


  useEffect(() => {
    if (postStatus === "loading" && !edit) {
      setHideSaveButton(false);
    } else if (postStatus === "idle") {
      dispatch(getProducts());
      setHideSaveButton(false);
    }
  }, [postStatus]);

  useEffect(() => {
    if (putStatus === "loading" && edit) {
      setHideSaveButton(false);
    } else if (putStatus === "idle") {
      dispatch(getProducts());
      setHideSaveButton(false);
    }

  }, [putStatus]);

  useEffect(() => {
    if (putProductsWorkflowStatus === "loading") {
      
    } else if (putProductsWorkflowStatus === "idle") {
      dispatch(getProductsWorkflows({ product_id: selectedItem?.id }))
    }

  }, [putProductsWorkflowStatus,putProductsWorkflowError]);

  


  useEffect(() => {
    if (status === "loading") {

    } else if (status === "idle") {
      setItemDataList(list);
    }

  }, [status, list]);



  useEffect(() => {
    if (workflowStatus === "loading") {

    } else if (workflowStatus === "idle") {
      let componentsArray = [];
      for (let index = 0; index < workflowList.length; index++) {
        componentsArray.push({ value: workflowList[index]?.id, label: workflowList[index]?.name })
      }
      setWorkflowDataList(componentsArray);
    }
  }, [workflowList, workflowStatus]);



  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Available Products</h2>
        &nbsp;
        <div style={{ alignContent: "right" }}>
          <Inputs
            color="success"
            className={`bx-burst-hover`}
            size="small"
            inputType="button"
            type="submit"
            text={"New Product"}
            onClick={() => setOpenForm(true)}
          />
          &nbsp;
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {itemDataList.length > 0 ? (
                <Table
                  columns={itemsTableHead}
                  data={itemDataList}
                  actions={[
                    {
                      action: "Details",
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Update",
                      onClick: (item) => {
                        editItem(item);
                      },
                    },
                    {
                      action: "Add Workflow",
                      onClick: (item) => {
                        toAddWorkflow(item);
                      },
                    }


                  ]}
                  downloadFileName={"Available Products"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={"Product Details"}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={productsWorkflowList && productsWorkflowList.length < selectedItem.stages_count ? "Add Workflow" : null}
        content={() => dialogOpen()}
        submit={(e) => toAddWorkflow(selectedItem)}
        hideSaveButton={productsWorkflowList && productsWorkflowList.length < selectedItem.stages_count ? false: true}
      />

      <MyDialog
        title={edit ? "Update Product Details" : "Add New Product"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={!edit ? "Save" : "Update"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
      />

      <MyDialog
        title={"Add Product Workflow"}
        width="md"
        onClose={handleRequestClose}
        open={addWorkflow}
        text={"Save"}
        content={() => formOpenWorkflow()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
      />



    </div>
  );
};

export default Products;
