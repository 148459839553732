import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getSaleInvoice, getSalesList, reverseSaleEntry } from "../api/salesAPI.js";

const initialState = {
  payload: {
    status: null,
    error: null,
    data: null,
  },
  post: {
    status: null,
    error: null,
    data: null,
  },
  put: {
    status: null,
    error: null,
    data: null,
  },
  get: {
    status: null,
    error: null,
    list: [],
  },
  invoice: {
    status: null,
    error: null,
    data: null,
  },
};

export const getSales = createAsyncThunk("items/getSales", async (payload, { rejectWithValue }) => {
  try {
    const response = await getSalesList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const saleInvoice = createAsyncThunk("items/saleInvoice", async (payload, { rejectWithValue }) => {
  try {
    const response = await getSaleInvoice(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const reverseSale = createAsyncThunk("sales/reverseSale", async (payload, { rejectWithValue }) => {
  try {
    const response = await reverseSaleEntry(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const salesSlice = createSlice({
  name: "items",
  initialState: initialState,
  reducers: {
    resetItemsState: (state) => {
      state.post.status = null;
      state.post.data = null;
      state.post.error = null;

      state.payload.data = null;
      state.payload.error = null;
      state.payload.status = null;

      state.put.status = null;
      state.put.error = null;
      state.put.data = null;

      state.get.status = null;
      state.get.error = null;
      state.get.list = [];

      state.invoice.data = null;
      state.invoice.error = null;
      state.invoice.status = null;
    },
  },
  extraReducers: {
    [getSales.pending]: (state) => {
      state.get.status = "loading";
    },
    [getSales.fulfilled]: (state, {payload}) => {
      // console.log('actionaction',payload)
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get item sales!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.get.status = 'idle';
    },
   [getSales.rejected]: (state, action) => {
      state.get.status = "idle";
      state.get.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get item sales!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [saleInvoice.pending]: (state) => {
      state.invoice.status = "loading";
    },
    [saleInvoice.fulfilled]: (state, {payload}) => {
      // console.log('actionaction',payload)
      const { status, data } = payload;
      if (status === 200) {
        state.invoice.data = data;
        state.invoice.error = null;
      }else{
        state.invoice.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get sale invoice!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.invoice.status = 'idle';
    },
    [saleInvoice.rejected]: (state, action) => {
      state.invoice.status = "idle";
      state.invoice.error = { message: "An error occurred" };
      //console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get item sales!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [reverseSale.pending]: (state) => {
      state.put.status = "loading";
    },
    [reverseSale.fulfilled]: (state, {payload}) => {
      // console.log('actionaction',payload)
      const { status, data } = payload;
      if(status === 200){
        state.put.data = data;
        state.put.error = null;
        toast.success("Successful", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        state.put.error = { message: `${status} - An error occurred` };
        toast.error("System error - couldn't save sale reverse!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.status = 'idle';
    },
    [reverseSale.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      //console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error - couldn't save sale reverse!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

// export const { resetItemsState } = resetItemsState.actions;
export default salesSlice.reducer;
