import React, { useState } from "react";

import "./sidebar.css";
import logo from "../../assets/images/ez_logo.svg";

import sidebar_items from "../../assets/JsonData/sidebar_routes.json";
import sidebar_services from "../../assets/JsonData/sidebar_routes_services.json";
import sidebar_items_agent from "../../assets/JsonData/sidebar_routes_agent.json";
import { Link } from "react-router-dom";
import { hasAccess, instCat } from "../../common/validators";

const SidebarItem = (props) => {
  const [showChild, setShowChild] = useState(false);
  const active = props.active ? "active" : "";

  return (
    <div
      className="ez-side-item"
      onMouseOver={() => {
        if (props.child != null) {
          setShowChild(true);
        }
      }}
      onMouseLeave={() => {
        if (props.child != null) {
          setShowChild(false);
        }
      }}
    >
      <div className={`ez-side-item-inner ${active}`}>
        <>
          <i className={props.icon}></i>
          <span className="ez-item-title">{props.title}</span>
        </>
        {showChild && (
          <>
            <i className="bx bx-chevron-down"></i>
          </>
        )}
      </div>
      {!showChild && <span className="ez-tooltip">{props.title}</span>}

      {showChild &&
        props.child.map((item, index) => (
          <div className={`ez-side-child`}>
            <Link to={item.route} key={index}>
              {item.display_name}
            </Link>
          </div>
        ))}
    </div>
  );
};



const Sidebar = (props) => {
  const [activeMenu, setActiveMenu] = useState("active");

  const collapseMenu = () => {
    activeMenu === "active" ? setActiveMenu("") : setActiveMenu("active");
  };
  const activeItem = instCat() === 4 ? 
  sidebar_items_agent.findIndex(
    (i) => i.route === window.location.pathname
  ) : instCat() === 5 ? 
  sidebar_services.findIndex(
    (i) => i.route === window.location.pathname
  ) :  
  sidebar_items.findIndex(
    (i) => i.route === window.location.pathname
  );

  return (
    <div className={`ez-sidebar ${activeMenu}`}>
      <div className="ez-side-logo" style={{backgroundColor:'#e0e0e0'}}>
        <h3 style={{color:'#367588',marginLeft:'-10px',}}>TaiPOS</h3>
      </div>

      <i
        className="bx bx-menu ez-sidebar-menu"
        style={{paddingTop:'12px'}}
        onClick={() => {
          collapseMenu();
        }}
      ></i>
      
      {
        instCat() === 4 ?
        <Link to={sidebar_items_agent[0].route ?? ""} key={0}>
          <SidebarItem
            title={sidebar_items_agent[0].display_name}
            icon={sidebar_items_agent[0].icon}
            active={0 === activeItem}
            child={sidebar_items_agent[0].child}
          />
        </Link>
        
        :
      
        <Link to={sidebar_items[0].route ?? ""} key={0}>
          <SidebarItem
            title={sidebar_items[0].display_name}
            icon={sidebar_items[0].icon}
            active={0 === activeItem}
            child={sidebar_items[0].child}
          />
        </Link>
      }

      {instCat() === 4 ?  sidebar_items_agent.map((item, index) => (
        (item.route != "/" && hasAccess(String(item.route).split('/')[1],'view') === true) &&
        <Link to={item.route ?? ""} key={index}>
          <SidebarItem
            title={item.display_name}
            icon={item.icon}
            active={index === activeItem}
            child={item.child}
          />
        </Link>
      )) :
      
      instCat() === 5 ?
      sidebar_services.map((item, index) => (
        (item.route != "/" && hasAccess(String(item.route).split('/')[1],'view') === true) &&
        <Link to={item.route ?? ""} key={index}>
          <SidebarItem
            title={item.display_name}
            icon={item.icon}
            active={index === activeItem}
            child={item.child}
          />
        </Link>
      )) :

      sidebar_items.map((item, index) => (
        (item.route != "/" && hasAccess(String(item.route).split('/')[1],'view') === true) &&
        <Link to={item.route ?? ""} key={index}>
          <SidebarItem
            title={item.display_name}
            icon={item.icon}
            active={index === activeItem}
            child={item.child}
          />
        </Link>
      ))

      }
    </div>
  );
};
export default Sidebar;
