import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getCreditsItemsList, getCreditsList, postCreditRepayment, postCredits, putCredits } from "../api/creditsAPI";
import { getCustomersList, postCustomers, putCustomers } from "../api/customersAPI";

const initialState = {
  payload: {
    status: null,
    error: null,
    data: null,
  },
  post: {
    status: null,
    error: null,
    data: null,
  },
  put: {
    status: null,
    error: null,
    data: null,
  },
  get: {
    status: null,
    error: null,
    list: [],
  },
  items: {
    status: null,
    error: null,
    list: [],
  },
  repayments: {
    list: [],
  },
  postRepayments: {
    status: null,
    error: null,
    data: null,
  },
};

export const getCredits = createAsyncThunk("customer/getCredits", async (payload, { rejectWithValue }) => {
  try {
    const response = await getCreditsList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getCreditItems = createAsyncThunk("customer/getCreditItems", async (payload, { rejectWithValue }) => {
  try {
    const response = await getCreditsItemsList(payload);
    //console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addCredit = createAsyncThunk(
  "customers/addCredit",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await postCredits(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const addCreditRepay = createAsyncThunk(
  "customers/addCreditRepay",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postCreditRepayment(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateCredit = createAsyncThunk(
  "customers/updateCredit",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await putCredits(payload);
      return response;
    } catch (err) {
      // console.log('err',err)
      return rejectWithValue(err.message);
    }
  }
);


export const creditsSlice = createSlice({
  name: "credits",
  initialState: initialState,
  reducers: {
    resetCreditsState: (state) => {
      state.post.status = null;
      state.post.data = null;
      state.post.error = null;

      state.payload.data = null;
      state.payload.error = null;
      state.payload.status = null;

      state.put.error = null;
      state.put.data = null;

      state.get.status = null;
      state.get.error = null;
      state.get.list = [];

      state.items.status = null;
      state.items.error = null;
      state.items.list = [];

      state.repayments.list = [];

      state.postRepayments.status = null;
      state.postRepayments.data = null;
      state.postRepayments.error = null;
    },
  },
  extraReducers: {
    [getCredits.pending]: (state) => {
      state.get.status = "loading";
    },
    [getCredits.fulfilled]: (state, {payload}) => {
      //console.log('actionaction',payload.data)
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
      }
      state.get.status = 'idle';
    },
    [getCreditItems.pending]: (state) => {
      state.items.status = "loading";
    },
    [getCreditItems.fulfilled]: (state, {payload}) => {
      //console.log('actionaction',payload.data)
      const { status, data } = payload;
      if (status === 200) {
        state.items.list = data.salesDetails;
        state.repayments.list = data.repaymentDetails;
        state.items.error = null;
      }else{
        state.items.error = { message: `${status} - An error occurred` };
      }
      state.items.status = 'idle';
    },
    [addCredit.pending]: (state) => {
      state.post.status = "loading";
    },
    [addCredit.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
     if (status === 201) {
        toast.success("Credit saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't add credit!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.post.status = "idle";
      state.post.data = data;
    },
    [addCredit.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add credit!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [addCreditRepay.pending]: (state) => {
      state.postRepayments.status = "loading";
    },
    [addCreditRepay.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
     if (status === 201) {
        toast.success("Repayment saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't add repayment!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.postRepayments.status = "idle";
      state.postRepayments.data = data;
    },
    [addCreditRepay.rejected]: (state, action) => {
      state.postRepayments.status = "idle";
      state.postRepayments.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add repayment!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateCredit.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateCredit.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Credit updated.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn("Couldn't update credit!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.status = "idle";
      // const index = state.findIndex((user) => user.id === action.payload.id);
      // state[index].updated = action.payload.updated;
    },
    [updateCredit.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      //alert(JSON.stringify(action.error))
      toast.warn(action.error.message, {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

// export const { resetItemsState } = resetItemsState.actions;
export default creditsSlice.reducer;
