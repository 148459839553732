import React, { useEffect, useState } from 'react'
import moment from 'moment';


const BillReminder = ({ billData }) => {


    // {
    //     "name": "TAI SHOP",
    //     "registration_date": "2023-04-15T07:33:35.000Z",
    //     "due_date": "2023-08-26",
    //     "due_days": -7,
    //     "id": 21,
    //     "invoice_no": "72308191440",
    //     "entry_date": "2023-09-02T05:46:26.000Z",
    //     "entry_by": 18,
    //     "status": "active",
    //     "invoice_date": "2023-08-19T00:00:00.000Z",
    //     "remarks": NULL,
    //     "start_date": "2023-08-19T00:00:00.000Z",
    //     "end_date": "2030-11-17T00:00:00.000Z",
    //     "inst_id": 23,
    //     "amount": 0
    //   }

    return (
        <div className="ez-main-card">
            <div className="col-12  col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-5 col-md-6 col-sm-6" key={0} onClick={{}} style={{cursor:"pointer"}}>
                        Invoice number <b>{billData?.invoice_no}</b><br/>
                        Amount <b>{Number(billData?.amount).toLocaleString()}</b><br/>
                        Pay before <b>{moment(billData?.due_date).format("YYYY-MM-DD")}</b><br/>
                        Pay within <b>{billData?.due_days}</b>  Days <br/>
                        Subscription Start Date <b>{moment(billData?.start_date).format("YYYY-MM-DD")}</b><br/>
                        Subscription End Date <b>{moment(billData?.end_date).format("YYYY-MM-DD")}</b><br/>
                    </div>
                    <div className="col-7 col-md-6 col-sm-6" key={0} onClick={{}} style={{cursor:"pointer"}}>
                        <h4>HOW TO PAY</h4><br/>
                        TIGOPESA
                        <ul>
                            <li>Dial *150*01#</li>
                            <li>Choose Pay Bill(4)</li>
                            <li>Choose Enter Business number(3)</li>
                            <li>Enter Business number(700500)</li>
                            <li>Enter reference number provided ({billData?.invoice_no})</li>
                            <li>Enter amount billed ({billData?.amount})</li>
                        </ul>
                        <br/>
                        M-PESA
                        <ul>
                        <li>Dial *150*00#</li>
                            <li>Choose Pay By M-Pesa(4)</li>
                            <li>Choose Enter Business number(4)</li>
                            <li>Enter Business number(700500)</li>
                            <li>Enter reference number provided ({billData?.invoice_no})</li>
                            <li>Enter amount billed ({billData?.amount})</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )

};

export default BillReminder;