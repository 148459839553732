import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getPOSaleList, postPos, putPos } from "../api/posAPI";

const initialState = {
  payload: {
    status: null,
    error: null,
    data: null,
  },
  post: {
    status: null,
    error: null,
    data: null,
  },
  put: {
    status: null,
    error: null,
    data: null,
  },
  get: {
    status: null,
    error: null,
    list: [],
  },
};

export const getPosHistory = createAsyncThunk("pos/getPosHistory", async (payload, { rejectWithValue }) => {
  try {
    const response = await getPOSaleList(payload);
    //console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addPos = createAsyncThunk(
  "pos/addPos",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postPos(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updatePos = createAsyncThunk(
  "pos/updatePos",
  async (payload, { rejectWithValue }) => {
    try {      
      const response = await putPos(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const posSlice = createSlice({
  name: "pos",
  initialState: initialState,
  reducers: {
    resetPosState: (state) => {
      state.post.status = null;
      state.post.data = null;
      state.post.error = null;

      state.payload.data = null;
      state.payload.error = null;
      state.payload.status = null;

      state.put.error = null;
      state.put.data = null;

      state.get.status = null;
      state.get.error = null;
      state.get.list = [];
    },
  },
  extraReducers: {
    [getPosHistory.pending]: (state) => {
      state.get.status = "loading";
    },
    [getPosHistory.fulfilled]: (state, {payload}) => {
      //console.log('payloadpayload',payload.data)
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
      }
      state.get.status = "idle";
    },
    [getPosHistory.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
    },

    [addPos.pending]: (state) => {
      state.post.status = "loading";
    },
    [addPos.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
      // if (status === 200 || status === 201) {
      //   state.post.data = data;
      //   state.post.error = null;
      // } else {
      //   state.post.error = { message: `${status} - An error occurred` };
      // }
      if (status === 200 || status === 201) {
        state.post.status = "posted";
        state.post.error = null;
        toast.success("Sale saves successfully.", {
          autoClose: 1800,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        state.post.error = "failed";
        state.post.status = null;
        toast.warn("Couldn't add stock!", {
          autoClose: 1800,
          hideProgressBar: true,
        });
      }
      
      state.post.data = data;
    },
    [addPos.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
    },
    [updatePos.pending]: (state) => {
      state.put.status = "loading";
    },
    [updatePos.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Stock updated.", {
          autoClose: 1800,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn(data.message, {
          autoClose: 1800,
          hideProgressBar: true,
        });
      }
      state.put.status = "idle";
      // const index = state.findIndex((user) => user.id === action.payload.id);
      // state[index].updated = action.payload.updated;
    },
    [updatePos.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.warn(action.error.message, {
        autoClose: 1800,
        hideProgressBar: true,
      });
    },
  },
});

// export const { resetStockState } = resetStockState.actions;
export default posSlice.reducer;
