import React, { useState, useEffect, useCallback } from "react";
// import logo from "../../assets/images/taiPOS.png";
// import "./login.css";
import Footer from "../../components/footer/Footer";
import { Button, Grid } from '@material-ui/core';
// import './intro.css';

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Policy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();




  const toLogin = () => {
    navigate("/login");
  }

  const toHome = () => {
    navigate("/intro");
  }

  const toPolicy = () => {
    navigate("/policy");
  }

  const toPricing = () => {
    navigate("/pricing");
  }



  //alert(localStorage.getItem('token'))
  return (
    <Grid className="intros" container spacing={1} style={{ height: '100vh', padding: '3rem' }}>
      <Grid item xs={12} md={6}>
        <h2 onClick={(e) => {
          e.preventDefault();
          toHome()
        }} style={{ color: '#367588' }}>TaiPOS</h2>
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'row' }}>
        <Grid item xs={2} md={2}>

        </Grid>
        <Grid item xs={2} md={2}>
          <p className="top-menu" onClick={(e) => {
            e.preventDefault();
            toHome()
          }}>
            Home
          </p>
        </Grid>
        <Grid item xs={2} md={2}>
          <p className="top-menu" onClick={(e) => {
            e.preventDefault();
            toPricing()
          }}>
            Pricing
          </p>
        </Grid>
        <Grid item xs={2} md={2}>
          <p className="top-menu" onClick={(e) => {
            e.preventDefault();
            toPolicy()
          }}>
            Terms
          </p>

        </Grid>
        <Grid item xs={2} md={2}>

          <Button
            id="btn-nav-resend"
            size="small"
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#367588' }}
            onClick={(e) => {
              e.preventDefault();
              toLogin()
            }}
          >
            Login
          </Button>
        </Grid>
      </Grid>
      <div
        className="card-body"
        style={{ display: "flex", flexDirection: "column", paddingLeft: '3rem' }}
      >


        <br />
        <br />
        <h4>Terms of Use</h4><br />
        The following Terms of Use (“Agreement”) governs your use of the platform. By accessing the platform, you agree to be bound by and comply with the terms and conditions of this Agreement. If, at any time, you choose not to accept the terms and conditions of this Agreement, do not access or use the platform in any manner.
        Please take a few minutes to read this Agreement carefully.

        <br /><br />
        <h4>User Submissions</h4>
        <br />
        By submitting User Content to any part of the platform, you represent and warrant that:
        <ol>
          <li>
            You are the sole author and owner of any User(s) Contents you submit;
          </li>
          <li>
            You are solely responsible for any contributions, comments or postings you submit, including any feedback or questions;
          </li>
          <li>
            All User(s) Contents that you post are accurate;
          </li>
          <li>
            You are at least 18 years old;
          </li>
          <li>
            Your use of the User(s) Contents does not violate this Agreement and will not cause injury to any person or entity;
          </li>
          <li>
            You have not been offered, have not accepted, and are not entitled to receive any compensation in any form and from any party in connection with submitted User(s) Contents.
          </li>
        </ol>

        <br />
        You further agree and warrant that you will not:<br />
        <ol>
          <li>
            Submit any User(s) Contents that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful or racially, ethnically or otherwise objectionable;
          </li>
          <li>
            Submit any User(s) Contents that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software, hardware or telecommunications equipment;

          </li>
          <li>
            Submit any User(s) Contents that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;

          </li>
          <li>
            Submit any User(s) Contents that is false or misleading;

          </li>
          <li>
            Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;

          </li>
          <li>
            Invade another’s privacy in any way, including posting another’s personal details without their prior permission;

          </li>
          <li>
            Manipulate identifiers in order to disguise the origin of any User(s) Contents submitted;

          </li>
          <li>
            Act in a manner that affects other users’ of the platform;

          </li>
          <li>
            Intentionally or unintentionally violate any applicable agreement.
          </li>
        </ol>

        <br />
        <h4>Information And Passwords</h4>

        <br />
        <ul>
          <li>
            You may register and create an account to access features and functions of the platform. You are solely responsible for maintaining the confidentiality of the user ID and password, and are fully responsible for all activities that occur under your account. TaiPOS does not retain liability or responsibility for such use.

          </li>
        </ul>

        <br />
        <h4>Termination</h4>

        <br />
        TaiPOS may immediately suspend access to the platform and remove and discard any Content you submitted for any reason if TaiPOS believes you have violated or acted inconsistently with the terms of this Agreement. Termination of your access to the platform may be effected without prior notice. TaiPOS will not be liable to you for termination of your access to the platform.
        We reserve the rights to remove or modify any Content submitted for any reason without explanation. Requests for Content to be removed or modified will be undertaken only at our discretion. We reserve the right to take action against any account with the Service at any time.


        <br /><br />
        <h4>Relationship</h4>
        <br />
        Your use of the platform does not create, and nothing contained in this Agreement will be deemed to establish, an employment, agency, franchise, joint venture or partnership relationship between you and TaiPOS. Use of the platform does not provide you with the authority to enter into any agreements for or on behalf of TaiPOS. Moreover, use of the platform does not grant you the authority, either express or implied, to incur obligations or liability on behalf of TaiPOS. By using the platform, you agree that no attempts to subject TaiPOS to any such obligations or liability will be made.

        <br /><br />
        <h4>Service Charge and Usage</h4>
        <br />
        We offer three months of free use of the platform to our new customers. For customers getting our services for more than three months, we offer a fair monthly service charge as explained on Pricing .


        <br /><br />
        <h4>Other Agreements</h4>
        <br />
        This Agreement shall be subject to any other agreements you have entered into with TaiPOS. If any such agreements conflict with the terms of the instant Agreement, the other agreements shall control.

        <br /><br />
        <h4>Additional Terms</h4>
        <br />
        Some sections or pages on the platform may contain separate terms and conditions of use, which are in addition to the terms and conditions of this Agreement. Should there be a conflict, the additional terms and conditions will govern for those sections or pages.


        <br /><br />
        <h4>Severability</h4>
        <br />
        If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall be enforced to the fullest extent possible, and the remaining provisions of the Agreement shall remain in full force and effect.

        <br /><br />
        <h4>Modification</h4>
        <br />
        TaiPOS reserves the right to modify this Agreement at any time, with or without notice to you. Thus, you should check the Agreement periodically for changes. You agree that TaiPOS will not be liable to you for any modifications to the Agreement.
        <br /><br />
        If you do not agree with these terms, please do not register or use this platform. If you wish to close your account(s), please contact us via;
        <br />
        Email: salesupport@waydata.co.tz
        <br />
        Call: +255 769 003 815




        <br /><br />
      </div>
    </Grid>

  );
};

export default Policy;
