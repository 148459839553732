import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { useDispatch, useSelector } from "react-redux";
import { addResource, updateResource,getResources } from "../../app/resourcesSlice";
import { getActions, getComponents } from "../../app/commonSlice";

const itemsTableHead = [
  { label: "Name", name: "name" },
  { label: "Action", name: "action" },
  { label: "Component", name: "component" },
  { label: "Status", name: "status" },
  "action",
];


const Resources = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);  
  let [hideSaveButton, setHideSaveButton] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [itemDataList, setItemDataList] = useState([]);
  const [componentsDataList, setComponentsDataList] = useState([]);
  const [actionsDataList, setActionsDataList] = useState([]);

  

  const dispatch = useDispatch();
  
  const { error, status, list } = useSelector(
    ({ resources }) => resources.get
  );

  const { error:postError, status:postStatus } = useSelector(
    ({ resources }) => resources.post
  );

  const { error:putError, status:putStatus } = useSelector(
    ({ resources }) => resources.put
  );

  const { get:getActionsData } = useSelector(
    ({ common }) => common.actions
  );

  const { get:getComponentsData } = useSelector(
    ({ common }) => common.components
  );

 

  const handleRequestClose = () => {
    setFormValues({});
    setOpenForm(false);
    setOpenDetail(false);  
    setEdit(false);
  };

  const viewItem = (item) => {
    if (item) {

      setViewDetailsSelected(true)

      //setEdit(true);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const editItem = (item) => {
    if (item) {
      setEdit(true);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };


  const formOpen = () => {
    return (
      <>
        <div className="row">
          {/* <div className="col-sm-12 col-md-12 col-12">
            <h3>Seller Details</h3>
          </div> */}
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="name"
              size="small"
              label="Resource Name"
              value={formValues.name}
              inputType="text"
              disabled={edit?true:false}
              onChange={handleChange}
            />
          </div>
          
{actionsDataList.length ? (
        <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="action"
            size="small"
            inputType="select"
            value={formValues.action}
            defaultValue={actionsDataList[0]}
            label="Action"
            listLabel="Action"
            listValue="action"
            list={actionsDataList}
            onChange={handleChange}
          />
        </div>
      ) : (
        ""
      )}

        

{componentsDataList.length ? (
        <div className="col-sm-12 col-md-6 col-6">
          <Inputs
            name="component"
            size="small"
            inputType="select"
            value={formValues.component}
            defaultValue={componentsDataList[0]}
            label="Component"
            listLabel="Component"
            listValue="component"
            list={componentsDataList}
            onChange={handleChange}
          />
        </div>
      ) : (
        ""
      )}
          
        </div>
      </>
    );
  };


  const dialogOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <span>Name</span>&nbsp;&nbsp;
            <span>{selectedItem?.name}</span>           
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Action</span>&nbsp;&nbsp;
            <span>{selectedItem?.action}</span> 
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Component</span>&nbsp;&nbsp;
            <span>{selectedItem?.component}</span> 
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Status</span>&nbsp;&nbsp;
            <span>{selectedItem?.status}</span>            
          </div>
         
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    //alert(JSON.stringify(event.target))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    if(edit){
      let newItem = {
        "id": selectedItem?.id,
        "name": formValues?.name,
        "action": formValues?.action,
        "component": formValues?.component,
        "status": formValues.status ? formValues.status: "A",
      }
      dispatch(updateResource(newItem));
    }else{
      let newItem = {
        "name": formValues?.name,
        "action": formValues?.action,
        "component": formValues?.component,
        "status": formValues.status ? formValues.status: "A",
      }
      dispatch(addResource(newItem));
    }    
    
  };

  

  useEffect(() => {
    dispatch(getResources());  
    dispatch(getActions());    
    dispatch(getComponents());    
  }, [dispatch]);
  

  useEffect(() => {
    if(postStatus === "loading" && !edit){
      setHideSaveButton(false);
    }else if(postStatus === "idle"){
      dispatch(getResources());   
      setHideSaveButton(false);
    }
  }, [postStatus]);

  useEffect(() => {
    if(putStatus === "loading" && edit){
      setHideSaveButton(false);
    }else if(putStatus === "idle"){
      dispatch(getResources());   
      setHideSaveButton(false);
    }

  }, [putStatus]);


  useEffect(() => {
    if(status === "loading"){

    }else if(status === "idle"){
      setItemDataList(list);
    }

  }, [status,list]);

  

  useEffect(() => {
    if(getActionsData.status === "loading"){

    }else if(getActionsData.status === "idle"){
      let actionsArray = [];
      for (let index = 0; index < getActionsData.list.length; index++) {
        actionsArray.push({value:getActionsData.list[index]?.name,label:getActionsData.list[index]?.name})        
      }
      setActionsDataList(actionsArray);
    }
  }, [getActionsData]);

  useEffect(() => {
    if(getComponentsData.status === "loading"){

    }else if(getComponentsData.status === "idle"){
      let componentsArray = [];
      for (let index = 0; index < getComponentsData.list.length; index++) {
        componentsArray.push({value:getComponentsData.list[index]?.name,label:getComponentsData.list[index]?.name})        
      }
      setComponentsDataList(componentsArray);
    }
  }, [getComponentsData]);

 

  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Available Resources</h2>
         &nbsp;
         <div style={{alignContent:"right"}}>
        <Inputs
          color="success"
          className={`bx-burst-hover`}
          size="small"
          inputType="button"
          type="submit"
          text={"New Resource"}
          onClick={() => setOpenForm(true)}
        />
        &nbsp;
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {itemDataList.length>0 ? (
                <Table
                  columns={itemsTableHead}
                  data={itemDataList}
                  actions={[
                    {
                      action: "Details",
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Update",
                      onClick: (item) => {
                        editItem(item);
                      },
                    }
                  ]}
                  downloadFileName={"Available Resources"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <Detaildialog
        title={"Resource Details"}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={!edit ? "Save" : "Update"}
        content={() => dialogOpen()}
        
      />

      <MyDialog
        title={"Update Resource Details"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={!edit ? "Save" : "Update"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
      />

    </div>
  );
};

export default Resources;
