import React, { useState,useEffect,useCallback,CSSProperties, useRef } from "react";
import Inputs from "../../components/inputs/Inputs";
import logo from "../../assets/images/taiPOS.png";
import "./login.css";
import Footer from "../../components/footer/Footer";
import { userLogin } from "../../app/authSlice";
import { resetMyPassword } from "../../app/userSlice";
import { useSelector,useDispatch } from "react-redux";
import { loginSchema, resetPasswordSchema } from "../../common/validators";
import validate from "validate.js";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";


const Login = () => {
  let [loadingSign, setLoadingSign] = useState(false);
  let [loadingForgot, setLoadingForgot] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showResetButton, setShowResetButton] = useState(true);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {auth} = useSelector((state) => state.auth);  
  const { error: passwordError, status: passwordStatus } = useSelector(
    ({ users }) => users.password
  );

  const inputRef = useRef(null);

  const backToRegister = (e) => {
    e.preventDefault();
    // window.location.replace("/register")
    navigate("/register");
  }

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  useEffect(()=>{
    inputRef.current.focus();
  },[])

  useEffect(() => {
    //alert(JSON.stringify(auth));
    if(auth?.data){
      localStorage.setItem('userData',JSON.stringify(auth?.data))
      setLoadingSign(false)
    }
  }, [auth]);

  useEffect(() => {
    //alert(JSON.stringify(auth));
    if(passwordStatus === 'loading'){
      setShowResetButton(false)
    }else
    if(passwordStatus === 'idle' && passwordError === null){
      setShowResetButton(true)
    }
    else
    if(passwordError){
      setShowResetButton(true)
    }
  }, [passwordStatus,passwordError]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    let validationErrors = validate(
      formValues,
      resetPasswordSchema
    );

    if(validationErrors){
      setErrors(validationErrors);      
    }else{
      //alert("Work in Progress")
      dispatch(resetMyPassword(formValues))
    }

  }

  const toHome = () => {
    navigate("/intro");
  }

  const submit = (e) => {
    setLoadingSign(true)
    e.preventDefault();
    let validationErrors = validate(
      formValues,
      loginSchema
    );


    if(validationErrors){
      setErrors(validationErrors);   
      setLoadingSign(false)   
    }else{
      dispatch(
        userLogin({
          email: formValues.username,
          password: formValues.password,
        })
      );
    }

  };



  
  //alert(localStorage.getItem('token'))
  return (
    <div className="login-page">
      
       
      <div className="row">
        <div className="col-12">
          <img onClick={(e) => {
                e.preventDefault();
                toHome()
              }} src={logo} alt="logo" height="80vh" />
          <div className="card" style={{backgroundColor:"#ffffff"}}>
            <h2 onClick={(e) => {
                e.preventDefault();
                toHome()
              }} className="page-header">TaiPOS</h2>
            <div
              className="card-body"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Inputs
                name="username"
                label="Username or Email"
                className="input-class"
                size="small"
                value={formValues.username}
                inputType="text"
                onChange={handleChange}
                error={errors["username"] ? true : false}
                helperText={errors["username"] ? errors.username[0] : null}
                inputRef={inputRef}
              />
              <br />
              <Inputs
                name="password"
                label="Password"
                className="input-class"
                size="small"
                value={formValues.password}
                inputType="password"
                onChange={handleChange}
                error={errors["password"] ? true : false}
                helperText={errors["password"] ? errors.password[0] : null}
              />
              <br />
              {/* <Buttons 
              name="LOGIN"
              type="submit"
              
              /> */}
              <div id="forgot">
                {showResetButton === true ?
                  <a style={{ float: "right",cursor:"pointer" }} onClick={(e) => handleResetPassword(e)}>
                  Reset My Password
                </a> : ""}              
              </div> 
              {
                loadingSign === false && (
                  <Inputs
                  color="primary"
                  size="medium"
                  inputType="button"
                  text="LOGIN"
                  type="submit"
                  onClick={(e) => submit(e)}
                  propBackgroundColor={"#367588"}
                />
                )
              }
              
              {
            (loadingSign === true || showResetButton === false) && (
              <div style={{textAlign:'center'}}>
            <ClipLoader
              color={"#367588"}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            </div>
            )}
            </div>
                       
          </div>
        </div>
      </div>
      <div id="register" style={{ float: "center" }}>
          Don't you have an account? Click here to &nbsp;
          <b id="styleRegister" style={{ cursor: "pointer", color:"#367588" }} onClick={(e) => backToRegister(e)}>
            Register
          </b>
      </div>
      <div style={{ marginTop: "9vh" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Login;
