import { adminServiceApi } from "../services";

export const getItemsList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("items", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getItemsSideEffectList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("items/side_effects", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const postItems = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("items/add",query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const postUploadedItems = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("items/upload",query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};


export const postSideEffects = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("items/side_effects/add",query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putItems = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("items/"+query?.item_code,query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putSideEffects = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("items/side_effects/"+query?.id,query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};