import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import { useDispatch, useSelector } from "react-redux";
import { creditRepaySchema, customerSchema, disposeSchema, hasAccess, orderSchema } from "../../common/validators";
import validate from "validate.js";
import { addOrder, getOrderItems, getOrders, updateOrder } from "../../app/ordersSlice";
import moment from "moment";
import { addCreditRepay, getCreditItems, getCredits } from "../../app/creditsSlice";
import { green } from "@mui/material/colors";


const itemsTableHead = [
  { label: "Date date", name: "selling_date" },
  { label: "Name", name: "customer_name" },
  { label: "Mobile", name: "customer_mobile" },
  { label: "Email", name: "customer_email" },
  { label: "Invoice number", name: "invoice_no" },
  { label: "Credit Amount", name: "credit_amount" },
  { label: "Unpaid Amount", name: "unpaid_amount" },
  { label: "Status", name: "status" },
  "action",
];



const isViewAccess = () => {
  return hasAccess('credits','view');
}

const isDetailsAccess = () => {
  return hasAccess('credits','details');
}

const isEditAccess = () => {
  return hasAccess('credits','edit');
}

const isAddAccess = () => {
  return hasAccess('credits','add');
}


const Credits = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openCancelForm, setOpenCancelForm] = useState(false);
  let [openStockForm, setOpenStockForm] = useState(false);
  let [openDisposeForm, setOpenDisposeForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  let [showSideEffect, setShowSideEffect] = useState(false);
  let [toEditSideEffect, setToEditSideEffect] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formValues, setFormValues] = useState({"pay_date": new Date()});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [itemDataList, setItemDataList] = useState([]);
  const [measuresDataList, setMeasuresDataList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);


  const dispatch = useDispatch();

  const { error, status, list } = useSelector(
    ({ credits }) => credits.get
  );

  const { error: postError, status: postStatus } = useSelector(
    ({ credits }) => credits.post
  );

  const { error: putError, status: putStatus } = useSelector(
    ({ credits }) => credits.put
  );

  const { error: errorItems, status: statusItems, list: listItems } = useSelector(
    ({ credits }) => credits.items
  );

  const { list: listRepayments } = useSelector(
    ({ credits }) => credits.repayments
  );

  const { status: postStatusRepayment, data: postDataRepayment } = useSelector(
    ({ credits }) => credits.postRepayments
  );


 


  const handleRequestClose = () => {
    setErrors([])
    setFormValues({});
    setOpenForm(false);
    setOpenCancelForm(false);
    setOpenDetail(false);
    setOpenStockForm(false);
    setOpenDisposeForm(false);
    setShowSideEffect(false);
    setEdit(false);
    setToEditSideEffect(false);
    setActiveTab(0);
    setSelectedOrders([]);
    setTotalPrice(0);
    setFormValues({})
  };

  const viewItem = (item) => {
    if (item) {
      dispatch(getCreditItems({invoiceNo:item?.invoice_no}))
      setViewDetailsSelected(true)

      setEdit(false);
      setOpenDisposeForm(false);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const deliverOrder = (item) => {
    if (item) {
      //setEdit(true);
      //setOpenDisposeForm(false);
      setHideSaveButton(false)
      setFormValues({pay_date: new Date()});
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };


 

  const formOpen = () => {
    return (
      <>
        <div className="row">
        <div className="col-sm-12 col-md-6 col-6">
            <span>Name</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_name}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Mobile</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_mobile}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Credit Amount</span>&nbsp;&nbsp;
            <span>{selectedItem?.credit_amount}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Remaining Amount</span>&nbsp;&nbsp;
            <span>{(selectedItem?.unpaid_amount)}</span>
          </div>

          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="amount"
              size="small"
              label="Amount"
              value={formValues.amount}
              inputType="number"
              onChange={handleChange}
              error={errors["amount"] ? true : false}
              helperText={errors["amount"] ? errors.amount[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="pay_date"
              size="small"
              label="Date"
              value={formValues.pay_date}
              inputType="date"
              onChange={(e) =>
                handleChange({ target: { name: "pay_date", value: e } })}
              error={errors["pay_date"] ? true : false}
              helperText={errors["pay_date"] ? errors.pay_date[0] : null}
            />
          </div>

        </div>
      </>
    );
  };

  

  const dialogOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <span>Name</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_name}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Mobile</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_mobile}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Email</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_email}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Credit Amount</span>&nbsp;&nbsp;
            <span>{selectedItem?.credit_amount}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Remaining Amount</span>&nbsp;&nbsp;
            <span style={{color:'red'}}>{(selectedItem?.unpaid_amount)}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Selling Date</span>&nbsp;&nbsp;
            <span>{moment(selectedItem?.selling_date).format("YYYY-MM-DD")}</span>
          </div>
          <br /> <br />
          <div className="col-sm-12 col-md-12 col-12">
            <h3>ITEMS</h3>
          </div>
          <div className="col-sm-12 col-md-12 col-12">
            <table>
              <thead>
                <tr>
                  <th>Item Code</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {listItems.map((item) => (
                  <tr key={item}>
                    <td>{item.item_code}</td>
                    <td>{item.item_description}</td>
                    <td>{item.qty}</td>
                    <td>{item.rate}</td>
                    <td>{item.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
            <div className="col-sm-12 col-md-12 col-12" style={{textAlign:'right'}}>
              TOTAL <b style={{color:'red'}}>{selectedItem?.credit_amount}</b>
            </div>

          <br /> <br />
          <div className="col-sm-12 col-md-12 col-12">
            <h3>REPAYMENTS</h3>
          </div>
          <div className="col-sm-12 col-md-12 col-12">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Invoice</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {listRepayments.map((item) => (
                  <tr key={item}>
                    <td>{moment(item.pay_date).format("YYYY-MM-DD")}</td>
                    <td>{item.invoice_no}</td>
                    <td>{item.amount}</td>
                    <td>{item.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
            <div className="col-sm-12 col-md-12 col-12" style={{textAlign:'right'}}>
              TOTAL <b style={{color:'green'}}>{selectedItem?.payment_history}</b>
            </div>

        </div>
      </>
    );
  };


  const handleChange = (event) => {   
    //alert(JSON.stringify(event.target.value))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    //alert(JSON.stringify(formValues));
    
      let validationErrors = validate(
        formValues,
        creditRepaySchema
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{
        let newRepayment = {
          "invoice_no": selectedItem.invoice_no,
          "amount": formValues?.amount,
          "pay_date": formValues?.pay_date,
          "remarks": formValues?.remarks
        }
        dispatch(addCreditRepay(newRepayment));
      }

  };


  useEffect(() => {
    dispatch(getCredits());
  }, [dispatch]);

  useEffect(() => {
    if (viewDetailsSelected === true && selectedItem) {
     // dispatch(getStockHistory({ item_code: selectedItem?.item_code }))
    }
  }, [viewDetailsSelected, selectedItem]);


  useEffect(() => {
    if (postStatusRepayment === "loading") {
      setHideSaveButton(true);
    } else if (postStatusRepayment === "idle") {
      dispatch(getCredits());
      setHideSaveButton(false);
      setOpenForm(false);
    }
  }, [postStatusRepayment]);

 


  useEffect(() => {
    if (putStatus === "loading" && edit) {
      setHideSaveButton(true);
    } else if (putStatus === "idle") {
      dispatch(getCredits());
      setHideSaveButton(false);
      setOpenForm(false);
      setOpenCancelForm(false);
    }

  }, [putStatus]);

  useEffect(() => {
    if (status === "loading") {

    } else if (status === "idle" ) {
      let newList = [];
      //newList = list;
      for (let index = 0; index < list.length; index++) {
        let indObject = list[index];
        indObject = {
          ...indObject,
          'selling_date':moment(list[index]['selling_date']).format("YYYY-MM-DD"),
          'credit_amount':Number(list[index]['credit_amount']).toLocaleString(),
          'unpaid_amount':Number(list[index]['unpaid_amount']).toLocaleString(),
        }
        newList.push(indObject)     
      }
      setItemDataList(newList);
    }

  }, [status, list]);




  useEffect(() => {
    if (statusItems === "loading") {

    } else if (statusItems === "idle") {
      let measureArray = [];
      for (let index = 0; index < listItems.length; index++) {
        measureArray.push({ value: listItems[index], label: listItems[index]?.item_description })
      }
      setMeasuresDataList(measureArray);
    }
  }, [statusItems, listItems]);


  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Sales on Credit</h2>
        
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card" style={{ backgroundColor: "#e0e0e0" }}>
            <div className="card-body">
              {itemDataList.length > 0 ? (
                <Table
                  columns={itemsTableHead}
                  data={itemDataList}
                  actions={[
                    {
                      action: "Details",
                      renderCheck: isViewAccess,
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Customer Repay",
                      renderCheck: (item) => {
                        if(item.status === 'active' && isEditAccess){
                          return true
                        }                        
                      },
                      onClick: (item) => {
                        //editItem(item);
                        if (item.status === 'active') {
                          //handleDeliverOrder(item)
                          deliverOrder(item)
                        }else{

                        }
                        
                      },
                    }
                  ]}
                  downloadFileName={"Customer Credit Sales"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={"Credit Sale Details"}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={"Update"}
        content={() => dialogOpen()}
        hideSaveButton={true}
        propBackgroundColor="#367588"
      />

      <MyDialog
        title={"Customer Repay"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={"Save Repayment"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor="#367588"
      />
    </div>
  );
};

export default Credits;
