import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSalesReport, getExpensesReport, getDisposingReport, getStockingReport, getExpiringReport, getStockValueReport, getActiveCreditsReport, getActiveOrdersReport, getMostPurchasesReport, getNearToFInishReport, getSalesTodayTotalReport, getProfitTodayTotalReport, getSalesTodayTotalReportWithdraw, getProfitSummary, getSubscriptionBills, getFloatCashBalance } from "../api/reportsAPI";


export const salesReport = createAsyncThunk("reports/salesReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getSalesReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const expensesReport = createAsyncThunk("reports/expensesReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getExpensesReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const disposingReport = createAsyncThunk("reports/disposingReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getDisposingReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const stockingReport = createAsyncThunk("reports/stockingReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getStockingReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const expiringReport = createAsyncThunk("reports/expiringReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getExpiringReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const stockValueReport = createAsyncThunk("reports/stockValueReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getStockValueReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const profitReport = createAsyncThunk("reports/profitReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getProfitSummary(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const activeCreditsReport = createAsyncThunk("reports/activeCreditsReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getActiveCreditsReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const activeOrdersReport = createAsyncThunk("reports/activeOrdersReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getActiveOrdersReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const mostPurchasesReport = createAsyncThunk("reports/mostPurchasesReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getMostPurchasesReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const nearToFInishReport = createAsyncThunk("reports/nearToFInishReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getNearToFInishReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const salesTodayTotalReport = createAsyncThunk("reports/salesTodayTotalReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getSalesTodayTotalReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const salesTodayTotalReportWithdraw = createAsyncThunk("reports/salesTodayTotalReportWithdraw", async (payload, { rejectWithValue }) => {
  try {
    const response = await getSalesTodayTotalReportWithdraw(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const profitTodayTotalReport = createAsyncThunk("reports/profitTodayTotalReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await getProfitTodayTotalReport(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const subscriptionBills = createAsyncThunk("invoices/subscriptionBills", async (payload, { rejectWithValue }) => {
  try {
    const response = await getSubscriptionBills(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const floatCashBalance = createAsyncThunk("reports/floatCashBalance", async (payload, { rejectWithValue }) => {
  try {
    const response = await getFloatCashBalance(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const reportsSlice = createSlice({
  name: "common",
  initialState: {
    sales: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    cashFloats: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    expenses: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    disposing: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    stocking: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    expiring: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    profit: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    bills: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    dashboard: {
      stockValue: {
        status: null,
        error: null,
        list: [],
      },
      activeCredits: {
        status: null,
        error: null,
        list: [],
      },
      activeOrders: {
        status: null,
        error: null,
        list: [],
      },
      mostPurchased: {
        status: null,
        error: null,
        list: [],
      },
      leastInStock: {
        status: null,
        error: null,
        list: [],
      },
      salesToday: {
        status: null,
        error: null,
        list: [],
      },
      salesTodayWithdraw: {
        status: null,
        error: null,
        list: [],
      },      
      profitToday: {
        status: null,
        error: null,
        list: [],
      },
    },
  },

  extraReducers: {
    //sales
    [salesReport.pending]: (state) => {
      state.sales.get.status = "loading";
    },
    [salesReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.sales.get.list = data;
        state.sales.get.error = null;
      }else{
        state.sales.get.error = { message: `${status} - An error occurred` };
      }
      state.sales.get.status = 'idle';
    },
     //bills
    [subscriptionBills.pending]: (state) => {
      state.bills.get.status = "loading";
    },
    [subscriptionBills.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.bills.get.list = data;
        state.bills.get.error = null;
      }else{
        state.bills.get.error = { message: `${status} - An error occurred` };
      }
      state.bills.get.status = 'idle';
    },
    //floatCashBalance
    [floatCashBalance.pending]: (state) => {
      state.cashFloats.get.status = "loading";
    },
    [floatCashBalance.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.cashFloats.get.list = data;
        state.cashFloats.get.error = null;
      }else{
        state.cashFloats.get.error = { message: `${status} - An error occurred` };
      }
      state.cashFloats.get.status = 'idle';
    },

    //expenses
    [expensesReport.pending]: (state) => {
      state.expenses.get.status = "loading";
    },
    [expensesReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.expenses.get.list = data;
        state.expenses.get.error = null;
      }else{
        state.expenses.get.error = { message: `${status} - An error occurred` };
      }
      state.expenses.get.status = 'idle';
    },
    //disposing
    [disposingReport.pending]: (state) => {
      state.disposing.get.status = "loading";
    },
    [disposingReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.disposing.get.list = data;
        state.disposing.get.error = null;
      }else{
        state.disposing.get.error = { message: `${status} - An error occurred` };
      }
      state.disposing.get.status = 'idle';
    },
    //components
    [stockingReport.pending]: (state) => {
      state.stocking.get.status = "loading";
    },
    [stockingReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.stocking.get.list = data;
        state.stocking.get.error = null;
      }else{
        state.stocking.get.error = { message: `${status} - An error occurred` };
      }
      state.stocking.get.status = 'idle';
    },
    //expiring
    [expiringReport.pending]: (state) => {
      state.expiring.get.status = "loading";
    },
    [expiringReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.expiring.get.list = data;
        state.expiring.get.error = null;
      }else{
        state.expiring.get.error = { message: `${status} - An error occurred` };
      }
      state.expiring.get.status = 'idle';
    },
    ///////////dashboard start
    [stockValueReport.pending]: (state) => {
      state.dashboard.stockValue.status = "loading";
    },
    [stockValueReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.dashboard.stockValue.list = data;
        state.dashboard.stockValue.error = null;
      }else{
        state.dashboard.stockValue.error = { message: `${status} - An error occurred` };
      }
      state.dashboard.stockValue.status = 'idle';
    },
    [profitReport.pending]: (state) => {
      state.profit.get.status = "loading";
    },
    [profitReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.profit.get.list = data;
        state.profit.get.error = null;
      }else{
        state.profit.get.error = { message: `${status} - An error occurred` };
      }
      state.profit.get.status = 'idle';
    },
    [activeCreditsReport.pending]: (state) => {
      state.dashboard.activeCredits.status = "loading";
    },
    [activeCreditsReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.dashboard.activeCredits.list = data;
        state.dashboard.activeCredits.error = null;
      }else{
        state.dashboard.activeCredits.error = { message: `${status} - An error occurred` };
      }
      state.dashboard.activeCredits.status = 'idle';
    },
    [activeOrdersReport.pending]: (state) => {
      state.dashboard.activeOrders.status = "loading";
    },
    [activeOrdersReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.dashboard.activeOrders.list = data;
        state.dashboard.activeOrders.error = null;
      }else{
        state.dashboard.activeOrders.error = { message: `${status} - An error occurred` };
      }
      state.dashboard.activeOrders.status = 'idle';
    },
    [mostPurchasesReport.pending]: (state) => {
      state.dashboard.mostPurchased.status = "loading";
    },
    [mostPurchasesReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.dashboard.mostPurchased.list = data;
        state.dashboard.mostPurchased.error = null;
      }else{
        state.dashboard.mostPurchased.error = { message: `${status} - An error occurred` };
      }
      state.dashboard.mostPurchased.status = 'idle';
    },
    [nearToFInishReport.pending]: (state) => {
      state.dashboard.leastInStock.status = "loading";
    },
    [nearToFInishReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.dashboard.leastInStock.list = data;
        state.dashboard.leastInStock.error = null;
      }else{
        state.dashboard.leastInStock.error = { message: `${status} - An error occurred` };
      }
      state.dashboard.leastInStock.status = 'idle';
    },
    [salesTodayTotalReport.pending]: (state) => {
      state.dashboard.salesToday.status = "loading";
    },
    [salesTodayTotalReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.dashboard.salesToday.list = data;
        state.dashboard.salesToday.error = null;
      }else{
        state.dashboard.salesToday.error = { message: `${status} - An error occurred` };
      }
      state.dashboard.salesToday.status = 'idle';
    },
    [salesTodayTotalReportWithdraw.pending]: (state) => {
      state.dashboard.salesTodayWithdraw.status = "loading";
    },
    [salesTodayTotalReportWithdraw.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.dashboard.salesTodayWithdraw.list = data;
        state.dashboard.salesTodayWithdraw.error = null;
      }else{
        state.dashboard.salesTodayWithdraw.error = { message: `${status} - An error occurred` };
      }
      state.dashboard.salesTodayWithdraw.status = 'idle';
    },

    
    [profitTodayTotalReport.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.dashboard.profitToday.list = data;
        state.dashboard.profitToday.error = null;
      }else{
        state.dashboard.profitToday.error = { message: `${status} - An error occurred` };
      }
      state.dashboard.profitToday.status = 'idle';
    },
     ///////////dashboard end
  },
});

export default reportsSlice.reducer;
