import { adminServiceApi } from "../services";

export const getWorkflowList = async (query = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("workflow", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const registerWorkflow = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("workflow/add", payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putWorkflow = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("workflow/"+payload?.id, payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};