import React, { useState,useEffect,useCallback } from "react";
import Inputs from "../../components/inputs/Inputs";
import logo from "../../assets/images/taiPOS.png";
import "./login.css";
import Footer from "../../components/footer/Footer";

import { userLogin } from "../../app/authSlice";
import { confirmUser, resendUserCode, resetMyPassword } from "../../app/userSlice";
import { useSelector,useDispatch } from "react-redux";
import Particles from 'react-particles';
import { loadFull } from "tsparticles";

import dawa from "../../assets/images/syrups.svg";
import tablet from "../../assets/images/tablet.svg";
import syringe from "../../assets/images/syringe.svg";
import bottle from "../../assets/images/bottle.svg";
import { confirmUserSchema, loginSchema, registerSchema, resendCodeSchema, resetPasswordSchema } from "../../common/validators";
import validate from "validate.js";
import { registerResource } from "../../api/resourcesAPI";
import { useNavigate } from "react-router-dom";

const Confirm = () => {
  // const auth = useSelector((state) => state.auth);
  const [errors, setErrors] = useState([]);
  const [showResetButton, setShowResetButton] = useState(true);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {auth} = useSelector((state) => state.auth);  
  const { error, status } = useSelector(
    ({ users }) => users.put
  );

  const { error:otpError, status:otpStatus } = useSelector(
    ({ users }) => users.otp
  );


  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  useEffect(() => {
    //alert(JSON.stringify(auth));
    if(auth?.data){
      localStorage.setItem('userData',JSON.stringify(auth?.data))
    }
  }, [auth]);

  useEffect(() => {
    //alert(JSON.stringify(auth));
    if(status === 'loading'){
      setShowResetButton(false)
    }else
    if(status === 'idle' && error === null){
      setShowResetButton(true)
      // window.location.replace("/login")
      navigate("/login");
    }
    else
    if(error){
      setShowResetButton(true)
    }
  }, [status,error]);

  useEffect(() => {
    if(otpStatus === 'loading'){
      setShowResetButton(false)
    }else
    if(otpStatus === 'idle' && otpError === null){
      setShowResetButton(true)
    }
    else
    if(otpStatus){
      setShowResetButton(true)
    }
  }, [otpError,otpStatus]);

  const backToLogin = (e) => {
    e.preventDefault();
    // window.location.replace("/login")
    navigate("/login");
  }

  const submit = (e) => {
    e.preventDefault();
    let validationErrors = validate(
      formValues,
      confirmUserSchema
    );


    if(validationErrors){
      setErrors(validationErrors);      
    }else{
      dispatch(
        confirmUser(formValues)
      );
    }

  };

  const handleResendCode = (e) => {
    e.preventDefault();
    let validationErrors = validate(
      formValues,
      resendCodeSchema
    );


    if(validationErrors){
      setErrors(validationErrors);      
    }else{
      dispatch(
        resendUserCode(formValues)
      );
    }

  };




  //alert(localStorage.getItem('token'))
  return (
    <div className="register-page">
      <div style={{zIndex:"5 !íportant"}}>
        </div>
       
      <div className="row">
        <div className="col-12">
          <img src={logo} alt="logo" height="80vh" />
          <div className="card" style={{backgroundColor:"#ffffff"}}>
            <h2 className="page-header">TaiPOS</h2>
            <div
              className="card-body"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Inputs
                name="email"
                label="Email Address"
                className="input-class"
                size="small"
                value={formValues.email}
                inputType="text"
                onChange={handleChange}
                error={errors["email"] ? true : false}
                helperText={errors["email"] ? errors.email[0] : null}
              />
              <br />
              <Inputs
                name="otp"
                label="One Time Code"
                className="input-class"
                size="small"
                value={formValues.otp}
                inputType="text"
                onChange={handleChange}
                error={errors["otp"] ? true : false}
                helperText={errors["otp"] ? errors.otp[0] : null}
              />
              <br />
             {showResetButton && <Inputs
                color="primary"
                size="medium"
                inputType="button"
                text="CONFIRM"
                type="submit"
                onClick={(e) => submit(e)}
                propBackgroundColor={"#367588"}
              />
             }
              <br />
              {showResetButton && <Inputs
                color="primary"
                size="medium"
                inputType="button"
                text="RESEND CODE"
                type="submit"
                onClick={(e) => handleResendCode(e)}
                propBackgroundColor={"#883636"}
              />
              }
            </div>
                       
          </div>
        </div>
      </div>
      <div id="register" style={{ float: "center" }}>
          Already have an account? Click here to &nbsp;
          <b id="styleRegister" style={{ cursor: "pointer", color:"#367588"}} onClick={(e) => backToLogin(e)}>
            Login
          </b>
      </div>
      <div style={{ marginTop: "9vh" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Confirm;
