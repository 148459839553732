import {
    ClickAwayListener,
    IconButton,
    MenuItem,
    MenuList,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";

  const Sections = ({
    data,
    columns,
    downloadFileName,
    dateFrom,
    dateTo,
    actions,
  }) => {

    const [dataShow, setDataShow] = useState(false);

    useEffect(() => {
        if(data){
            setDataShow(true)
        }        
    },[data])

    return (
    <div className="ez-table-wrapper" style={{backgroundColor:"#ffffff",borderRadius:"5px"}}>
    <table>
      {columns ? (
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th className="trow" key={index}>
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
      ) : (
        ""
      )}

{dataShow ? (
            <tbody>
              {data
                .map((item, index) => (
                  <tr key={`tr-item-${index}`}>
                    {columns.map((col, _index) => (
                      <td
                        key={`td-row-${index}-col-${_index}`}
                        className={
                          "text-align-" +
                          (col.hasOwnProperty("options")
                            ? col.options.align ?? "left"
                            : "left")
                        }
                      >
                        {item[col.name] ?? ""}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          ) : null}
      </table>
      </div>
    );
  }
  export default Sections;