import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getTaxList, putTax, registerTax } from "../api/taxAPI";
import { getUserList,registerUser, putUser, resetPassword, selfRegisterUser, confirmUserRegistration, resendCode } from "../api/userAPI";

export const getTaxes = createAsyncThunk("tax/getTaxes", async (payload, { rejectWithValue }) => {
  try {
    const response = await getTaxList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const addTax = createAsyncThunk("tax/addTax", async (payload, { rejectWithValue }) => {
  try {
    const response = await registerTax(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const updateTax = createAsyncThunk("tax/updateTax", async (payload, { rejectWithValue }) => {
  try {
    const response = await putTax(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});



export const taxSlice = createSlice({
  name: "tax",
  initialState: {
    payload: {
      status: null,
      error: null,
      data: null,
    },
    post: {
      status: null,
      error: null,
      data: null,
    },
    put: {
      status: null,
      error: null,
      data: null,
    },
    get: {
      status: null,
      error: null,
      list: [],
    },
  },

  extraReducers: {
    [getTaxes.pending]: (state) => {
      state.get.status = "loading";
    },
    [getTaxes.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get tax!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.get.status = 'idle';
    },
    [getTaxes.rejected]: (state, action) => {
      state.get.status = "idle";
      state.get.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get tax!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [addTax.pending]: (state) => {
      state.post.status = "loading";
    },
    [addTax.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Tax saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.post.status = "idle";
        state.post.error = null;
      }else{
        toast.warn("Couldn't add tax!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.post.error = "Failed";
      }

      state.post.data = data;
    },
    [addTax.rejected]: (state, action) => {
      //state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add tax!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateTax.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateTax.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Tax saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.put.status = "idle";
      }else{
        state.put.error = "Failed";
        toast.warn("Couldn't update tax!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.data = data;
    },
    [updateTax.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add tax!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

export default taxSlice.reducer;
