import React, { useEffect,useCallback,useState } from "react";
import statusCards from "../assets/JsonData/status-card-data.json";
import Cards from "../components/cards/Cards";

import Chart from "react-apexcharts";
import Table from "../components/table/Table";
import { Link,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Particles from 'react-particles';
import { loadFull } from "tsparticles";
import shopping from "../assets/images/shopping.svg";
import boxProduct from "../assets/images/boxProduct.svg";
import dawa from "../assets/images/syrups.svg";
import tablet from "../assets/images/tablet.svg";
import syringe from "../assets/images/syringe.svg";
import bottle from "../assets/images/bottle.svg";
import { hasAccess, instCat } from "../common/validators";
import { activeCreditsReport, activeOrdersReport, mostPurchasesReport, nearToFInishReport, profitTodayTotalReport, salesTodayTotalReport, salesTodayTotalReportWithdraw, stockValueReport, subscriptionBills, floatCashBalance } from "../app/reportsSlice";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import BillReminder from "./BillReminder";
import MyDialog from "../components/dialog/Dialog";

const availableMenu = [
  {
    icon:"bx bx-dollar-circle",
    subject:"POS",
    url: "/pos"
  },
  {
    icon:"bx bx-receipt",
    subject:"ACCOUNTS",
    url:"/accounts"
  },
  {
    icon:"bx bx-shopping-bag",
    subject:"SALES",
    url:"/sales"
  },
  {
    icon:"bx bx-cart",
    subject:"ITEMS",
    url:"/items"
  },
  {
    icon:"bx bx-user",
    subject:"STAFF",
    url:"/staff"
  },
  {
    icon:"bx bx-chart",
    subject:"REPORTS",
    url:"/reports"
  },
  {
    icon:"bx bx-group",
    subject:"CUSTOMERS",
    url:"/customers"
  },
  {
    icon:"bx bx-help-circle",
    subject:"ORDERS",
    url:"/orders"
  },
  {
    icon:"bx bx-credit-card",
    subject:"CREDITS",
    url:"/credits"
  },
  {
    icon:"bx bx-credit-card",
    subject:"EXPENSES",
    url:"/expenses"
  },
  {
    icon:"bx bx-home",
    subject:"BUSINESSES",
    url:"/businesses"
  },
  {
    icon:"bx bx-barcode",
    subject:"BARCODES",
    url:"/barcodes"
  },
]



const Dashboard = () => {
  const [userMenu, setUserMenu] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [labelsData, setLabelsData] = useState([]);
  const [oPenBillDialog, setOPenBillDialog] = useState(false);
  const [currentFloatBalance, setCurrentFloatBalance] = useState(0);
  const [currentCashBalance, setCurrentCashBalance] = useState(0);
  const [openFloatBalance, setOpenFloatBalance] = useState(0);
  const [openCashBalance, setOpenCashBalance] = useState(0);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const stats = useSelector((state) => state.common.stats);
  const selectedTheme = useSelector((state) => state.theme.mode);
  const [totalStock, setTotalStock] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);

  
  const { error: billsError, status: billsStatus, list: billsList } = useSelector(
    ({ reports }) => reports.bills.get
  );

  const { error: cashFloatsError, status: cashFloatsStatus, list: cashFloatsList } = useSelector(
    ({ reports }) => reports.cashFloats.get
  );
  
  const { error: ordersError, status: ordersStatus, list: ordersList } = useSelector(
    ({ reports }) => reports.dashboard.activeOrders
  );

  const { error: creditsError, status: creditsStatus, list: creditsList } = useSelector(
    ({ reports }) => reports.dashboard.activeCredits
  );

  const { error: stockValueError, status: stockValueStatus, list: stockValueList } = useSelector(
    ({ reports }) => reports.dashboard.stockValue
  );

  const { error: mostPurchasedError, status: mostPurchasedStatus, list: mostPurchasedList } = useSelector(
    ({ reports }) => reports.dashboard.mostPurchased
  );

  const { error: leastInStockError, status: leastInStockStatus, list: leastInStockList } = useSelector(
    ({ reports }) => reports.dashboard.leastInStock
  );

  const { error: salesTodayError, status: salesTodayStatus, list: salesTodayList } = useSelector(
    ({ reports }) => reports.dashboard.salesToday
  );

  const { error: salesTodayErrorWithdraw, status: salesTodayStatusWithdraw, list: salesTodayListWithdraw } = useSelector(
    ({ reports }) => reports.dashboard.salesTodayWithdraw
  );

  const { error: profitTodayError, status: profitTodayStatus, list: profitTodayList } = useSelector(
    ({ reports }) => reports.dashboard.profitToday
  );

  const handleClickMenu = (url) => {
    //alert(url)
    navigate(url);
  }

  useEffect( async () => {
    let businessDate = new Date()
    //dispatch(getStats());
    if(!localStorage.getItem('token')){
      handleClickMenu('/intro')
    }else{
      dispatch(activeCreditsReport())
      dispatch(activeOrdersReport())
      dispatch(stockValueReport())
      dispatch(mostPurchasesReport())
      dispatch(nearToFInishReport())
      dispatch(salesTodayTotalReport())      
      dispatch(subscriptionBills())      
      dispatch(floatCashBalance({businessDate:moment(businessDate).format("YYYY-MM-DD")}))      
      dispatch(salesTodayTotalReportWithdraw())      
      dispatch(profitTodayTotalReport({businessDate:moment(businessDate).format("YYYY-MM-DD")}))      
    }
    
  }, [dispatch]);


  useEffect(() => {  
    if(stockValueList && stockValueList.length > 0){      
      setTotalStock(instCat() === 4 ? currentFloatBalance : stockValueList[0]["w_stock_value"])
    }
  },[stockValueList])

  useEffect(() => {  

    if(cashFloatsList && cashFloatsList.length > 0){  
      let _openCashBalance = 0;
      let _openFloatBalance = 0;
      let _currentCashBalance = 0;
      let _currentFloatBalance = 0;
      //alert(JSON.stringify(cashFloatsList))    
      //setTotalStock(cashFloatsList[0]["w_stock_value"])
      for (let index = 0; index < cashFloatsList.length; index++) {
        _openFloatBalance = _openFloatBalance + cashFloatsList[index]['device_balance'];
        _openCashBalance = _openCashBalance + cashFloatsList[index]['droo_balance'];
        _currentCashBalance = _currentCashBalance + cashFloatsList[index]['cash_balance'];
        _currentFloatBalance = _currentFloatBalance + cashFloatsList[index]['float_balance'];        
      }
      setCurrentFloatBalance(_currentFloatBalance)
      setCurrentCashBalance(_currentCashBalance)
      setOpenCashBalance(_openCashBalance)
      setOpenFloatBalance(_openFloatBalance)
    }
  },[cashFloatsList,cashFloatsStatus,cashFloatsError])


  useEffect(()=>{
    if(mostPurchasedList && mostPurchasedList.length > 0){
      let newSeries = [];
      let newLabels = [];
      for (let index = 0; index < mostPurchasedList.length; index++) {
        newSeries.push(mostPurchasedList[index]['amount'])
        newLabels.push(mostPurchasedList[index]['item_description'])        
      }
      
      setSeriesData(newSeries);
      setLabelsData(newLabels);
    }

  },[mostPurchasedList])

  const chartData = {
    series: seriesData,
    options: {
      chart: {
        type: 'pie',
      },
      labels: labelsData,
    },
  };
  

  useEffect(() => {  
   
    if(!profitTodayError && profitTodayStatus && profitTodayList && profitTodayList.length > 0 && profitTodayList[0]["stock_price"] != null){      
      setTotalProfit(profitTodayList[0]["sell_amount"] - profitTodayList[0]["stock_price"])
    }else if(!profitTodayError && profitTodayList && profitTodayList.length > 0 && profitTodayList[0]["stock_price"] == null){
      setTotalProfit(0)
    }
  },[profitTodayList,profitTodayStatus,profitTodayError])


  useEffect(()=>{
    if(billsList && billsList.length > 0){
      setOPenBillDialog(true)
    }else{
      setOPenBillDialog(false)
    }

  },[billsList]);

  const handleCloseBill = () => {
    setOPenBillDialog(false)
  }

  const dialogOpen = () => {
    return <BillReminder billData={oPenBillDialog === true ? billsList[0] : null}/>
  }

  const handleClick = (toPath=null) => {
    //event.preventDefault();
    if(toPath && toPath != null){
      navigate(toPath); // Redirect to the "props.toPath" route
    }    
  };

  return (
    <div>
    
      <div className="ez-main-card">
      {instCat() === 4 && (
         <div className="col-12  col-md-12 col-sm-12">
           <div className="row" style={{marginBottom:'20px'}}>  
            <div className="col-3 col-md-6 col-sm-6" key={0} onClick={{}} style={{backgroundColor:'#e1e1e16c', padding:'10px'}}>
              Open Cash: <b>{Number(openCashBalance).toLocaleString()}</b>
            </div>
            <div className="col-3 col-md-6 col-sm-6" key={0} onClick={{}} style={{padding:'10px'}}>
              Current Cash: <b>{Number(currentCashBalance).toLocaleString()}</b>
            </div>
            <div className="col-3 col-md-6 col-sm-6" key={0} onClick={{}} style={{backgroundColor:'#e1e1e16c', padding:'10px'}}>
              Open Float: <b>{Number(openFloatBalance).toLocaleString()}</b>
            </div>
            <div className="col-3 col-md-6 col-sm-6" key={0} onClick={{}} style={{padding:'10px'}}>
              Current Float: <b>{Number(currentFloatBalance).toLocaleString()}</b>
            </div>
           </div>
         <div className="row">   
          <div className="col-3 col-md-6 col-sm-6" key={0} onClick={{}} style={{cursor:"pointer"}}>
            <Cards
              type="normal"
              number={salesTodayList && salesTodayList.length > 0 ? salesTodayList[0]["amount"].toLocaleString(): 0}
              count={null}
              title={"Deposits Today"}
            />
          </div>
          <div className="col-3 col-md-6 col-sm-6" key={0} onClick={{}} style={{cursor:"pointer"}}>
            <Cards
              type="normal"
              number={salesTodayListWithdraw && salesTodayListWithdraw.length > 0 ? salesTodayListWithdraw[0]["amount"].toLocaleString(): 0}
              count={null}
              title={"Withdraws Today"}
            />
          </div>
          <div className="col-3 col-md-6 col-sm-6" key={0} onClick={{}} style={{cursor:"pointer"}}>
            <Cards
              type="normal"
              number={totalProfit.toLocaleString()}
              count={null}
              title={"Commissions Today"}
            />
          </div>
          <div className="col-3 col-md-6 col-sm-6" key={0} onClick={{}} style={{cursor:"pointer"}}>
            <Cards
              type="normal"
              number={totalStock.toLocaleString()}
              count={null}
              title={"Float Value"}
            />
          </div>

          <div className="col-6 col-md-6 col-sm-6" key={0} onClick={{}} style={{cursor:"pointer", backgroundColor:"#77777708"}}>
          <h4>Most Transaction</h4>
          <br/>
          
            <table>
              <thead>
                {hasAccess("staff",'view') === true ? 
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>amount</th>
              </tr> :
              <tr>
                <th>Item</th>
                <th>Qty</th>           
              </tr>
                }
                
              </thead>
              <tbody>
                {mostPurchasedList.map((item) => (
                  hasAccess("staff",'view') === true ? 
                   <tr key={item}>
                    <td>{item.item_description}</td>
                    <td>{item.qty.toLocaleString()}</td>
                    <td>{item.amount.toLocaleString()}</td>
                  </tr> :
                 
                 <tr key={item}>
                    <td>{item.item_description}</td>
                    <td>{item.qty.toLocaleString()}</td>
                  </tr>
                 
                ))}
              </tbody>
            </table>
        </div>
        <div className="col-6 col-md-6 col-sm-6" key={0} onClick={{}} style={{cursor:"pointer", backgroundColor:"#00000024"}}>
        <ReactApexChart options={chartData.options} series={chartData.series} type="donut" />
        </div>

         </div>
         </div>
      )}
      {instCat() != 4 && (
        <div className="col-12  col-md-12 col-sm-12">
        <div className="row">        
        <div className={hasAccess("staff",'view') === false ? "col-3 col-md-6 col-sm-6":"col-2 col-md-6 col-sm-6" } key={0} onClick={function(e){handleClick('/sales');}} style={{cursor:"pointer"}}>
          <Cards
            type="normal"
            number={salesTodayList && salesTodayList.length > 0 ? salesTodayList[0]["amount"].toLocaleString(): 0}
            count={null}
            title={"Sales Today"}
            toPath={"/sales"}
          />
        </div>
        {hasAccess("staff",'view') === true ?
        <div className={hasAccess("staff",'view') === false ? "col-3 col-md-6 col-sm-6":"col-2 col-md-6 col-sm-6" } key={0} onClick={function(e){handleClick('/sales');}} style={{cursor:"pointer"}}>         
          {hasAccess("staff",'view') === true &&
          <Cards
            type="normal"
            number={totalProfit.toLocaleString()}
            count={null}
            title={"Profit Today"}
          />
          }
        </div>:""
        }
        <div className={hasAccess("staff",'view') === false ? "col-3 col-md-6 col-sm-6":"col-2 col-md-6 col-sm-6" } key={0} onClick={function(e){handleClick('/orders');}} style={{cursor:"pointer"}}>
          <Cards
            type="normal"
            number={ordersList && ordersList.length > 0 ? ordersList[0]["amount"].toLocaleString(): 0}
            count={null}
            title={"Active Orders"}
            borderColor={"#36758882"}
            toPath={"/orders"}
          />
        </div>
        <div className={hasAccess("staff",'view') === false ? "col-3 col-md-6 col-sm-6":"col-2 col-md-6 col-sm-6" } key={0} onClick={function(e){handleClick('/credits');}} style={{cursor:"pointer"}}>
          <Cards
            type="normal"
            number={creditsList && creditsList.length > 0 ? creditsList[0]["amount"].toLocaleString(): 0}
            count={null}
            title={"Credits Unpaid"}
            borderColor={"#ff670087"}
            toPath={"/credits"}
          />
        </div>
        <div className={hasAccess("staff",'view') === false ? "col-3 col-md-6 col-sm-6":"col-2 col-md-6 col-sm-6" } key={0} onClick={{}} style={{cursor:"pointer"}}>
         {instCat() != 5 && hasAccess("staff",'view') === true &&
          <Cards
            type="normal"
            number={totalStock.toLocaleString()}
            count={null}
            title={"Stock Value"}
          />
          }
          {hasAccess("staff",'view') === false &&
          <Cards
            type="normal"
            number={salesTodayList && salesTodayList.length > 0 ? salesTodayList[0]["amount"].toLocaleString(): 0}
            count={null}
            title={"Sales Today"}
            toPath={"/sales"}
          />
          }
        </div>
        
        
        <div className="col-6 col-md-6 col-sm-6" key={0} onClick={function(e){handleClick('/sales');}} style={{cursor:"pointer", backgroundColor:"#77777708"}}>
          <h4>Most Purchased</h4>
          <br/>
          
            <table>
              <thead>
                {hasAccess("staff",'view') === true ? 
              <tr>
                {instCat() != 5 ? <th>Item</th> : <th>Service</th> }
                <th>Qty</th>
                <th>amount</th>
              </tr> :
              <tr>
                {instCat() != 5 ? <th>Item</th> : <th>Service</th> }
                <th>Qty</th>           
              </tr>
                }
                
              </thead>
              <tbody>
                {mostPurchasedList.map((item) => (
                  hasAccess("staff",'view') === true ? 
                   <tr key={item}>
                    <td>{item.item_description}</td>
                    <td>{item.qty.toLocaleString()}</td>
                    <td>{item.amount.toLocaleString()}</td>
                  </tr> :
                 
                 <tr key={item}>
                    <td>{item.item_description}</td>
                    <td>{item.qty.toLocaleString()}</td>
                  </tr>
                 
                ))}
              </tbody>
            </table>
        </div>
        {
          instCat() != 5 && (
            <div className="col-6 col-md-6 col-sm-6" key={0} onClick={function(e){handleClick('/items');}} style={{cursor:"pointer", backgroundColor:"#77777708"}}>
          <h4>Least In Stock</h4>
          <br/>
          
            <table>
              <thead>
              {
              hasAccess("staff",'view') === true ? 
                <tr>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>amount</th>
                </tr>:
                <tr>
                <th>Item</th>
                <th>Qty</th>
              </tr>
              }
              </thead>
              <tbody>
                {leastInStockList.map((item) => (
                  hasAccess("staff",'view') === true ? 
                  <tr key={item}>
                    <td>{item.item_description}</td>
                    <td>{item.balance_qty.toLocaleString()}</td>
                    <td>{item.stock_value.toLocaleString()}</td>
                  </tr>:
                  <tr key={item}>
                  <td>{item.item_description}</td>
                  <td>{item.balance_qty.toLocaleString()}</td>
                </tr>
                ))}
              </tbody>
            </table>
        </div>
          )
        }
        </div>
        </div>
      )}
       

      </div>
      <MyDialog
        title={"Subscription - Pending Bill"}
        width="md"
        open={oPenBillDialog}
        onClose={()=>handleCloseBill()}
        text={"Subscription - Pending Bill"}
        content={() => dialogOpen()}
        hideSaveButton={true}
        propBackgroundColor="#367588"
      />
    </div>
  );
};

export default Dashboard;
