import React, { useEffect, useState } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import  Autocomplete  from "@material-ui/lab/Autocomplete";
import ReloadIcon from "@material-ui/icons/Replay";


/**
 ************************** PROPS DOCUMENTATION *************************
 * setValues        ~ a useState function used to set the state on the state variable
 * values           ~ a useState variable which holds the state value
 * options          ~ an array of options to be displayed, an array of objects
 * reload           ~ a function which holds the logic to be executed when pressing the reload button when there's no data
 * label            ~ the label to be displayed on the autocomplete element
 * optionId         ~ the option value that gets selected, the value of the option
 * optionName       ~ the option name/text that displays on the individual option
 * itemStateValue   ~ the name of the input, which will hold data(optionId) that is selected
 * validateErrors   ~ 
 * 
 */

const AppAutoComplete = ({setValues, values, defaultValue = null, options, otherOption, reload, label, optionId, optionName, itemStateValue, validateErrors, handleChange = null, page = null}) => {

 const displayOption = (value1, value2, value3 ) => {
    value2 = value2 !=null? ` (${value2})` : value2
    value3 = value3 !=null? ` - ${value3}` : value3
   return value1 ? `${value1 ?? ""} ${value2 ??""} ${value3 ??""}` : ""
 }

 let required = true
 if(validateErrors == undefined){
   required = false
   validateErrors={}
 }

 return (
<>
    <Autocomplete
        id="auto-complete"
        options={options}
        defaultValue={optionName !=null ? {[optionName[0]]: defaultValue}: null}
        onChange={handleChange != null && page === 'pos' ? 
            (e,value) =>{
                e.preventDefault(); 
                // alert(itemStateValue)
                //alert(JSON.stringify(e?.target))
                // alert(value[optionId]?.item_code)
                //handleChange(e)
                if(value != null){
                    handleChange({target:{name:itemStateValue,type: "select", value: value[optionId]}})
                }                
            } : 
        (event, value) => {
            event.preventDefault();
            if(value!=null){
                if(value[optionId]){
                    setValues({
                    ...values,
                    [itemStateValue]: value[optionId]
                    })
                }
            }                   
        }}

        getOptionLabel={(option) => displayOption(option[optionName[0]], page === 'pos' ? option[optionName[1]?.item_code] : option[optionName[1]], option[optionName[2]])}
        renderOption={(props, option) => (
        <div {...props} style={{ fontSize: "12px" }}>
            {displayOption(props[optionName[0]], page === 'pos' ? props[optionName[1]?.item_code] : props[optionName[1]], props[optionName[2]])}
        </div>
        )}

        noOptionsText={
        <div style={{ display: "flex", flexDirection: "row" }}>
            <span style={{ width: '98%' }}>{'No options'}</span>
            <IconButton size="small"
            id="appSelect"
            aria-label="currency icon"
            onMouseDown={(e) => {
                e.preventDefault();
               if(reload!=undefined) reload();
            }}
            >
            <ReloadIcon className="app-button-icon" />
            </IconButton>
        </div>
        }

        renderInput={(params) => (
        <TextField
            {...params}
            label={label || "Select from the list"}
            variant="outlined"
            required={required}
            margin="dense"
            error={
                validateErrors[itemStateValue] !== undefined  ? true : false
            }

            helperText={

                validateErrors[itemStateValue] !== undefined
                ? validateErrors[itemStateValue]
                : null
            }
            inputProps={{
            ...params.inputProps,
            id: "next-user-inner-input",
            }}
        />
        )}
    />
    </>
 )
}
 
export default AppAutoComplete