import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import { toast } from "react-toastify";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import { expenseSchema, hasAccess, usersSchema } from "../../common/validators";
import validate from "validate.js";
import { addExpense, getExpenses, updateExpense } from "../../app/expensesSlice";
import moment from "moment";

const userTableHead = [
  { label: "Expense Date", name: "expense_date" },
  { label: "Amount", name: "amount" },
  { label: "Remarks", name: "remarks" },
  { label: "Status", name: "status" },
];


const userData = localStorage.getItem("userData")
? JSON.parse(localStorage.getItem("userData"))
: null;

const Expenses = () => {
  const dispatch = useDispatch();
  let [openForm, setOpenForm] = useState(false);
  let [edit, setEdit] = useState(false);
  let [isPosting, setIsPosting] = useState(false);
  let [data, setData] = useState([]);
  const [formValues, setFormValues] = useState({expense_date:new Date().toLocaleDateString(), fromDate: new Date(), toDate: new Date()});
  const {status,error,list} = useSelector(({expenses}) => expenses.get);
  const {status:postStatus,error:postError} = useSelector(({expenses}) => expenses.post);
  const {status:putStatus,error:putError} = useSelector(({expenses}) => expenses.put);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    //dispatch(getExpenses());
    
  }, [dispatch]);

  useEffect(() => {
    if(postStatus == "idle" && !postError){
      dispatch(getExpenses());
      setFormValues({});
      setOpenForm(false);
      setEdit(false);
      setIsPosting(false);
    }else{
      setIsPosting(false);
    }    
  }, [postStatus,postError]);

  useEffect(() => {
    if(putStatus == "idle" && !putError){
      dispatch(getExpenses());
      setFormValues({});
      setOpenForm(false);
      setEdit(false);
    }   
    setIsPosting(false); 
  }, [putStatus,putError]);

  useEffect(() => {
    if (list && status === "idle") {
      let _data = [];
      list.forEach((item, index) => {
        _data.push({
          id:item.id,
          expense_date: moment(item.expense_date).format("YYYY-MM-DD"),
          amount: Number(item.amount).toLocaleString(),
          remarks: item.remarks,
          status:
            item.status.toUpperCase() === "A"
              ? "Active"
              : item.status.toUpperCase() === "I"
              ? "Inactive"
              : item.status.toUpperCase() === "H"
              ? "Handover"
              : "Deleted"
        });
      });
      setData(_data);
    }
  }, [list]);

  useEffect(()=>{
    if(formValues?.fromDate && formValues?.toDate){
      dispatch(getExpenses({fromDate:formValues?.fromDate, toDate: formValues?.toDate}))
    }
   },[formValues])



  const handleRequestClose = () => {
    setErrors([])
    setFormValues({});
    setOpenForm(false);
    setEdit(false);
    setIsPosting(false);
  };

  const isAccess = (component,action) => {
    return hasAccess(component,action);
  }

  const isEditAccess = () => {
    return hasAccess('expenses','edit');
  }


  const editItem = (userItem) => {
    
    
    //alert(JSON.stringify(userItem))
    if (userItem) {
      setEdit(true);
      setOpenForm(true);
      setTimeout(() => {
        setFormValues({
          ...userItem,
          amount: String(userItem.amount).replace(',',''),
          status: 'A'
        });
      }, 300);
    }
  };

  const registerExpense = (e) => {
    let newDate = new Date();
    setFormValues({expense_date: newDate})
    e.preventDefault();
    setOpenForm(true);
    setFormValues({});
  };

  const handleSearchExpenses = () => {
    //alert(JSON.stringify(5))
    dispatch(getExpenses({fromDate:formValues?.fromDate, toDate: formValues?.toDate}))
  }

  const formOpenExpenseSearch = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-4">
            <Inputs
              name="fromDate"
              size="small"
              label="Start Date"
              value={formValues.fromDate}
              inputType="date"
              onChange={(e) => {handleChange({ target: { name: "fromDate", value: e } })}}
              error={errors["fromDate"] ? true : false}
              helperText={errors["fromDate"] ? errors.fromDate[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-4">
            <Inputs
              name="toDate"
              size="small"
              label="End Date"
              value={formValues.toDate}
              inputType="date"
              onChange={(e) => {handleChange({ target: { name: "toDate", value: e } })}}
              error={errors["toDate"] ? true : false}
              helperText={errors["toDate"] ? errors.toDate[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-4">
          <button style={{padding:"15px", borderRadius:"5px"}} onSubmit={() => {handleSearchExpenses()}}>
            Fetch Data
          </button>
          </div>
        </div>
      </>
    )
  }

  const formOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="expense_date"
              size="small"
              label="Date"
              value={formValues.expense_date}
              inputType="date"
              defaultValue={new Date()}
              onChange={(e) => handleChange({ target: { name: "expense_date", value: e } })}
              disabled={edit ? true : false}
              error={errors["expense_date"] ? true : false}
              helperText={errors["expense_date"] ? errors.expense_date[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="amount"
              size="small"
              label="Amount"
              value={formValues.amount}
              inputType="number"
              onChange={handleChange}
              error={errors["amount"] ? true : false}
              helperText={errors["amount"] ? errors.amount[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="remarks"
              size="small"
              label="Remarks"
              value={formValues.remarks}
              inputType="text"
              onChange={handleChange}
              error={errors["remarks"] ? true : false}
              helperText={errors["remarks"] ? errors.remarks[0] : null}
            />
          </div>
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    //event.preventDefault();
    //alert(event)
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsPosting(true);
    if(!formValues.expense_date){
      setFormValues({
        ...formValues,
        expense_date: new Date()
      })
    }
    //alert(formValues.expense_date);
    if(isAccess('expenses','add')){
      if(edit===true){
        let validationErrors = validate(
          formValues,
          expenseSchema
        );
        if(validationErrors){
          setErrors(validationErrors);      
        }else{
        //formValues.id = selectedUser?.id;
        dispatch(updateExpense(formValues))
        }
      }else{
        let validationErrors = validate(
          formValues,
          expenseSchema
        );
        if(validationErrors){
          setErrors(validationErrors);      
        }else{
        dispatch(addExpense(formValues))
        }
      }
    }else{
      toast.error("You are not allowed to access this facility");
    }
    
    //alert(JSON.stringify(formValues))
    //toast.success(JSON.stringify(formValues));
  };

  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Expenses</h2>
        &nbsp;
         <div style={{alignContent:"right"}}>
           {isAccess('expenses','add') === true &&
        <Inputs
          color="success"
          className={`bx-burst-hover`}
          size="small"
          inputType="button"
          type="submit"
          text={"New Expense"}
          onClick={(e) => registerExpense(e)}
          propBackgroundColor={"#367588"}
        />
        }
        </div>
      </div>

      {
        formOpenExpenseSearch()
      }

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {status != 'loading' && list.length ? (
                <Table
                  columns={userTableHead}
                  data={data}
                  actions={[
                    {
                      action: "Edit",
                      renderCheck: isEditAccess,
                      onClick: (item) => {
                        editItem(item);
                      },
                    } 
                  ]}
                  downloadFileName={"Staff List"}
                />
              ) : status === 'loading' ? (
                <MySpinner size={35} color="#367588" />
              ) : (<></>)}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={edit ? "Expense Update" : "New Expense"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        submit={handleSubmit}
        text={edit && isPosting == false ? "Update" :  isPosting == false ? "Save" : ""}
        content={() => formOpen()}
        hideSaveButton={!openForm}
        propBackgroundColor={"#367588"}
        showLoading={isPosting}
      />
    </div>
  );
};

export default Expenses;
