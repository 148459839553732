import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import { getCategory, getUnitMeasures } from "../../app/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { addSideEffects, getSideEffects, updateSideEffects } from "../../app/sideEffectsSlice";
import { addDispodedItems } from "../../app/disposedItemsSlice";
import AppTabs from "../../components/appTabs/appTabs";
import { customerSchema, disposeSchema, hasAccess, instCat, messageSchema, orderSchema, purchaseSchema, saleSchema } from "../../common/validators";
import validate from "validate.js";
import { addCustomer, getCustomers, getSmsBalance, purchaseSms, sendSms, updateCustomer } from "../../app/customersSlice";
import { getItems } from "../../app/itemsSlice";
import { addOrder } from "../../app/ordersSlice";
import Messages from "../../components/messages/Messages";
import { addPos } from "../../app/posSlice";
import { toast } from "react-toastify";

const tabs = [
  {
    title: "Credits History",
  },
  {
    title: "Orders History",
  }
];

const itemsTableHead = [
  { label: "Name", name: "customer_name" },
  { label: "Mobile", name: "customer_mobile" },
  { label: "Email", name: "customer_email" },
  "action",
];



const isViewAccess = () => {
  return hasAccess('items','view');
}

const isDetailsAccess = () => {
  return hasAccess('customers','details');
}

const isEditAccess = () => {
  return hasAccess('customers','edit');
}

const isAddAccess = () => {
  return hasAccess('customers','add');
}

const isAddStockAccess = () => {
  return hasAccess('orders','add');
}

const isEditStockAccess = () => {
  return hasAccess('orders','edit');
}

const isDisposeAccess = () => {
  return hasAccess('customers','delete');
}

const Customers = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openStockForm, setOpenStockForm] = useState(false);
  let [toSell, setToSell] = useState(false);  
  let [openDisposeForm, setOpenDisposeForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  let [edit, setEdit] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  let [showSideEffect, setShowSideEffect] = useState(false);
  let [toEditSideEffect, setToEditSideEffect] = useState(false);
  const [errors, setErrors] = useState([]);
  const [totalPurchaseAmount, setTotalPurchaseAmount] = useState(0);  
  const [formValues, setFormValues] = useState({"order_date": new Date(),"deliver_date": new Date(),"counts": 1});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [itemDataList, setItemDataList] = useState([]);
  const [measuresDataList, setMeasuresDataList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  let [openSms, setOpenSms] = useState(false);
  let [isPurchase, setIsPurchase] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  


  const dispatch = useDispatch();

  const { error, status, list } = useSelector(
    ({ customers }) => customers.get
  );

  const { error: postError, status: postStatus } = useSelector(
    ({ customers }) => customers.post
  );

  const { error: putError, status: putStatus } = useSelector(
    ({ customers }) => customers.put
  );

  const { error: errorItems, status: statusItems, list: listItems } = useSelector(
    ({ items }) => items.get
  );

  const { error: postOrderError, status: postOrderStatus } = useSelector(
    ({ orders }) => orders.post
  );

  const { error: saleErrorPost, status: saleStatusPost } = useSelector(
    ({ pos }) => pos.post
  );
 
  const { error: postDisposeError, status: postDisposeStatus } = useSelector(
    ({ disposedItems }) => disposedItems.post
  );

  const { error: postSideEffectsError, status: postSideEffectsStatus } = useSelector(
    ({ sideEffects }) => sideEffects.post
  );

  const { error: putSideEffectsError, status: putSideEffectsStatus } = useSelector(
    ({ sideEffects }) => sideEffects.put
  );

  const { error: smsError, status: smsStatus, list: smsList } = useSelector(
    ({ customers }) => customers.smsSend
  );

  const { error: purchaseError, status: purchaseStatus, list: purchaseList } = useSelector(
    ({ customers }) => customers.smsPurchase
  );

  const { error: balanceError, status: balanceStatus, list: balanceList } = useSelector(
    ({ customers }) => customers.smsBalance
  );

  const loadBalance = () => {
    dispatch(getSmsBalance());
  }

  const handleRequestClose = () => {
    setErrors([])
    setFormValues({});
    setOpenForm(false);
    setOpenDetail(false);
    setOpenStockForm(false);
    setToSell(false);
    setOpenDisposeForm(false);
    setShowSideEffect(false);
    setEdit(false);
    setToEditSideEffect(false);
    setActiveTab(0);
    setSelectedOrders([]);
    setTotalPrice(0);
    setFormValues({})
  };

  const loadSmsDialog = () => {
    loadBalance()
    setOpenSms(!openSms);
    setIsPurchase(false)
    setTotalPurchaseAmount(0)
  }

  const handleSendSms = (e) => {
    setIsLoading(true)
    e.preventDefault();
    //console.log('smsFormValues',formValues)
    if(isPurchase === true){
      let validationErrors = validate(
        formValues,
        purchaseSchema
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{
        dispatch(purchaseSms(formValues)) 
      }      
    }else{
      let newFormValues = {
        ...formValues,
        group: formValues?.receiversGroup,
        message: formValues?.messageBody
      }
      let validationErrors = validate(
        formValues,
        messageSchema
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{  
        dispatch(sendSms(newFormValues))  
      }
    }
      
  }



  const viewItem = (item) => {
    if (item) {

      setViewDetailsSelected(true)

      setEdit(false);
      setOpenDisposeForm(false);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const editItem = (item) => {
    if (item) {
      setEdit(true);
      setOpenDisposeForm(false);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };

 

  const formOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="customer_name"
              size="small"
              label="Customer Name"
              value={formValues.customer_name}
              inputType="text"
              disabled={edit ? true : false}
              onChange={handleChange}
              error={errors["customer_name"] ? true : false}
              helperText={errors["customer_name"] ? errors.customer_name[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="customer_mobile"
              size="small"
              label="Mobile"
              value={formValues.customer_mobile}
              inputType="number"
              onChange={handleChange}
              error={errors["customer_mobile"] ? true : false}
              helperText={errors["customer_mobile"] ? errors.customer_mobile[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="customer_email"
              size="small"
              label="Email"
              value={formValues.customer_email}
              inputType="text"
              onChange={handleChange}
              error={errors["customer_email"] ? true : false}
              helperText={errors["customer_email"] ? errors.customer_email[0] : null}
            />
          </div>

        </div>
      </>
    );
  };

  const formOrder = (item,isSale = false) => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-12">
            <h3>Details</h3>
          </div>
          <div className="col-sm-12 col-md-4 col-4">
            <Inputs
              name="sale_category"
              size="small"
              inputType="select"
              value={formValues.sale_category}
              defaultValue={{value:'retail',label:'Retail'}}
              label="Sale Category"
              listLabel="Sale Category"
              listValue="sale_category"
              list={[{value:'retail',label:'Retail'},{value:'wholesale',label:'Wholesale'}]}
              onChange={handleChange}
              error={errors["sale_category"] ? true : false}
              helperText={errors["sale_category"] ? errors.sale_category[0] : null}
            />
          </div>
          {
            isSale === true && <div className="col-sm-12 col-md-4 col-4">
            <Inputs
              name="sell_type"
              size="small"
              inputType="select"
              value={formValues.sell_type}
              defaultValue={{value:'cash',label:'Cash'}}
              label="Pay Type"
              listLabel="Pay Type"
              listValue="sell_type"
              list={[{value:'cash',label:'Cash'},{value:'oncredit',label:'On Credit'}]}
              onChange={handleChange}
              error={errors["sell_type"] ? true : false}
              helperText={errors["sell_type"] ? errors.sell_type[0] : null}
            />
          </div>
          }
           {
            isSale != true && 
          <div className="col-sm-12 col-md-4 col-4">
            <Inputs
              name="order_date"
              size="small"
              label="Order Date"
              value={formValues.order_date}
              inputType="date"
              onChange={(e) =>
              handleChange({ target: { name: "order_date", value: e } })}
              error={errors["order_date"] ? true : false}
              helperText={errors["order_date"] ? errors.order_date[0] : null}
            />
          </div>
          }

{
            isSale != true && 
          <div className="col-sm-12 col-md-4 col-4">
            <Inputs
              name="deliver_date"
              size="small"
              label="Deliver Date"
              value={formValues.deliver_date}
              inputType="date"
              onChange={(e) =>
                handleChange({ target: { name: "deliver_date", value: e } })}
                error={errors["deliver_date"] ? true : false}
                helperText={errors["deliver_date"] ? errors.deliver_date[0] : null}
            />
          </div>
  }

          <div className="col-sm-12 col-md-12 col-12">
           <br/>
            <h3>Items</h3>
          </div>
          

            <div className="col-sm-12 col-md-4 col-4">
              <Inputs
                name="unit_of_measure"
                size="small"
                inputType="select"
                value={formValues.unit_of_measure}
                defaultValue={measuresDataList[0]}
                label="Item"
                listLabel="Item"
                listValue="unit_of_measure"
                list={measuresDataList}
                onChange={handleChange}
                error={errors["unit_of_measure"] ? true : false}
                helperText={errors["unit_of_measure"] ? errors.unit_of_measure[0] : null}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-4">
              <Inputs
                name="rate"
                size="small"
                label="Price Per"
                value={formValues.rate}
                inputType="number"
                onChange={handleChange}
                error={errors["rate"] ? true : false}
                helperText={errors["rate"] ? errors.rate[0] : null}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-2">
              <Inputs
                name="counts"
                size="small"
                label="Counts"
                value={formValues.counts}
                inputType="number"
                onChange={handleChange}
                error={errors["counts"] ? true : false}
                helperText={errors["counts"] ? errors.counts[0] : null}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-2">
              <button onClick={addToOrderList} style={{padding:'10px',marginTop:'10px'}}>Add to Cart</button>
            </div>

            <div className="col-sm-12 col-md-12 col-12">
           <br/>
            <table>
      <thead>
        <tr>
          <th>Item Code</th>
          <th>Description</th>
          <th>Qty</th>
          <th>Rate</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {selectedOrders.map((item) => (
          <tr key={item}>
            <td>{item.item_code}</td>
            <td>{item.item_description}</td>
            <td>{item.counts}</td>
            <td>{item.amount > 0 ? item.amount/item.counts : 0}</td>
            <td>{item.amount}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    <div className="col-sm-12 col-md-12 col-12" style={{textAlign:'right'}}>
      TOTAL <b>{totalPrice}</b>
    </div>
            

        </div>
      </>
    );
  };


  const dialogOpen = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-6">
            <span>Name</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_name}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Mobile</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_mobile}</span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Email</span>&nbsp;&nbsp;
            <span>{selectedItem?.customer_name}</span>
          </div>
          <br /> <br />
          <div className="col-sm-12 col-md-12 col-12">
          <AppTabs
            tabs={tabs}
            activeTab={activeTab}
            handleTabChange={handleChangeTabs}
          />
          </div>
          <br /> <br />


        </div>
      </>
    );
  };

  
  const handleChangePurchase = (event) => {
    //alert(event.target.checked)
    setIsPurchase(!isPurchase);
    // setIsPurchase(event.target.checked);
  };


  const handleChangeOnSMSForm = (event) => {    
    //alert(JSON.stringify(event.target))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleChange = (event) => {
   
    //alert(JSON.stringify(event.target.value))
    setFormValues({
      ...formValues,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });

    
    if(event.target.name === "unit_of_measure"){
       setFormValues({
        ...formValues,
        "rate": formValues.sale_category === 'wholesale' ? event.target.value["w_selling_price"] : event.target.value["selling_price"],
        "item_code": event.target.value["item_code"],
        "item_id": event.target.value["id"],
        "item_description": event.target.value["item_description"],
        "counts":1
      });

      
    }
  };

  const addToOrderList = () => {

        //alert(JSON.stringify(formValues))

        let _selectedOrders = selectedOrders;      
        let selectedOrder = {"customer_id":selectedItem["id"],"order_date":formValues["order_date"],"deliver_date":formValues["deliver_date"],"item_id":null,"item_code":null,"item_description":null,"counts":0,"amount":0}
    
        selectedOrder.amount = formValues.counts * formValues.rate;
        selectedOrder.counts = formValues.counts;
        selectedOrder.item_code = formValues["item_code"];
        selectedOrder.item_id = formValues["item_id"];
        selectedOrder.item_description = formValues["item_description"];

        const itemPresent = _selectedOrders.filter((item) => item.item_code !== selectedOrder.item_code);
        // const itemPresent = _selectedOrders.find((item) => item.item_code === selectedOrder.item_code);
        _selectedOrders = itemPresent;
        if (itemPresent) {         
          _selectedOrders.push(selectedOrder);
          setSelectedOrders(_selectedOrders);

          if(formValues.counts > 0 && !_selectedOrders.includes(selectedOrder)){
            _selectedOrders.push(selectedOrder);
            setSelectedOrders(_selectedOrders);
          }
        }

        calculateTotalPrice(itemPresent);
  }

  const calculateTotalPrice = (itemPresent) => {
    let totalAmount = 0;
    for (let index = 0; index < itemPresent.length; index++) {
      totalAmount = totalAmount + itemPresent[index]["amount"];      
    }

    setTotalPrice(totalAmount);
  }

  const handleSubmitSale = (itemsPurchasedList = [], totalAmount = 0) => {
    if (itemsPurchasedList.length > 0) {
      if (formValues.sell_type == "oncredit") {
        let newSale = {
          taiData: itemsPurchasedList,
          payType: formValues.sell_type,
          customerName: selectedItem?.customer_name,
          customerMobile: selectedItem?.customer_mobile,
          totalAmount: totalAmount,
          sellingDate: formValues.order_date
        }
        dispatch(addPos(newSale))
      } else if (instCat() != 4 && formValues.sell_type === "cash") {
        let newSale = {
          taiData: itemsPurchasedList,
          customerName: selectedItem?.customer_name,
          customerMobile: selectedItem?.customer_mobile,
          sellingDate: formValues.order_date
        }
        dispatch(addPos(newSale))
      } else {
        toast.error("Please provide customer name and mobile number");
      }

    } else {
      toast.error("Please provide Items");
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    if (edit && !openStockForm) {
      let validationErrors = validate(
        formValues,
        customerSchema
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{
      let newItem = {
        "id": formValues?.id,
        "customer_name": formValues?.customer_name,
        "customer_mobile": formValues?.customer_mobile,
        "customer_email": formValues?.customer_email
      }
      dispatch(updateCustomer(newItem));
    }
    } else if (openStockForm && toSell === false) {
      let validationErrors = validate(
        formValues,
        orderSchema
        
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{
        //toast.success(JSON.stringify(formValues));
        for (let index = 0; index < selectedOrders.length; index++) {
          delete selectedOrders[index]['item_code'];
          delete selectedOrders[index]['item_description'];        
        }      
        dispatch(addOrder({toSend:JSON.stringify(selectedOrders)}))
      }
    }else if (openStockForm && toSell === true) {
      let validationErrors = validate(
        formValues,
        saleSchema
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{
        //toast.success(JSON.stringify(formValues));
        let _selectedOrders = selectedOrders;
        let totalAmountSale = 0;
        for (let index = 0; index < _selectedOrders.length; index++) {
          totalAmountSale = totalAmountSale + _selectedOrders[index]['amount'];
          _selectedOrders[index]['rate'] = _selectedOrders[index]['amount']/_selectedOrders[index]['counts']
          _selectedOrders[index]['qty'] = _selectedOrders[index]['counts']
          _selectedOrders[index]['is_retail'] = formValues?.sale_category === 'retail' ? 'Y' : 'N'
          _selectedOrders[index]['discount'] = 0
          _selectedOrders[index]['package'] = ''
          _selectedOrders[index]['unit_of_measure'] = ''
          
          delete _selectedOrders[index]['order_date'];
          delete _selectedOrders[index]['deliver_date'];    
          delete _selectedOrders[index]['customer_id'];    
          delete _selectedOrders[index]['counts'];    
              
        }    
        handleSubmitSale(_selectedOrders,totalAmountSale);
      }

    } else if (openDisposeForm) {
      let validationErrors = validate(
        formValues,
        disposeSchema
      );
      if(validationErrors){
        //console.log('validationErrors',validationErrors)
        setErrors(validationErrors);      
      }else{
      setFormValues({
        ...formValues,
        item_id: selectedItem?.id
      })
      //alert(JSON.stringify(formValues))
      dispatch(addDispodedItems(formValues))
    }
    }else if(showSideEffect && !toEditSideEffect){
      let newEffect = {
        item_code:formValues.item_code,
        item_id:formValues.id,
        remarks:formValues.side_effect,
        status:'A'
      }
      // alert(JSON.stringify(newEffect))
      dispatch(addSideEffects(newEffect))
    } else if(showSideEffect && toEditSideEffect){
      let newEffect = {
        id:formValues.id,
        item_code:formValues.item_code,
        item_id:formValues.item_id,
        remarks:formValues.side_effect,
        status:String(formValues.side_effect).length < 5 ? 'X' : 'A'
      }
      // alert(JSON.stringify(newEffect))
      dispatch(updateSideEffects(newEffect))
    }
     else {
      let validationErrors = validate(
        formValues,
        customerSchema
      );
      if(validationErrors){
        setErrors(validationErrors);      
      }else{
      let newItem = {
        "customer_name": formValues?.customer_name,
        "customer_mobile": formValues?.customer_mobile,
        "customer_email": formValues?.customer_email
      }
      dispatch(addCustomer(newItem));
    }
    }

  };


  const newStockClicked = (item = null, _toSell = false) => {
    // e.preventDefault();
    //toast.success("New Stock");
    dispatch(getItems())
    //setFormValues({ item_code: item?.item_code, item_description: item?.item_description, buy_date: new Date() })
    setSelectedItem(item);
    setOpenStockForm(true);
    setToSell(_toSell);
    setOpenDisposeForm(false);


  };

  const disposeClicked = (item = null) => {
    //setFormValues({ item_code: item?.item_code, item_description: item?.item_description, destroy_date: new Date() })
    setSelectedItem(item);
    setOpenDisposeForm(true);
    setEdit(false);
    setOpenDetail(false);

  };

  const handleChangeTabs = (index) => {
    setActiveTab(index);
  };


  useEffect(() => {
    dispatch(getCustomers());
    dispatch(getCategory());
    dispatch(getUnitMeasures());
  }, [dispatch]);


  useEffect(() => {
    if(formValues.counts && formValues.counts > 0){      
      let _amount = formValues.counts * 25;
      let newValues = {
      ...formValues,
      amount: _amount
      }
      setFormValues(newValues)
      setTotalPurchaseAmount(_amount);
      //alert(formValues.counts+"=="+formValues.amount)
    }
  },[formValues.counts])

  useEffect(() => {
    setToEditSideEffect(false);
    if (activeTab === 0) {
      setShowSideEffect(false);
      if (viewDetailsSelected === true && selectedItem) {
        //dispatch(getStockHistory({ item_code: selectedItem?.item_code }))
      }
      // dispatch(getExpenses())
    } else {
      //setShowStock(false);
      setShowSideEffect(false);
    }

  }, [activeTab]);

  useEffect(() => {
    if (viewDetailsSelected === true && selectedItem) {
     // dispatch(getStockHistory({ item_code: selectedItem?.item_code }))
    }
  }, [viewDetailsSelected, selectedItem]);



  useEffect(() => {
    if (postStatus === "loading" && !edit) {
      setHideSaveButton(true);
    } else if (postStatus === "idle") {
      dispatch(getCustomers());
      setHideSaveButton(false);
    }
  }, [postStatus]);

  useEffect(() => {
    if (smsStatus === "loading") {
      setOpenSms(true);
    } else if (smsError === null && smsStatus === "idle") {
      setOpenSms(false);
    }
  }, [smsStatus,smsError]);

  useEffect(() => {
    if (purchaseStatus === "loading") {
      setOpenSms(true);
    } else if (purchaseError === null && purchaseStatus === "idle") {
      setIsPurchase(false);
      setOpenSms(false);
    }
  }, [purchaseStatus,purchaseError]);

  

  

  useEffect(() => {
    if (postSideEffectsStatus === "loading" && showSideEffect) {
      setHideSaveButton(true);
    } else if (postSideEffectsStatus === "idle") {
      dispatch(getSideEffects({item_code:selectedItem?.item_code}));
      setHideSaveButton(false);
    }
  }, [postSideEffectsStatus]);


  useEffect(() => {
    if (putSideEffectsStatus === "loading" && showSideEffect) {
      setHideSaveButton(true);
    } else if (putSideEffectsStatus === "idle") {
      //setToEditSideEffect(false);
      dispatch(getSideEffects({item_code:selectedItem?.item_code}));
      setHideSaveButton(false);
    }
  }, [putSideEffectsStatus]);

  

  useEffect(() => {
    if (postDisposeStatus === "loading") {
      setHideSaveButton(true);
    } else if (postDisposeStatus === "idle") {
      dispatch(getCustomers());
      setHideSaveButton(false);
    }
  }, [postDisposeStatus]);

  useEffect(() => {
    if (putStatus === "loading" && edit) {
      setHideSaveButton(true);
    } else if (putStatus === "idle") {
      dispatch(getCustomers());
      setHideSaveButton(false);
    }

  }, [putStatus]);

  useEffect(() => {
    if (postOrderStatus === "loading") {
      setHideSaveButton(true);
    } else if (postOrderStatus === "idle") {
      dispatch(getCustomers());
      setHideSaveButton(false);
      setTotalPrice(0);
      setSelectedOrders([]);
      setFormValues({})
    }
  }, [postOrderStatus]);

  useEffect(() => {
    if (saleStatusPost === "loading") {
      setHideSaveButton(true);
    } else if (saleStatusPost === "posted") {
      dispatch(getCustomers());
      setHideSaveButton(false);
      setTotalPrice(0);
      setSelectedOrders([]);
      setFormValues({})
      // setToSell(false)
    }
  }, [saleStatusPost,saleErrorPost]);
  

  useEffect(() => {
    if (status === "loading") {

    } else if (status === "idle") {
      setItemDataList(list);
    }

  }, [status, list]);


  useEffect(() => {
    if (postOrderError) {
      setOpenStockForm(true);
    } else if (postOrderStatus === "idle") {
      setOpenStockForm(false)
      setToSell(false)
      setTotalPrice(0);
      setSelectedOrders([]);
      setFormValues({})
    }


  }, [postOrderStatus, postOrderError]);


  useEffect(() => {
    if (statusItems === "loading") {

    } else if (statusItems === "idle") {
      let measureArray = [];
      for (let index = 0; index < listItems.length; index++) {
        measureArray.push({ value: listItems[index], label: listItems[index]?.item_description })
      }
      setMeasuresDataList(measureArray);
    }
  }, [statusItems, listItems]);


  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Available Customers</h2>
        {/* <i
          title="New Item"
          className={`bx bx-plus bx-burst-hover`}
          style={{color:"red",cursor:"pointer"}}
          onClick={() => {
            setOpenForm(true);
          }}
        ></i> */}
        &nbsp;
        <div style={{ alignContent: "right" }}>
          {isAddAccess &&
          <Inputs
            color="success"
            className={`bx-burst-hover`}
            size="small"
            inputType="button"
            type="submit"
            text={"New Customer"}
            onClick={() => setOpenForm(true)}
            propBackgroundColor={"#367588"}
          />
          }
          &nbsp;
          {isAddAccess &&
          <Inputs
            color="success"
            className={`bx-burst-hover`}
            size="small"
            inputType="button"
            type="submit"
            text={"New Message"}
            onClick={() => loadSmsDialog()}
            propBackgroundColor={"#800000ff"}
          />
          }
          {/* <Inputs
          color="info"
          size="small"
          inputType="button"
          type="submit"
          text={"New Stock"}
          onClick={() => newStockClicked()}
        /> */}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card" style={{ backgroundColor: "#e0e0e0" }}>
            <div className="card-body">
              {itemDataList.length > 0 ? (
                <Table
                  columns={itemsTableHead}
                  data={itemDataList}
                  actions={[
                    {
                      action: "Details",
                      renderCheck: isDetailsAccess,
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Update",
                      renderCheck: isEditAccess,
                      onClick: (item) => {
                        editItem(item);
                      },
                    },
                    {
                      action: "New Order",
                      renderCheck: isAddStockAccess,
                      onClick: (item) => {
                        newStockClicked(item);
                      },
                    },
                    {
                      action: "New Sale",
                      renderCheck: isAddStockAccess,///isDisposeAccess,
                      onClick: (item) => {
                        //disposeClicked(item);
                        newStockClicked(item, true);
                      },
                    },
                  ]}
                  downloadFileName={"Available Customers"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={(isPurchase === true ? "Purchasing Messages" : "Sending Message")+(balanceList && balanceList.length>0? ' - Available balance '+Number(balanceList[0]['counts']).toLocaleString()+' ' : '')}
        width="sm"
        onClose={loadSmsDialog}
        open={openSms}
        text={isPurchase === true ? "Request Invoice" : "Send Message"}
        content={() => <Messages handleChangeSMS={handleChangeOnSMSForm} handleChangePurchase={handleChangePurchase} setFormValues={setFormValues} isPurchase={isPurchase} totalPurchaseAmount={totalPurchaseAmount} />}
        submit={(e) => handleSendSms(e)}
        hideSaveButton={openSms && !(purchaseStatus === 'loading' || smsStatus === 'loading') ? false: true}
        propBackgroundColor="#367588"
      />

      <MyDialog
        title={"Item Details"}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={showSideEffect ? "Save Side Effect" : "Update"}
        content={() => dialogOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={showSideEffect ? false: true}
        propBackgroundColor="#367588"
      />

      <MyDialog
        title={!edit ? "New Customer Details" :"Update Customer Details"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={!edit ? "Save" : "Update"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor="#367588"
      />

      <MyDialog
        title={toSell === true ? "New sale to "+selectedItem?.customer_name+" "+selectedItem?.customer_mobile : "Order from "+selectedItem?.customer_name+" "+selectedItem?.customer_mobile}
        width="md"
        onClose={handleRequestClose}
        open={openStockForm}
        text={"Save"}
        content={() =>
          formOrder(selectedItem && selectedItem.id ? selectedItem : null, toSell)
        }
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor={"#367588"}
      />
    </div>
  );
};

export default Customers;
