import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getRolesList, getRolesResourcesList, putRole, putRoleResources, registerRole } from "../api/rolesAPI";

export const getRoles = createAsyncThunk("roles/getRoles", async (payload, { rejectWithValue }) => {
  try {
    const response = await getRolesList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getRolesResources = createAsyncThunk("roles/getRolesResources", async (payload, { rejectWithValue }) => {
  try {
    const response = await getRolesResourcesList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addRole = createAsyncThunk("roles/addRole", async (payload, { rejectWithValue }) => {
  try {
    const response = await registerRole(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const updateRole = createAsyncThunk("roles/updateRole", async (payload, { rejectWithValue }) => {
  try {
    const response = await putRole(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const updateRoleResource = createAsyncThunk("roles/updateRoleResource", async (payload, { rejectWithValue }) => {
  try {
    const response = await putRoleResources(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    payload: {
      status: null,
      error: null,
      data: null,
    },
    post: {
      status: null,
      error: null,
      data: null,
    },
    put: {
      status: null,
      error: null,
      data: null,
    },
    get: {
      status: null,
      error: null,
      list: [],
    },
    rolesResources: {
      status: null,
      error: null,
      list: [],
    },
    putRolesResources: {
      status: null,
      error: null,
      data: null,
    },
  },

  extraReducers: {
    [getRoles.pending]: (state) => {
      state.get.status = "loading";
    },
    [getRoles.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get role!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.get.status = 'idle';
    },
    [getRoles.rejected]: (state, action) => {
      state.get.status = "idle";
      state.get.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get role!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [getRolesResources.pending]: (state) => {
      state.rolesResources.status = "loading";
    },
    [getRolesResources.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.rolesResources.list = data;
        state.rolesResources.error = null;
      }else{
        state.rolesResources.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get role!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.rolesResources.status = 'idle';
    },
    [getRolesResources.rejected]: (state, action) => {
      state.rolesResources.status = "idle";
      state.rolesResources.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get role!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [addRole.pending]: (state) => {
      state.post.status = "loading";
    },
    [addRole.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Role saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.post.status = "idle";
        state.post.error = null;
      }else{
        toast.warn("Couldn't add Role!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.post.error = "Failed";
      }

      state.post.data = data;
    },
    [addRole.rejected]: (state, action) => {
      //state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add Role!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateRole.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateRole.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Role saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.put.status = "idle";
      }else{
        state.put.error = "Failed";
        toast.warn("Couldn't update Role!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.data = data;
    },
    [updateRole.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add Role!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateRoleResource.pending]: (state) => {
      state.putRolesResources.status = "loading";
    },
    [updateRoleResource.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Resource updated.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.putRolesResources.status = "idle";
      }else{
        state.putRolesResources.error = "Failed";
        toast.warn("Couldn't update Resource!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.putRolesResources.data = data;
    },
    [updateRoleResource.rejected]: (state, action) => {
      state.putRolesResources.status = "idle";
      state.putRolesResources.error = { message: "An error occurred" };
      //console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add Resource!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

export default rolesSlice.reducer;
