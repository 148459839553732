import { adminServiceApi } from "../services";

export const getProductsList = async (query = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("products", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getProductsWorkflowList = async (query = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("product_workflow", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const registerProducts = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("products/add", payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const assignWorkflow = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("product_workflow/add", payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putProducts = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("products/"+payload?.id, payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putProductWorkflow = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("product_workflow/"+payload?.id, payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};