import React, { useState, useEffect, useCallback } from "react";
import logo from "../../assets/images/taiPOS.svg";
// import logo from "../../assets/images/taiPOS.png";
import "./login.css";
import Footer from "../../components/footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import './intro.css';
import IconList from "@material-ui/icons/ArrowForwardIos";
import IconLine from "@material-ui/icons/ArrowDropDown";






const Intro = () => {
  const [errors, setErrors] = useState([]);
  const [instCategories, setInstCategories] = useState([]);

  const [showResetButton, setShowResetButton] = useState(true);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);


  

  useEffect(() => {
    //alert(JSON.stringify(auth));
    if (auth?.data) {
      localStorage.setItem('userData', JSON.stringify(auth?.data))
    }
  }, [auth]);


  const toLogin = () => {
    navigate("/login");
  }

  const toRegister = () => {
    navigate("/register");
  }

  const toPolicy = () => {
    navigate("/policy");
  }

  const toPricing = () => {
    navigate("/pricing");
  }

  const toHome = () => {
    navigate("/intro");
  }

  return (
    <>
    <Grid className="intro" container spacing={1} style={{height:'90vh'}}>
      <Grid item xs={12} md={6} style={{color:'#367588', padding:'3rem'}}>
        {/* <img src={logo} alt="logo" width="80vh" /> */}
        <h2>TaiPOS</h2>
      </Grid>
      <Grid item xs={12} md={6} style={{display:'flex', flexDirection:'row', padding:'3rem'}}>
        <Grid item xs={2} md={2}>
          
        </Grid>
        <Grid item xs={2} md={2}>
        <p className="top-menu" onClick={(e) => {
            e.preventDefault();
            toHome()
          }}>
            Home
          </p>
        </Grid>
        <Grid item xs={2} md={2}>
        <p className="top-menu" onClick={(e) => {
                e.preventDefault();
                toPricing()
              }}>
          Pricing
          </p>
        </Grid>
        <Grid item xs={2} md={2}>
          <p className="top-menu" onClick={(e) => {
                e.preventDefault();
                toPolicy()
              }}>
                Terms
          </p>
          
        </Grid>
        <Grid item xs={2} md={2}>
          
          <Button
              id="btn-nav-resend"
              size="small"
              variant="contained"
              color="primary"
              style={{backgroundColor:'#367588'}}
              onClick={(e) => {
                e.preventDefault();
                toLogin()
              }}
            >
             Login
            </Button>
        </Grid>
      </Grid>
      <Grid  item xs={12} md={12} style={{textAlign:'center', color:'#fff', alignItems:'center' }}>
        <div className="grid-intro">
        <h1 className="text-bordered">Effortlessly Manage Businesses,</h1>
        <h1 className="text-bordered">Monitor Stock Level, and Track Sales Data</h1>
        <h1 className="text-bordered">at your convenience</h1>
        </div>
      </Grid>
      
    </Grid>
    <Grid className="intro-why" container spacing={1} style={{}}>
    <Grid item xs={12} md={12} style={{padding:'1rem', textAlign:'center', color:'#367588'}}>
      <IconLine style={{fontSize:'5rem'}}/>
    </Grid>

    <Grid item xs={12} md={12} style={{padding:'1rem'}}>
    <h3>Why you need TaiPOS system?</h3>
    </Grid>
      
      
    <Grid item xs={12} md={8} lg={8} style={{backgroundColor:'#fff', borderRadius:'0.5rem', padding:'1rem'}}>
        <h2>RETAILORS AND WHOLESALERS</h2>
        <br/>
        <p>Set prices for your products to cover their costs and earn profit. </p>
        <p>Offer discounted prices to retailers based on the quantity of products purchased to meet profit margins.</p>
        <p>Generate and badge your products BARCODES for easier tracking and searching.</p>
        <p>Curate a selection of products from wholesalers or directly from manufacturers based on consumer demand and market trends. </p>
        <br/>
        <p>Manage your inventory effectively, ensuring that products are available to meet customer demand while minimizing excess stock. </p>
        <p>Receive and deliver customer orders while maintaining your values. </p>
        <p>Make cash or on credit sales to your customers. </p>
        <p>Establish good relationships with your customers for future businesses.</p>
      </Grid>
      <Grid item xs={12} md={4} lg={4} style={{backgroundColor:'#3675883d', borderRadius:'0.5rem', padding:'1rem'}}>
        <h2>MONEY AGENTS/WAKALA</h2>
        <br/>
        <p><IconList style={{fontSize:'12px', marginRight:'1rem'}}/>Record deposit and withdraw requests from your customers. </p>
        <p><IconList style={{fontSize:'12px', marginRight:'1rem'}}/>Manage your business floats effectively. </p>
        <p><IconList style={{fontSize:'12px', marginRight:'1rem'}}/>Track the commissions on daily basis. </p>
        <br/>
        <br/>
        <Button
              id="btn-nav-resend"
              size="small"
              variant="contained"
              color="primary"
              style={{backgroundColor:'#367588'}}
              onClick={(e) => {
                e.preventDefault();
                toRegister()
              }}
            >
             Register Business
            </Button>
      </Grid>
      <Grid item xs={12} md={12} style={{padding:'2rem', textAlign:'center', color:'#367588'}}>
       <Footer/>
    </Grid>
      </Grid>
    </>
  );
};

export default Intro;
