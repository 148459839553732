import { adminServiceApi } from "../services";

export const getRolesList = async (query = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    // query.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("roles", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getRolesResourcesList = async (query = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("roles_resources", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const registerRole = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("roles/add", payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putRole = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("roles/"+payload?.id, payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putRoleResources = async (payload = null) => {
  const activeSession = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    payload.inst_id = activeSession.data?.inst_id;
  const reqOpts = {
    headers: {
      Authorization: `Bearer ${activeSession.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("roles_resources/"+payload?.id, payload,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};