import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getWorkflowList, putWorkflow, registerWorkflow } from "../api/workflowAPI";

export const getWorkflow = createAsyncThunk("workflow/getWorkflow", async (payload, { rejectWithValue }) => {
  try {
    const response = await getWorkflowList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addWorkflow = createAsyncThunk("workflow/addWorkflow", async (payload, { rejectWithValue }) => {
  try {
    const response = await registerWorkflow(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const updateWorkflow = createAsyncThunk("workflow/updateWorkflow", async (payload, { rejectWithValue }) => {
  try {
    const response = await putWorkflow(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const workflowSlice = createSlice({
  name: "resources",
  initialState: {
    payload: {
      status: null,
      error: null,
      data: null,
    },
    post: {
      status: null,
      error: null,
      data: null,
    },
    put: {
      status: null,
      error: null,
      data: null,
    },
    get: {
      status: null,
      error: null,
      list: [],
    },
  },

  extraReducers: {
    [getWorkflow.pending]: (state) => {
      state.get.status = "loading";
    },
    [getWorkflow.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get workflow!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.get.status = 'idle';
    },
    [getWorkflow.rejected]: (state, action) => {
      state.get.status = "idle";
      state.get.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get workflow!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [addWorkflow.pending]: (state) => {
      state.post.status = "loading";
    },
    [addWorkflow.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Workflow saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.post.status = "idle";
        state.post.error = null;
      }else{
        toast.warn("Couldn't add workflow!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.post.error = "Failed";
      }

      state.post.data = data;
    },
    [addWorkflow.rejected]: (state, action) => {
      //state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add workflow!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateWorkflow.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateWorkflow.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Workflow saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.put.status = "idle";
      }else{
        state.put.error = "Failed";
        toast.warn("Couldn't update workflow!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.data = data;
    },
    [updateWorkflow.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add workflow!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

export default workflowSlice.reducer;
