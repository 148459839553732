export const SERVICES_BASE_URL = "https://mauzo-gateway.herokuapp.com";//"http://localhost:5555";//

export const ADMIN_SERVICE_URL = process.env.REACT_APP_ADMIN_SERVICE_URL || `${SERVICES_BASE_URL}`;

const dev = {
  API_URL: "https://mauzo-gateway.herokuapp.com,"//"http://localhost:5555",//
};

const prod = {
  API_URL: `${SERVICES_BASE_URL}`,
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

export const dateFormat = "DD/MM/YYYY";
export const dateFormatWithTime = "DD/MM/YYYY hh:mm:ss";
