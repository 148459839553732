import React from 'react';

const KeyPad = ({_onClick}) => { 
  //https://codesandbox.io/s/pefmr?file=/src/App.js:1276-1318

    return (
      <div className="button">
        <button className="calc" name="00" onClick={_onClick}>00</button>
        <button className="calc" name="CE" style={{color:"#e0b9b9",fontWeight:"bold"}} onClick={_onClick}>CE</button>
        <button className="calc" name="000" onClick={_onClick}>000</button>
        <button className="calc" name="C" style={{color:"#e0b9b9"}} onClick={_onClick}>C</button><br/>


        <button className="calc" name="1" onClick={_onClick}>1</button>
        <button className="calc" name="2" onClick={_onClick}>2</button>
        <button className="calc" name="3" onClick={_onClick}>3</button>
        <button className="calc" name="50" style={{color:"#b9e0cf"}} onClick={_onClick}>50</button><br/>


        <button className="calc" name="4" onClick={_onClick}>4</button>
        <button className="calc" name="5" onClick={_onClick}>5</button>
        <button className="calc" name="6" onClick={_onClick}>6</button>
        <button className="calc" name="100" style={{color:"#b9e0cf"}} onClick={_onClick}>100</button><br/>

        <button className="calc" name="7" onClick={_onClick}>7</button>
        <button className="calc" name="8" onClick={_onClick}>8</button>
        <button className="calc" name="9" onClick={_onClick}>9</button>
        <button className="calc" name="200" style={{color:"#b9e0cf"}} onClick={_onClick}>200</button><br/>


        <button className="calc" name="." onClick={_onClick}>.</button>
        <button className="calc" name="0" onClick={_onClick}>0</button>
        <button className="calc" name="=" onClick={_onClick}>=</button>
        <button className="calc" name="500" style={{color:"#b9e0cf"}} onClick={_onClick}>500</button><br/>
      </div>
    )
  }

export default KeyPad;