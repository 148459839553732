import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getItemsList, postItems, postUploadedItems, putItems } from "../api/itemsAPI";
import itemsList from "../assets/JsonData/items-list.json";

const initialState = {
  payload: {
    status: null,
    error: null,
    data: null,
  },
  post: {
    status: null,
    error: null,
    data: null,
  },
  put: {
    status: null,
    error: null,
    data: null,
  },
  get: {
    status: null,
    error: null,
    list: [],
  },
};

export const getItems = createAsyncThunk("items/getItems", async (payload, { rejectWithValue }) => {
  try {
    const response = await getItemsList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const addItems = createAsyncThunk(
  "items/addItems",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await postItems(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const uploadItems = createAsyncThunk(
  "items/uploadItems",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postUploadedItems(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateItems = createAsyncThunk(
  "items/updateItems",
  async (payload, { rejectWithValue }) => {
    try {
      // const response = {
      //   status:201,
      //   data:{}
      // }
      const response = await putItems(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);


export const updateItemss = createAsyncThunk(
  "items/updateItems",
  async (payload, { rejectWithValue }) => {
    try {
      let isChanged = false;
      for (let index = 0; index < itemsList.length; index++) {
        if(itemsList[index].item_code === payload?.item_code){
          itemsList[index] = payload;
          isChanged = true
        };        
      }

      const response = {
        status:202,
        data:{}
      }

      response.status = !isChanged ? 400 : 202;
      //await createWorkflowItem(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

// export const updateItems = createAsyncThunk(
//   "items/updateItems",
//   async (payload) => {
//     const response = await fetch(`http://localhost:4000/items/${payload.id}`, {
//       method: "PATCH",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         updated: payload.updated,
//       }),
//     });
//     if (response.ok) {
//       const user = await response.json();
//       return { user };
//     }
//   }
// );

export const itemsSlice = createSlice({
  name: "items",
  initialState: initialState,
  reducers: {
    resetItemsState: (state) => {
      state.post.status = null;
      state.post.data = null;
      state.post.error = null;

      state.payload.data = null;
      state.payload.error = null;
      state.payload.status = null;

      state.put.error = null;
      state.put.data = null;

      state.get.status = null;
      state.get.error = null;
      state.get.list = [];
    },
  },
  extraReducers: {
    [getItems.pending]: (state) => {
      state.get.status = "loading";
    },
    [getItems.fulfilled]: (state, {payload}) => {
      // console.log('actionaction',payload)
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
      }
      state.get.status = 'idle';
    },
    [addItems.pending]: (state) => {
      state.post.status = "loading";
    },
    [addItems.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Item saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn(data.message ? data.message : "Couldn't add item! ", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.post.status = "idle";
      state.post.data = data;
    },
    [addItems.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add item!"+action.message, {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [uploadItems.pending]: (state) => {
      state.post.status = "loading";
    },
    [uploadItems.fulfilled]: (state, { payload }) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Items saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn(data.message ? data.message : "Couldn't upload item! ", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.post.status = "idle";
      state.post.data = data;
    },
    [uploadItems.rejected]: (state, action) => {
      state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't upload item!"+action.message, {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateItems.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateItems.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;

      // alert(JSON.stringify(data))
      if (status === 202) {
        toast.success("Item updated.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
      }else{
        toast.warn(data.message ? data.message : "Couldn't update item!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.status = "idle";
      // const index = state.findIndex((user) => user.id === action.payload.id);
      // state[index].updated = action.payload.updated;
    },
    [updateItems.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** WORKFLOW ERROR PAYLOAD ******* ", action);
      toast.warn(action.error.message, {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

// export const { resetItemsState } = resetItemsState.actions;
export default itemsSlice.reducer;
