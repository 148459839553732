import { adminServiceApi, mfukopesaServiceApi } from "../services";

export const getLoansList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("loans", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const loanDetails = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("loans/details/"+query?.id, reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCalculator = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    //?period=12&amount=2000000&interest=14&loan_date=2022-10-19&loan_charges=0
    const response = await adminServiceApi.get("loans/calculator", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const postLoans = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  query.inst_id = userData.data.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.post("loans/add",query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const putLoans = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put("loans/"+query?.id,query,reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};