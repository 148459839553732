import React from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./layout/Layout";
import { Provider } from "react-redux";
import { store } from "./app/store";

const MainApp = () => (
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

export default MainApp;
