import React from "react";
import "./appTabs.css";

const AppTabs = ({ tabs = [], activeTab, handleTabChange }) => {
  return (
    <div className="app-tab-view">
      {tabs.map((tab, index) => (
        <div
          id={`app-tab-${tab.title.toLowerCase()}`}
          className={
            "app-tab " +
            (activeTab === index ? "active-tab " : "") +
            (tab.disabled ? "disabled-tab " : "")
          }
          onClick={() => {
            handleTabChange(index);
          }}
        >
          {tab.title}
          {tab.badgeText && <div className="tab-badge">{tab.badgeText}</div>}
        </div>
      ))}
    </div>
  );
};

export default AppTabs;
