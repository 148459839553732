import React from "react";
import { useNavigate } from "react-router-dom";
// import { Button } from '@mui/material';

import "./footer.css";

const Footer = () => {

const navigate = useNavigate();
const today = new Date().getFullYear();

const handlePolicy = (e) => {
  e.preventDefault();
  // window.location.replace("/policy")
  navigate("/policy");

}



  return (
  <small>
    &copy; WayData Company Ltd - {today} <br/>  
  Email: salesupport@waydata.co.tz &nbsp;&nbsp;&nbsp;  Call: +255 769 003 815 &nbsp;&nbsp; <a style={{cursor:'pointer'}} onClick={(e) => handlePolicy(e)}>Terms</a>
  </small>
  );
};

export default Footer;
