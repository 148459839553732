import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getActionsList, getComponentsList, getInstitutionCategoriesList, getItemsCategoryList, getUnitMeasuresList } from "../api/commonAPI";


export const getCategory = createAsyncThunk("categories/getCategory", async (payload, { rejectWithValue }) => {
  try {
    const response = await getItemsCategoryList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getUnitMeasures = createAsyncThunk("measures/getUnitMeasures", async (payload, { rejectWithValue }) => {
  try {
    const response = await getUnitMeasuresList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getActions = createAsyncThunk("public/getActions", async (payload, { rejectWithValue }) => {
  try {
    const response = await getActionsList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getInstitutionCategories = createAsyncThunk("public/getInstitutionCategories", async (payload, { rejectWithValue }) => {
  try {
    const response = await getInstitutionCategoriesList();
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});

export const getComponents = createAsyncThunk("public/getComponents", async (payload, { rejectWithValue }) => {
  try {
    const response = await getComponentsList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const commonSlice = createSlice({
  name: "common",
  initialState: {
    categories: {
      post: {
        status: null,
        error: null,
        data: null,
      },
      put: {
        status: null,
        error: null,
        data: null,
      },
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    institutionCategories: {
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    unitMeasures: {
      post: {
        status: null,
        error: null,
        data: null,
      },
      put: {
        status: null,
        error: null,
        data: null,
      },
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    actions: {
      post: {
        status: null,
        error: null,
        data: null,
      },
      put: {
        status: null,
        error: null,
        data: null,
      },
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    components: {
      post: {
        status: null,
        error: null,
        data: null,
      },
      put: {
        status: null,
        error: null,
        data: null,
      },
      get: {
        status: null,
        error: null,
        list: [],
      },
    },
    
  },

  extraReducers: {
    //item categories
    [getCategory.pending]: (state) => {
      state.categories.get.status = "loading";
    },
    [getCategory.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.categories.get.list = data;
        state.categories.get.error = null;
      }else{
        state.categories.get.error = { message: `${status} - An error occurred` };
      }
      state.categories.get.status = 'idle';
    },
    //institution categories
    [getInstitutionCategories.pending]: (state) => {
      state.institutionCategories.get.status = "loading";
    },
    [getInstitutionCategories.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.institutionCategories.get.list = data;
        state.institutionCategories.get.error = null;
      }else{
        state.institutionCategories.get.error = { message: `${status} - An error occurred` };
      }
      state.institutionCategories.get.status = 'idle';
    },
    //unit measures
    [getUnitMeasures.pending]: (state) => {
      state.unitMeasures.get.status = "loading";
    },
    [getUnitMeasures.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.unitMeasures.get.list = data;
        state.unitMeasures.get.error = null;
      }else{
        state.unitMeasures.get.error = { message: `${status} - An error occurred` };
      }
      state.unitMeasures.get.status = 'idle';
    },
    //actions
    [getActions.pending]: (state) => {
      state.actions.get.status = "loading";
    },
    [getActions.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.actions.get.list = data;
        state.actions.get.error = null;
      }else{
        state.actions.get.error = { message: `${status} - An error occurred` };
      }
      state.actions.get.status = 'idle';
    },
    //components
    [getComponents.pending]: (state) => {
      state.components.get.status = "loading";
    },
    [getComponents.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.components.get.list = data;
        state.components.get.error = null;
      }else{
        state.components.get.error = { message: `${status} - An error occurred` };
      }
      state.components.get.status = 'idle';
    },

  },
});

export default commonSlice.reducer;
