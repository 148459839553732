import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Button } from '@mui/material';

import "./messages.css";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Button, Grid } from "@material-ui/core";
import AppAutoComplete from "../../common/appautocomplete";
import Inputs from "../inputs/Inputs";
import { getCustomers, getSmsBalance } from "../../app/customersSlice";

const Messages = ({via = 'sms', receiversGroup = 'individual', receivers = null, formValues, handleChangeSMS, handleChangePurchase, setFormValues, isPurchase, totalPurchaseAmount}) => {

  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [receiversLov, setReceiversLov] = useState([]);  
  // const [formValues, setFormValues] = useState({});
  const [showSendButton, setShowSendButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const { error: customersError, status: customersStatus, list: customersList } = useSelector(
    ({ customers }) => customers.get
  );

  
  const loadCustomers = () => {
    dispatch(getCustomers());
  }


  useEffect(() => {
    if(formValues?.receiversGroup === 1){
      loadCustomers()    
    } 
  },[formValues])

  useEffect(() => {
    if(customersStatus === 'idle' && customersError === null && customersList?.length > 0){
      let receiversArray = [];
      for (let index = 0; index < customersList.length; index++) {
        receiversArray.push({ value: customersList[index]?.id, label: customersList[index]?.customer_name+" - "+ customersList[index]?.customer_mobile })
      }
      setReceiversLov(receiversArray);
    } 

  },[customersList, customersError, customersStatus])




  return (
    <div className="col-12">
          <Grid container spacing={1}>
          {isPurchase === false && (
            <Grid item xs={12} md={12}>
              <AppAutoComplete
                label="Send To"
                itemStateValue="receiversGroup"
                values={formValues}
                setValues={setFormValues}
                options={[{ label: 'Individual', value: 1 }, { label: 'All Customers', value: 2 }, { label: 'Orders', value: 3 }, { label: 'Creditors', value: 4 }]}
                optionName={['label']}
                optionId="value"
                handleChange={handleChangeSMS}
                page={"pos"}
              />
            </Grid>            
          )}
          <br />
            
            {isPurchase === false && 
              <Grid item xs={12} md={12}>
              <AppAutoComplete
                label="Select Receivers"
                itemStateValue="receivers"
                values={formValues}
                setValues={setFormValues}
                options={receiversLov}
                optionName={['label']}
                optionId="value"
                handleChange={handleChangeSMS}
                page={"pos"}
                reload={loadCustomers}
              />
            </Grid>
            }
            <br />
            {isPurchase === false && 
            <Grid item xs={12} md={12}>
              <AppAutoComplete
                label="Send Via"
                itemStateValue="via"
                values={formValues}
                setValues={setFormValues}
                options={[{ label: 'Normal SMS', value: 'sms' }, { label: 'Email', value: 'email' }, { label: 'WhatsApp', value: 'whatsapp' }]}
                optionName={['label']}
                optionId="value"
                handleChange={handleChangeSMS}
                page={"pos"}
              />
            </Grid>
            }
            <br />
            {isPurchase === false && 
            <Grid item xs={12} md={12}>
              <Inputs
                name="messageBody"
                label="Type your message here"
                className="input-class"
                size="small"
                value={formValues?.messageBody}
                inputType="textarea"
                onChange={handleChangeSMS}
                error={errors["messageBody"] ? true : false}
                helperText={errors["messageBody"] ? errors.messageBody[0] : null}
                inputRef={inputRef}
              />
            </Grid>
            }
            {isPurchase === true && 
            <Grid item xs={12} md={12}>
              <Inputs
                name="counts"
                label="How many messages would you need?"
                className="input-class"
                size="small"
                value={formValues?.counts}
                inputType="number"
                onChange={handleChangeSMS}
                error={errors["counts"] ? true : false}
                helperText={errors["counts"] ? errors.counts[0] : null}
                inputRef={inputRef}
              />
            </Grid>
            }
            <br />
            {isPurchase === true && totalPurchaseAmount > 25 && 
            <Grid item xs={12} md={12}>
              <Inputs
                name="amount"
                label="Amount to pay"
                className="input-class"
                size="small"
                disabled={true}
                value={totalPurchaseAmount}
                defaultValue={totalPurchaseAmount}
                inputType="number"
                onChange={handleChangeSMS}
                error={errors["amount"] ? true : false}
                helperText={errors["amount"] ? errors.amount[0] : null}
              />
            </Grid>
            }
            <br />
            <br />
            <br />
            <Grid item xs={12} md={12}>
            <label>
              <input
                type="checkbox"
                checked={isPurchase}
                onChange={handleChangePurchase}
              />
              <b>{" Purchase Message"} {totalPurchaseAmount > 25 ? " - Payment amount will be "+Number(totalPurchaseAmount).toLocaleString() : ""} </b>
            </label>
            </Grid>
          </Grid>
        

    </div>
  );
};

export default Messages;
