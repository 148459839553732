import { instCat } from "../common/validators";
import { adminServiceApi } from "../services";

export const getSalesList = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    // query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get(instCat() === 4 ?"sales/agent" : "sales", reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSaleInvoice = async (query = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    // query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.get("sales/invoice/"+query?.invoice_no, reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const reverseSaleEntry = async (payload = null) => {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
    // query.inst_id = userData.data?.inst_id;
  const reqOpts = {
    //params: query,
    headers: {
      Authorization: `Bearer ${userData.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    validateStatus: (status) => {
      return status < 500; // Resolve only if the status code is less than 500
    },
  };
  try {
    const response = await adminServiceApi.put(instCat() === 4 ? "sales/reverseAgent/"+payload?.id : "sales/reverse/"+payload?.id,payload, reqOpts);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
