import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Inputs from "../../components/inputs/Inputs";
import { toast } from "react-toastify";
import moment from "moment";
import MyDialog from "../../components/dialog/Dialog";
import MySpinner from "../../components/Spinner/spinner";
import Detaildialog from "../../components/dialog/Detaildialog";
import { useDispatch, useSelector } from "react-redux";
import { addResource, updateResource, getResources } from "../../app/resourcesSlice";
import { getActions, getComponents } from "../../app/commonSlice";
import { getProducts } from "../../app/productsSlice";
import { getLoans, addLoan, updateLoan, getLoanDetails, getLoanCalculator } from "../../app/loansSlice";
import { loanReviewSchema, loansSchema } from "../../common/validators";
import validate from "validate.js";
import AppTabs from "../../components/appTabs/appTabs";
import { addLoansProgress, getLoansProgress } from "../../app/loansProgressSlice";
import { base64toBlob } from "../../api/commonAPI";
import LoanAttachmentPdf from "../../components/dialog/LoanAttachmentPdf";

const tableHead = [
  { label: "Customer Name", name: "f_name" },
  { label: "Loan Product", name: "product_name" },
  { label: "Stage No", name: "stage_no" },
  { label: "Days", name: "days" },
  { label: "Final Stage No", name: "stages_count" },
  { label: "Rate", name: "rate" },
  { label: "Amount", name: "amount" },
  { label: "Tenure", name: "tenure_months" },
  { label: "Status", name: "status" },
  "action",
];

const tabs = [
  {
    title: "Details",
  },
  {
    title: "Location",
  },
  {
    title: "Reviews",
  },
  {
    title: "Calculator",
  },
];


const Loans = () => {
  let [openForm, setOpenForm] = useState(false);
  let [openDetail, setOpenDetail] = useState(false);
  const [errors, setErrors] = useState([]);
  let [edit, setEdit] = useState(false);
  let [viewDetailsSelected, setViewDetailsSelected] = useState(false);
  let [hideSaveButton, setHideSaveButton] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [itemDataList, setItemDataList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [reviewsDataList, setReviewsDataList] = useState([]);
  const [actionsDataList, setActionsDataList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  let [showDetails, setShowDetails] = useState(false);
  let [showReviews, setShowReviews] = useState(false);
  let [showLocation, setShowLocation] = useState(false);
  let [showCalculator, setShowCalculator] = useState(false);
  let [incorporation, setIncorporation] = useState(null);
  let [incorporationName, setIncorporationName] = useState(null);
  let [businessInput, setBusinessInput] = useState(null);
  let [businessInputName, setbusinessInputName] = useState(null);
  let [taxClearanceInput, setTaxClearanceInput] = useState(null);
  let [taxClearanceInputName, setTaxClearanceInputName] = useState(null);
  let [openPDF, setOpenPDF] = useState(false);
  let [pdfData, setPdfData] = useState(null);
  let [pdfTitle, setPdfTitle] = useState(null);
  

  const dispatch = useDispatch();

  const { error:loanError, status:loanStatus, data:loanData } = useSelector(
    ({ loans }) => loans.payload
  );

  const { error:calculatorError, status:calculatorStatus, data:calculatorData } = useSelector(
    ({ loans }) => loans.calculator
  );

  const { error, status, list } = useSelector(
    ({ loans }) => loans.get
  );

  const { error: postError, status: postStatus } = useSelector(
    ({ loans }) => loans.post
  );

  const { error: putError, status: putStatus } = useSelector(
    ({ loans }) => loans.put
  );

  const { error: postPoansProgressError, status: postLoansProgressStatus } = useSelector(
    ({ loansProgress }) => loansProgress.post
  );

  const { error: loansProgressError, status: loansProgressStatus, list: loansProgressList } = useSelector(
    ({ loansProgress }) => loansProgress.get
  );

  const { get: getActionsData } = useSelector(
    ({ common }) => common.actions
  );

  const { get: getComponentsData } = useSelector(
    ({ common }) => common.components
  );

  const { status: productsStatus, error: productsError, list: productsDataList } = useSelector(
    ({ products }) => products.get
  );

  const handleEditSideEffect = (item) => {

  }

  const handleChangeTabs = (index) => {
    setActiveTab(index);
  };

  const handleRequestClose = () => {
    setFormValues({});
    setOpenForm(false);
    setOpenDetail(false);
    setEdit(false);
    setErrors([])
  };

 

  

  const viewItem = (item) => {
    if (item) {

      // for (let index = 0; index < productsDataList.length; index++) {
      //   if(productsDataList[index][id] == item.id){

      //   }        
      // }

      dispatch(getLoansProgress({ id: item?.id }))
      dispatch(getLoanDetails({ id: item?.id }))
      dispatch(getLoanCalculator({ period: item?.tenure_months,amount:item?.amount,interest:item?.rate,loan_date:moment(item?.entry_date).format('YYYY-MM-DD'),loan_charges:0}))
      ///amortizationOrg?period=12&amount=2000000&interest=14&loan_date=2022-10-19&loan_charges=0
      setViewDetailsSelected(true)

      //setEdit(true);
      setFormValues(item);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenDetail(true);
      }, 300);
    }
  };

  const editItem = (item) => {
    if (item) {
      setEdit(true);
      let newItem = {
        ...item
      };
      newItem.incorporation = null;
      newItem.tax_clearance = null;
      newItem.business_license = null;
      setFormValues(newItem);
      setSelectedItem(item);
      setTimeout(() => {
        setOpenForm(true);
      }, 300);
    }
  };

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


  const formOpen = () => {
    return (
      <>
        <div className="row">

          {productsList.length ? (
            <div className="col-sm-12 col-md-6 col-6">
              <Inputs
                name="product_id"
                size="small"
                inputType="select"
                value={formValues.product_id}
                defaultValue={productsList[0]}
                label="Products"
                listLabel="Products"
                listValue="product_id"
                list={productsList}
                onChange={handleChange}
                error={errors["product_id"] ? true : false}
                helperText={errors["product_id"] ? errors.product_id[0] : null}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="f_name"
              size="small"
              label="Customer Name"
              value={formValues.f_name}
              inputType="text"
              disabled={false}
              onChange={handleChange}
              error={errors["f_name"] ? true : false}
              helperText={errors["f_name"] ? errors.f_name[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="email"
              size="small"
              label="Customer Email"
              value={formValues.email}
              inputType="text"
              disabled={false}
              onChange={handleChange}
              error={errors["email"] ? true : false}
              helperText={errors["email"] ? errors.email[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="mobile"
              size="small"
              label="Customer Phone"
              value={formValues.mobile}
              inputType="text"
              disabled={false}
              onChange={handleChange}
              error={errors["mobile"] ? true : false}
              helperText={errors["mobile"] ? errors.mobile[0] : null}
            />
          </div>

          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="tin"
              size="small"
              label="Customer TIN"
              value={formValues.tin}
              inputType="text"
              disabled={false}
              onChange={handleChange}
              error={errors["tin"] ? true : false}
              helperText={errors["tin"] ? errors.tin[0] : null}
            />
          </div>

          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="tenure_months"
              size="small"
              label="Tenure Months"
              value={formValues.tenure_months}
              inputType="number"
              disabled={false}
              onChange={handleChange}
              error={errors["tenure_months"] ? true : false}
              helperText={errors["tenure_months"] ? errors.tenure_months[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="amount"
              size="small"
              label="Amount"
              value={formValues.amount}
              inputType="number"
              disabled={false}
              onChange={handleChange}
              error={errors["amount"] ? true : false}
              helperText={errors["amount"] ? errors.amount[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="rate"
              size="small"
              label="Rate"
              value={formValues.rate}
              inputType="number"
              disabled={false}
              onChange={handleChange}
              error={errors["rate"] ? true : false}
              helperText={errors["rate"] ? errors.rate[0] : null}
            />
          </div>
          {formValues.product_id == 1 ? <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="nid"
              size="small"
              label="Customer NID"
              value={formValues.nid}
              inputType="text"
              disabled={false}
              onChange={handleChange}
              error={errors["nid"] ? true : false}
              helperText={errors["nid"] ? errors.nid[0] : null}
            />
          </div> : ""
          }
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="address"
              size="small"
              label="Physical Address"
              value={formValues.address}
              inputType="text"
              disabled={false}
              onChange={handleChange}
              error={errors["address"] ? true : false}
              helperText={errors["address"] ? errors.address[0] : null}
            />
          </div>
          {formValues.product_id != 1 ? <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="incorporation"
              size="small"
              label="Certificate of Incorporation"
              value={formValues.incorporation}
              inputType="file"
              disabled={false}
              onChange={handleChange}
              error={errors["incorporation"] ? true : false}
              helperText={errors["incorporation"] ? errors.incorporation[0] : null}
            />
          </div> : ""
          }
          {formValues.product_id != 1 ? <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="incorporation_expiry"
              size="small"
              label="Certificate of Incorporation Expiry"
              value={formValues.incorporation_expiry}
              inputType="date"
              disabled={false}
              onChange={(e) =>
                handleChange({ target: { name: "incorporation_expiry", value: e } })}
              error={errors["incorporation_expiry"] ? true : false}
              helperText={errors["incorporation_expiry"] ? errors.incorporation_expiry[0] : null}
            />
          </div> : ""
          }
          {formValues.product_id != 1 ? <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="tax_clearance"
              size="small"
              label="Tax Clearance"
              value={formValues.tax_clearance}
              inputType="file"
              disabled={false}
              onChange={handleChange}
              error={errors["tax_clearance"] ? true : false}
              helperText={errors["tax_clearance"] ? errors.tax_clearance[0] : null}
            />
          </div> : ""
          }
          {formValues.product_id != 1 ? <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="tax_clearance_expiry"
              size="small"
              label="Tax Clearance Expiry"
              value={formValues.tax_clearance_expiry}
              inputType="date"
              disabled={false}
              onChange={(e) =>
                handleChange({ target: { name: "tax_clearance_expiry", value: e } })}
              error={errors["tax_clearance_expiry"] ? true : false}
              helperText={errors["tax_clearance_expiry"] ? errors.tax_clearance_expiry[0] : null}
            />
          </div> : ""
          }
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="business_license"
              size="small"
              label="Business License"
              value={formValues.business_license}
              inputType="file"
              disabled={false}
              onChange={handleChange}
              error={errors["business_license"] ? true : false}
              helperText={errors["business_license"] ? errors.business_license[0] : null}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <Inputs
              name="business_license_expiry"
              size="small"
              label="Business License Expiry"
              value={formValues.business_license_expiry}
              inputType="date"
              disabled={false}
              onChange={(e) =>
                handleChange({ target: { name: "business_license_expiry", value: e } })}
              error={errors["business_license_expiry"] ? true : false}
              helperText={errors["business_license_expiry"] ? errors.business_license_expiry[0] : null}
            />
          </div>






        </div>
      </>
    );
  };


  const dialogOpen = () => {
    return (
      <>
        <AppTabs
          tabs={tabs}
          activeTab={activeTab}
          handleTabChange={handleChangeTabs}
        />
        {showDetails && (<div className="row" style={{padding:"10px"}}>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Customer Name</span>&nbsp;&nbsp;
            <span><b>{selectedItem?.f_name}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Mobile</span>&nbsp;&nbsp;
            <span><b>{selectedItem?.mobile}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Email</span>&nbsp;&nbsp;
            <span><b>{selectedItem?.email}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Amount</span>&nbsp;&nbsp;
            <span><b>{Number(selectedItem?.amount).toLocaleString()}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Product</span>&nbsp;&nbsp;
            <span><b>{selectedItem?.product_name}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Rate</span>&nbsp;&nbsp;
            <span><b>{selectedItem?.rate}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Tenure Months</span>&nbsp;&nbsp;
            <span><b>{selectedItem?.tenure_months}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Status</span>&nbsp;&nbsp;
            <span><b>{selectedItem?.status}</b></span>
          </div>
          <div className="col-12">
            <br />
            <h4>Attachments</h4>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Business License</span>&nbsp;&nbsp;
            <span onClick={(e)=>{downloadBusinessLicense(e)}} id={selectedItem.business_license?"attachment":"hi"}><b>{selectedItem?.business_license}</b></span>
          </div>
          <div className="col-sm-12 col-md-6 col-6">
            <span>Business License Expiry</span>&nbsp;&nbsp;
            <span><b>{moment(selectedItem?.business_license_expiry).format('YYYY-MM-DD')}</b></span>
          </div>
          {selectedItem.product_id != 1 && (
          <div className="col-sm-12 col-md-6 col-6">
            <span>Certificate of Incorporation</span>&nbsp;&nbsp;
            <span onClick={(e)=>{downloadCertificateIncorporation(e)}} id={selectedItem.incorporation?"attachment":"hi"}><b>{selectedItem?.incorporation}</b></span>
          </div>)}
          {selectedItem.product_id != 1 && (<div className="col-sm-12 col-md-6 col-6">
            <span>Certificate of Incorporation Expiry</span>&nbsp;&nbsp;
            <span><b>{moment(selectedItem?.incorporation_expiry).format('YYYY-MM-DD')}</b></span>
          </div>)}
          {selectedItem.product_id != 1 && (<div className="col-sm-12 col-md-6 col-6">
            <span>Tax Clearance</span>&nbsp;&nbsp;
            <span onClick={(e)=>{downloadTaxClearance(e)}} id={selectedItem.tax_clearance?"attachment":"hi"}><b>{selectedItem?.tax_clearance}</b></span>
          </div>)}
          {selectedItem.product_id != 1 && (<div className="col-sm-12 col-md-6 col-6">
            <span>Tax Clearance Expiry</span>&nbsp;&nbsp;
            <span><b>{moment(selectedItem?.tax_clearance_expiry).format('YYYY-MM-DD')}</b></span>
          </div>)}
        </div>
        )}

        {showReviews && (
          <div className="col-sm-12 col-md-12 col-12" style={{padding:"10px"}}>

          </div>)}

        {showReviews && (
          <div className="col-sm-12 col-md-12 col-12">

            {reviewsDataList && (reviewsDataList ?? []).length >= 0 && (
              reviewsDataList
                .filter((i) => i.remarks != null)
                .map((item, index) => (
                  <div className="wf-comments-item">
                    <div className="bubble" onClick={() => { handleEditSideEffect(item) }}>
                      <div>
                        {item.remarks}
                        <br />
                        <small>{item.user}</small>&nbsp;
                        <small style={item.action == "REJECT" || item.action == "DROP" ? { color: "#800000ff" } : item.action == "COMMENT" ? { color: "#fca11a" } : item.action == "DISBURSED" ? { color: "#3991d9" } : { color: "#39d982" }}><b>{item.action}</b></small>&nbsp;
                        <small>{moment(item.entry_date).format('YYYY-MM-DD HH:mm:ss')}</small>
                        <small style={{ float: "right" }}>{item.stage_name}</small>
                      </div>
                    </div>
                  </div>
                ))
            )}
            <Inputs
              name="remarks"
              size="small"
              label="Remarks"
              value={formValues.remarks}
              inputType="text"
              disabled={false}
              onChange={handleChange}
              error={errors["remarks"] ? true : false}
              helperText={errors["remarks"] ? errors.remarks[0] : null}
            />

          </div>
        )}
        {showCalculator && (
          <div className="row" style={{padding:"10px"}}>
            <div className="col-6" >
              <span>Loan Amount</span>&nbsp;&nbsp;
              <span><b>{Number(selectedItem?.amount).toLocaleString()}</b></span>
            </div>
            <div className="col-6" >
              <span>Tenure</span>&nbsp;&nbsp;
              <span><b>{selectedItem?.tenure_months}</b></span>
            </div>
            <div className="col-6" >
              <span>Annual Interest</span>&nbsp;&nbsp;
              <span><b>{calculatorData?.annualInterest}</b></span>
            </div>
            <div className="col-6" >
              <span>Monthly Repayments</span>&nbsp;&nbsp;
              <span><b>{Number(calculatorData?.monthlyPayments).toLocaleString()}</b></span>
            </div>
            <div className="col-6" >
              <span>Start Repayments</span>&nbsp;&nbsp;
              <span><b>{calculatorData?.amortOutput[1]?.payMonth}</b></span>
            </div>
            <div className="col-6" >
              <span>Finish Repayments</span>&nbsp;&nbsp;
              <span><b>{calculatorData?.amortOutput[selectedItem?.tenure_months]?.payMonth}</b></span>
            </div>
          </div>)}
      </>
    );
  };

  const handleChange = async (event) => {
    //alert(JSON.stringify(event.target))

    if(event.target && event.target.type === "file"){
      let file = event.target && event.target.files ? event.target.files[0] : null;
      // console.log('name',file?.name)
      // console.log('size',file?.size)
      // console.log('type',file?.type)
      //console.log('base64',await toBase64(file))
      //alert(event.target.type)
      if(event.target && event.target.name === "incorporation"){
        setIncorporation(
          file ? await toBase64(file) : null
        );
        setIncorporationName(
          file?.name
        );
      }

      if(event.target && event.target.name === "tax_clearance"){
        setTaxClearanceInput(
          file ? await toBase64(file) : null
        );
        setTaxClearanceInputName(
          file?.name
        );
      }

      if(event.target && event.target.name === "business_license"){
        setBusinessInput(
          file ? await toBase64(file) : null
        );

        setbusinessInputName(
          file?.name
        );
      }
      
    }

    if (event.target && event.target.type != "file"){
      setFormValues({
        ...formValues,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      });
      //alert(event.target.value)
    }   


    if (event.target && event.target.name === "product_id") {

      for (let index = 0; index < productsDataList.length; index++) {
        if (event.target.value === productsDataList[index].id) {
          setFormValues({
            ...formValues,
            "rate": productsDataList[index].int_rate,
            "product_id": event.target.value
          });
        }

      }
    }
  };

  const handleAttachmentClose = () => {
    setOpenPDF(false) 
    setPdfData(null)
  };

  const downloadBusinessLicense = (e) => {
    e.preventDefault();
    //alert(loanData[0]?.business_license)
    if(loanData[0].business_license){
    const _blob = base64toBlob("data:application/pdf;base64,"+loanData[0]?.business_license);
    const _url = URL.createObjectURL(_blob);   
    setOpenPDF(true) 
    setPdfData(_url)
    setPdfTitle("Attachment - Business License")
    }
  }

  const downloadTaxClearance = (e) => {
    e.preventDefault();
    if(loanData[0].tax_clearance){
      const _blob = base64toBlob("data:application/pdf;base64,"+loanData[0]?.tax_clearance);
      const _url = URL.createObjectURL(_blob);   
      setOpenPDF(true) 
      setPdfData(_url)
      setPdfTitle("Attachment - Tax Clearance")
    }
   

  }

  const downloadCertificateIncorporation = (e) => {
    e.preventDefault();
    if(loanData[0].incorporation){
    const _blob = base64toBlob("data:application/pdf;base64,"+loanData[0]?.incorporation);
    const _url = URL.createObjectURL(_blob);   
    setOpenPDF(true) 
    setPdfData(_url)
    setPdfTitle("Attachment - Certificate of Incorporation")
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // setFormValues({
    //   ...formValues,
    //   incorporation:incorporation,
    //   incorporationName:incorporationName
    // })

    

    //console.log('formValues',formValues)

    //toast.success(JSON.stringify(formValues));
    let validationErrors = validate(
      formValues,
      loansSchema
    );
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      if (edit) {
        //alert(selectedItem?.id)
        let newValues = formValues;
        newValues = {
          ...newValues,
          id:selectedItem?.id,
          incorporationName:incorporationName,
          incorporation:incorporation,
          taxClearanceInput:taxClearanceInput,
          taxClearanceInputName:taxClearanceInputName,
          businessInput:businessInput,
          businessInputName:businessInputName
        }
        dispatch(updateLoan(newValues));
      } else {
        let newValues = formValues;
        newValues = {
          ...newValues,
          incorporationName:incorporationName,
          incorporation:incorporation,
          taxClearanceInput:taxClearanceInput,
          taxClearanceInputName:taxClearanceInputName,
          businessInput:businessInput,
          businessInputName:businessInputName
        }
        dispatch(addLoan(newValues));
      }
    }





  };

  const reviewSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));

    let validationErrors = validate(
      formValues,
      loanReviewSchema
    );
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      let newItem = {
        "id": selectedItem?.id,
        "remarks": formValues?.remarks,
        "action": "COMMENT",
        "stage_no": selectedItem?.stage_no,
        "product_id": selectedItem?.product_id
      }
      dispatch(addLoansProgress(newItem));
    }
  };

  const approveSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    let validationErrors = validate(
      formValues,
      loanReviewSchema
    );
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      let newItem = {
        "id": selectedItem?.id,
        "remarks": formValues?.remarks,
        "action": "APPROVE",
        "stage_no": selectedItem?.stage_no,
        "product_id": selectedItem?.product_id
      }
      dispatch(addLoansProgress(newItem));
    }
  };

  const rejectSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    let validationErrors = validate(
      formValues,
      loanReviewSchema
    );
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      let newItem = {
        "id": selectedItem?.id,
        "remarks": formValues?.remarks,
        "action": "REJECT",
        "stage_no": selectedItem?.stage_no,
        "product_id": selectedItem?.product_id
      }
      dispatch(addLoansProgress(newItem));
    }
  };


  const disbursedSubmit = (e) => {
    e.preventDefault();
    let validationErrors = validate(
      formValues,
      loanReviewSchema
    );
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      let newItem = {
        "id": selectedItem?.id,
        "remarks": formValues?.remarks,
        "action": "DISBURSED",
        "stage_no": selectedItem?.stage_no,
        "product_id": selectedItem?.product_id
      }
      dispatch(addLoansProgress(newItem));
    }
  };

  const dropSubmit = (e) => {
    e.preventDefault();
    //toast.success(JSON.stringify(formValues));
    let validationErrors = validate(
      formValues,
      loanReviewSchema
    );
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      let newItem = {
        "id": selectedItem?.id,
        "remarks": formValues?.remarks,
        "action": "DROP",
        "stage_no": selectedItem?.stage_no,
        "product_id": selectedItem?.product_id
      }
      dispatch(addLoansProgress(newItem));
    }
  };

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getLoans());
    dispatch(getActions());
    dispatch(getComponents());
  }, [dispatch]);

  useEffect(() => {
    if (postLoansProgressStatus === "loading") {
      setHideSaveButton(false);
    } else if (postLoansProgressStatus === "posted" && !loansProgressError) {
      setOpenDetail(false);
      dispatch(getLoans());
      setHideSaveButton(false);
    }
  }, [postLoansProgressStatus, loansProgressError]);

  useEffect(() => {
    if (postStatus === "loading" && !edit) {
      setHideSaveButton(false);
    } else if (postStatus === "idle") {
      dispatch(getLoans());
      setHideSaveButton(false);
    }
  }, [postStatus]);


  useEffect(() => {
    if (putStatus === "loading" && edit) {
      setHideSaveButton(false);
    } else if (putStatus === "idle") {
      dispatch(getResources());
      setHideSaveButton(false);
    }

  }, [putStatus]);


  useEffect(() => {
    if (status === "loading") {

    } else if (status === "idle") {
      setDataList(list);
    }

  }, [status, list]);

  useEffect(() => {
    if (loansProgressStatus === "loading") {

    } else if (loansProgressStatus === "idle") {
      setReviewsDataList(loansProgressList);
    }

  }, [loansProgressStatus, loansProgressList]);

  useEffect(() => {
    if (productsStatus === "loading") {

    } else if (productsStatus === "idle") {
      let arr = [];
      for (let index = 0; index < productsDataList.length; index++) {
        arr.push({ value: productsDataList[index]["id"], label: productsDataList[index]["name"] })
      }
      setProductsList(arr);
    }

  }, [productsStatus, productsDataList]);



  useEffect(() => {
    if (getActionsData.status === "loading") {

    } else if (getActionsData.status === "idle") {
      let actionsArray = [];
      for (let index = 0; index < getActionsData.list.length; index++) {
        actionsArray.push({ value: getActionsData.list[index]?.name, label: getActionsData.list[index]?.name })
      }
      setActionsDataList(actionsArray);
    }
  }, [getActionsData]);

  useEffect(() => {
    // isAddAccess();
    // isEditAccess();
    if (activeTab === 0) {
      setShowDetails(true);
      setShowLocation(false);
      setShowReviews(false);
      setShowCalculator(false);
    } else if (activeTab === 1) {
      setShowDetails(false);
      setShowLocation(true);
      setShowReviews(false);
      setShowCalculator(false);
      // dispatch(getDisposedItems())
    } else if (activeTab === 2) {
      setShowDetails(false);
      setShowLocation(false);
      setShowReviews(true);
      setShowCalculator(false);
      // dispatch(getDisposedItems())
    } else if (activeTab === 3) {
      setShowDetails(false);
      setShowLocation(false);
      setShowReviews(false);
      setShowCalculator(true);
      // dispatch(getDisposedItems())
    }


  }, [dispatch, activeTab]);

  return (
    <div>
      <div className="ez-page-action">
        <h2 className="page-header">Loan Requests</h2>
        &nbsp;
        <div style={{ alignContent: "right" }}>
          <Inputs
            color="success"
            className={`bx-burst-hover`}
            size="small"
            inputType="button"
            type="submit"
            text={"New Loan"}
            onClick={() => setOpenForm(true)}
            propBackgroundColor={"#367588"}
          />
          &nbsp;
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {dataList.length > 0 ? (
                <Table
                  columns={tableHead}
                  data={dataList}
                  actions={[
                    {
                      action: "Details",
                      onClick: (item) => {
                        viewItem(item);
                      },
                    },
                    {
                      action: "Update",
                      onClick: (item) => {
                        editItem(item);
                      },
                    }
                  ]}
                  downloadFileName={"Available Resources"}
                />
              ) : (
                <MySpinner size={35} color="#367588" />
              )}
            </div>
          </div>
        </div>
      </div>

      <MyDialog
        title={"Loan Request"}
        width="md"
        onClose={handleRequestClose}
        open={openDetail}
        text={selectedItem.stage_no < selectedItem.stages_count ? "Save Remark" : null}
        rejectText={selectedItem.stage_no < selectedItem.stages_count ? "Send Back" : null}
        dropText={selectedItem.status != "DROPPED" && selectedItem.status != "DISBURSED" ? "Drop Request" : null}
        disbursedText={selectedItem.status === "REVIEWED" ? "Mark Disbursed" : null}
        approveText={selectedItem.stage_no < selectedItem.stages_count ? "Approve" : null}
        submit={(e) => { reviewSubmit(e) }}
        approve={selectedItem.stage_no < selectedItem.stages_count ? (e) => { approveSubmit(e) } : {}}
        reject={selectedItem.stage_no < selectedItem.stages_count ? (e) => { rejectSubmit(e) } : {}}
        drop={selectedItem.status != "DROPPED" && selectedItem.status != "DISBURSED" ? (e) => { dropSubmit(e) } : {}}
        disbursed={selectedItem.status === "REVIEWED" ? (e) => { disbursedSubmit(e) } : {}}
        content={() => dialogOpen()}
        hideSaveButton={showReviews && !hideSaveButton ? false : true}
        propBackgroundColor={"#367588"}
      />

      <MyDialog
        title={edit ? "Update Loan Request" : "New Loan Request"}
        width="md"
        onClose={handleRequestClose}
        open={openForm}
        text={!edit ? "Save Request" : "Update"}
        content={() => formOpen()}
        submit={(e) => handleSubmit(e)}
        hideSaveButton={hideSaveButton}
        propBackgroundColor={"#367588"}
      />

      <LoanAttachmentPdf
      title={pdfTitle}
      width="md"
      onClose={handleAttachmentClose}
      open={openPDF}
      content={pdfData}
      />

    </div>
  );
};

export default Loans;
