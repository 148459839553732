import React from "react";
import ReactDOM from "react-dom";

import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import "./assets/css/grid.css";
import "./assets/css/index.css";
import "./assets/css/theme.css";

import "react-toastify/dist/ReactToastify.css";
import MainApp from "./App";

document.title = "TaiPOS";
ReactDOM.render(
  <React.StrictMode>
    <MainApp />
  </React.StrictMode>,
  document.getElementById("root")
);
