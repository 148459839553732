import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Flip, toast, Zoom } from "react-toastify";
import { getBarcodeList, postBarcodes, putBarcodes } from "../api/barcodesAPI";

export const getBarcodes = createAsyncThunk("barcodes/getBarcodes", async (payload, { rejectWithValue }) => {
  try {
    const response = await getBarcodeList(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Errorrrrrrrrr ", error);
    return rejectWithValue(error.message);
  }
});

export const addBarcode = createAsyncThunk("barcodes/addBarcode", async (payload, { rejectWithValue }) => {
  try {
    const response = await postBarcodes(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const updateBarcode = createAsyncThunk("barcodes/updateBarcode", async (payload, { rejectWithValue }) => {
  try {
    const response = await putBarcodes(payload);
    // console.log('responseresponse',response)
    return response;
  } catch (error) {
    console.log("Error ", error);
    return rejectWithValue(error.message);
  }
});


export const barcodesSlice = createSlice({
  name: "barcodes",
  initialState: {
    payload: {
      status: null,
      error: null,
      data: null,
    },
    post: {
      status: null,
      error: null,
      data: null,
    },
    put: {
      status: null,
      error: null,
      data: null,
    },
    get: {
      status: null,
      error: null,
      list: [],
    },
  },

  extraReducers: {
    [getBarcodes.pending]: (state) => {
      state.get.status = "loading";
    },
    [getBarcodes.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 200) {
        state.get.list = data;
        state.get.error = null;
      }else{
        state.get.error = { message: `${status} - An error occurred` };
        toast.error("System error couldn't get barcodes!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.get.status = 'idle';
    },
    [getBarcodes.rejected]: (state, action) => {
      state.get.status = "idle";
      state.get.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't get Batch!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [addBarcode.pending]: (state) => {
      state.post.status = "loading";
    },
    [addBarcode.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 201) {
        toast.success("Batch saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.post.status = "idle";
        state.post.error = null;
      }else{
        toast.warn("Couldn't add Batch!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
        state.post.error = "Failed";
      }

      state.post.data = data;
    },
    [addBarcode.rejected]: (state, action) => {
      //state.post.status = "idle";
      state.post.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add batch!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
    [updateBarcode.pending]: (state) => {
      state.put.status = "loading";
    },
    [updateBarcode.fulfilled]: (state, {payload}) => {
      const { status, data } = payload;
      if (status === 202) {
        toast.success("Batch saved.", {
          autoClose: 1300,
          transition: Flip,
          hideProgressBar: true,
        });
        state.put.status = "idle";
      }else{
        state.put.error = "Failed";
        toast.warn("Couldn't update batch!", {
          autoClose: 1300,
          hideProgressBar: true,
        });
      }
      state.put.data = data;
    },
    [updateBarcode.rejected]: (state, action) => {
      state.put.status = "idle";
      state.put.error = { message: "An error occurred" };
      console.log("***** ERROR PAYLOAD ******* ", action);
      toast.error("System error couldn't add batch!", {
        autoClose: 1300,
        hideProgressBar: true,
      });
    },
  },
});

export default barcodesSlice.reducer;
