import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "./dialog.css";
import React from "react";
import Inputs from "../inputs/Inputs";

const LoanAttachmentPdf = ({
  open,
  onClose,
  width,
  title,
  content,
  height,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={width}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent style={{ height: height ?? "" }}>
        <DialogContentText>
        <iframe src={content} width="100%" height="500px"></iframe>
       
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Inputs
          color="primary"
          size="small"
          inputType="button"
          text="Close"
          type="submit"
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default LoanAttachmentPdf;
